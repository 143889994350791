import { IMeta } from 'common/common.interface'
import {
    IAverageMonthlyPerformance,
    IDataCustomersTable,
    IDataOverviewTable1,
    IDataOverviewTable2,
    IDataTradeLanesTable,
    IFinanceTableData,
    IHistoryData,
    IProgressBarChartData,
    ISplitRecordBarchartData,
} from './control-tower.interface'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import {
    IBarChartStackedData,
    IDataJointChart,
    IDonatRechart,
    ILineData,
} from '@components/rechart-component/rechart-interface'
import { faker } from '@faker-js/faker'

export interface IControlTowerSlice {
    // common
    meta: IMeta
    historyData: IHistoryData

    // segment overview
    dataOverviewTable1: IDataOverviewTable1[]
    dataOverviewTable2: IDataOverviewTable2[]
    joinChartOverview: IDataJointChart
    barchartOverview: IBarChartStackedData

    // segment trade lanes
    pieChartTradeLanes: {
        regionPieChart: IDonatRechart[]
        carrierPieChart: IDonatRechart[]
    }
    dataTradeLanesTable: IDataTradeLanesTable[]

    // segment trade lanes
    customerChartOrganisationData: IProgressBarChartData
    customerTableData: IDataCustomersTable[]

    // segment finance
    financeTableData: IFinanceTableData[]
    financePieChart: IDonatRechart[]
    averageMonthlyPerformance: IAverageMonthlyPerformance
}

export const initialState: IControlTowerSlice = {
    // segment common
    meta: {
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total_page: 0,
        total_Items: 0,
        from: 0,
        to: 0,
        index_end: 0,
        index_start: 0,
    },
    historyData: {
        development: {
            chartData: {
                key: [
                    {
                        id: 'line1',
                        color: '#8D99A5',
                    },
                    {
                        id: 'line2',
                        color: '#D9DDE1',
                    },
                ],
                line1: [],
                line2: [],
            },
            tickItems: [],
        },
        splitRecord: {
            key: [
                {
                    id: 'f1',
                    color: '#D9DDE1',
                },
                {
                    id: 'f2',
                    color: '#8D99A5',
                },
            ],
            data: [
                {
                    name: 'This Period',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'Last Period',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
            ],
        },
    },

    // segment overview
    dataOverviewTable1: Array.from({ length: 20 }, (_, idx) => [
        [
            {
                item: 'Revenue',
                thisPeriod: 'AUD 12,000.00',
                lastPeriod: 'AUD 11,750.00',
                change: '+AUD 250.00 (2.1%)',
            },
            {
                item: 'GP',
                thisPeriod: 'AUD 20,565.00',
                lastPeriod: 'AUD 23,518.00',
                change: '-AUD 2,953.00 (12.5%)',
            },
        ],
    ]).flat(2),
    dataOverviewTable2: Array.from({ length: 20 }, (_, idx) => [
        [
            {
                name: 'OneByOne Tokyo',
                thisPeriod: '15,580',
                lastPeriod: '11,544',
                change: '+4,036 (35%)',
            },
            {
                name: 'OneByOne New York City',
                thisPeriod: '14,698',
                lastPeriod: '14,002',
                change: '+696 (5%) ',
            },
            {
                name: 'OneByOne Paris',
                thisPeriod: '14,051',
                lastPeriod: '15,668',
                change: '-1,617 (10.3%) ',
            },
        ],
    ]).flat(2),
    joinChartOverview: {
        key: [
            {
                id: 'line1',
                color: '#8D99A5',
            },
            {
                id: 'line2',
                color: '#D9DDE1',
            },
        ],
        line1: [
            { x: 1, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 2, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 3, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 4, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 5, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 6, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 7, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 8, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 9, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            {
                x: 10,
                secName: '2014',
                y: faker.number.int({ min: 0, max: 10 }),
            },
            {
                x: 11,
                secName: '2014',
                y: faker.number.int({ min: 0, max: 10 }),
            },
            {
                x: 12,
                secName: '2014',
                y: faker.number.int({ min: 0, max: 10 }),
            },
        ],
        line2: [
            { x: 1, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 2, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 3, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 4, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 5, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 6, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 7, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 8, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 9, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            {
                x: 10,
                secName: '2014',
                y: faker.number.int({ min: 0, max: 10 }),
            },
            {
                x: 11,
                secName: '2014',
                y: faker.number.int({ min: 0, max: 10 }),
            },
            {
                x: 12,
                secName: '2014',
                y: faker.number.int({ min: 0, max: 10 }),
            },
        ],
    },
    barchartOverview: {
        key: [
            {
                id: 'f1',
                color: '#D9DDE1',
            },
            {
                id: 'f2',
                color: '#8D99A5',
            },
        ],
        data: [
            {
                name: 'This Period',
                f1: faker.number.int({ min: 10, max: 30 }),
                f2: faker.number.int({ min: 10, max: 30 }),
                amt: 10,
            },
            {
                name: 'Last Period',
                f1: faker.number.int({ min: 10, max: 30 }),
                f2: faker.number.int({ min: 10, max: 30 }),
                amt: 10,
            },
        ],
    },

    // segment trade lanes
    pieChartTradeLanes: {
        regionPieChart: [
            {
                name: 'Southeast Asia',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#5280C6',
                textColor: '#FFFFFF',
            },
            {
                name: 'Sub-Saharan',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#76C6CC',
                textColor: '#FFFFFF',
            },
            {
                name: 'Middle East',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#9D75AD',
                textColor: '#FFFFFF',
            },
            {
                name: 'Latin America',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#E3CD9A',
                textColor: '#FFFFFF',
            },
            {
                name: 'Western Europe',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#C98167',
                textColor: '#FFFFFF',
            },
            {
                name: 'Northern America',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#C9677F',
                textColor: '#FFFFFF',
            },
            {
                name: 'Australia',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#84B37D',
                textColor: '#FFFFFF',
            },
            {
                name: 'Others',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#465A6C',
                textColor: '#FFFFFF',
            },
        ],
        carrierPieChart: [
            {
                name: 'Maersk Line',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#5280C6',
                textColor: '#FFFFFF',
            },
            {
                name: 'Mediterranean',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#76C6CC',
                textColor: '#FFFFFF',
            },
            {
                name: 'CMA CGM',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#9D75AD',
                textColor: '#FFFFFF',
            },
            {
                name: 'Evergreen Line',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#E3CD9A',
                textColor: '#FFFFFF',
            },
            {
                name: 'Hapag-Lloyd',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#C98167',
                textColor: '#FFFFFF',
            },
            {
                name: 'COSCO Shipping',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#C9677F',
                textColor: '#FFFFFF',
            },
            {
                name: 'UPS',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#84B37D',
                textColor: '#FFFFFF',
            },
            {
                name: 'Others',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#465A6C',
                textColor: '#FFFFFF',
            },
        ],
    },
    dataTradeLanesTable: Array.from({ length: 20 }, (_, idx) => [
        [
            {
                company: 'Brazil',
                thisPeriod: '15,580',
                lastPeriod: '11,544',
                change: '+4,036 (35%)',
            },
            {
                company: 'Canada',
                thisPeriod: '14,698',
                lastPeriod: '14,002',
                change: '+696 (5%) ',
            },
            {
                company: 'Japan',
                thisPeriod: '14,051',
                lastPeriod: '15,668',
                change: '-1,617 (10.3%) ',
            },
        ],
    ]).flat(2),

    // segment customers
    customerChartOrganisationData: {
        total: 300000,
        data: [
            {
                bgColor: '#5280C6',
                textColor: '#FFFFFF',
                value: 90000,
            },
            {
                bgColor: '#76C6CC',
                textColor: '#FFFFFF',
                value: 84000,
            },
            {
                bgColor: '#9D75AD',
                textColor: '#FFFFFF',
                value: 30000,
            },
            {
                bgColor: '#C98167',
                textColor: '#FFFFFF',
                value: 18000,
            },
            {
                bgColor: '#E3CD9A',
                textColor: '#FFFFFF',
                value: 12000,
            },
            {
                bgColor: '#C9677F',
                textColor: '#FFFFFF',
                value: 9000,
            },
            {
                bgColor: '#D9DDE1',
                textColor: '#FFFFFF',
                value: 57000,
            },
        ],
    },
    customerTableData: Array.from({ length: 20 }, (_, idx) => {
        const color: string[] = [
            '#5280C6',
            '#76C6CC',
            '#9D75AD',
            '#E3CD9A',
            '#C98167',
            '#C9677F',
            '#C9677F',
        ]
        const randomColor = color[Math.floor(Math.random() * color.length)]

        return [
            {
                mark: randomColor,
                name: 'VALMANNEW',
                thisPeriod: '15,580',
                lastPeriod: '11,544',
                development: '+4,036 (35%)',
                rev: '30%',
                gp: '43.2%',
                vol: '58%',
                gpPerShipment: 'AUD 5,012.00',
            },
            {
                mark: randomColor,
                name: 'MICRESLON',
                thisPeriod: '14,698',
                lastPeriod: '14,002',
                development: '+696 (5.0%)',
                rev: '28%',
                gp: '25.2%',
                vol: '30.2%',
                gpPerShipment: 'AUD 3,952.00',
            },
            {
                mark: randomColor,
                name: 'APPDETSFO',
                thisPeriod: '14,698',
                lastPeriod: '15,668',
                development: '-1,617 (10.3%)',
                rev: '10%',
                gp: '78%',
                vol: '54%',
                gpPerShipment: 'AUD 2,269.00',
            },
        ]
    }).flat(2),

    // segment finance
    financeTableData: Array.from({ length: 20 }, (_, idx) => [
        [
            {
                name: 'REVENUE',
                thisPeriod: 'AUD 5,012.00',
                lastPeriod: 'AUD 5,984.00',
                change: '-AUD 972.00 (16.2%)',
            },
            {
                name: 'COSTS',
                thisPeriod: 'AUD 3,952.00',
                lastPeriod: 'AUD 2,547.62',
                change: '+AUD 1,404.38 (55.1%)',
            },
            {
                name: 'GP',
                thisPeriod: 'AUD 2,269.00',
                lastPeriod: 'AUD 2,002.58',
                change: '+AUD 266.42 (13.3%)',
            },
        ],
    ]).flat(2),
    financePieChart: [
        {
            name: 'Within payment term',
            value: faker.number.int({ min: 1000, max: 10000 }),
            fill: '#C0C7CD',
            textColor: '#FFFFFF',
        },
        {
            name: '1 week overdue',
            value: faker.number.int({ min: 1000, max: 10000 }),
            fill: '#5B6C7C',
            textColor: '#FFFFFF',
        },
        {
            name: '2 weeks overdue',
            value: faker.number.int({ min: 1000, max: 10000 }),
            fill: '#FFE3A4',
            textColor: '#FFFFFF',
        },
        {
            name: '1 month overdue',
            value: faker.number.int({ min: 1000, max: 10000 }),
            fill: '#E3CD9A',
            textColor: '#FFFFFF',
        },
        {
            name: '3 months overdue',
            value: faker.number.int({ min: 1000, max: 10000 }),
            fill: '#F691A9',
            textColor: '#FFFFFF',
        },
        {
            name: '6 months overdue',
            value: faker.number.int({ min: 1000, max: 10000 }),
            fill: '#C9677F',
            textColor: '#FFFFFF',
        },
    ],
    averageMonthlyPerformance: {
        chartData: {
            key: [
                {
                    id: 'line1',
                    color: '#5280C6',
                },
                {
                    id: 'line2',
                    color: '#C98167',
                },
                {
                    id: 'line3',
                    color: '#76C6CC',
                },
            ],
            line1: [],
            line2: [],
            line3: [],
        },
        tickItems: [],
    },
}

const controlTowerSlice = createSlice({
    name: 'ControlTowerIFB',
    initialState,
    reducers: {
        // segment common
        setHistoryData(
            state,
            action: PayloadAction<{
                development: {
                    line1: ILineData[]
                    line2: ILineData[]
                    tickItems: string[]
                }
                splitRecord: ISplitRecordBarchartData[]
            }>,
        ) {
            const { development, splitRecord } = action.payload
            state.historyData.development.chartData.line1 = development.line1
            state.historyData.development.chartData.line2 = development.line2
            state.historyData.development.tickItems = development.tickItems

            const splitRecordBarchartData = splitRecord.map((item) => ({
                ...item,
                amt: 10,
            }))
            state.historyData.splitRecord.data = splitRecordBarchartData
        },

        // segment finance
        setAverageMonthlyPerformance(
            state,
            action: PayloadAction<{
                line1: ILineData[]
                line2: ILineData[]
                line3: ILineData[]
                tickItems: string[]
            }>,
        ) {
            const { line1, line2, line3, tickItems } = action.payload
            state.averageMonthlyPerformance.chartData.line1 = line1
            state.averageMonthlyPerformance.chartData.line2 = line2
            state.averageMonthlyPerformance.chartData.line3 = line3
            state.averageMonthlyPerformance.tickItems = tickItems
        },
    },
})

// these all the variables used for selector\
// segment common
export const historyDataSelector = (state: RootState) =>
    state.controlTowerIFB.historyData || {}

// segment overview
export const dataOverviewTable1Selector = (state: RootState) =>
    state.controlTowerIFB.dataOverviewTable1 || {}
export const dataOverviewTable2Selector = (state: RootState) =>
    state.controlTowerIFB.dataOverviewTable2 || {}
export const joinChartOverviewSelector = (state: RootState) =>
    state.controlTowerIFB.joinChartOverview || {}
export const barChartOverviewSelector = (state: RootState) =>
    state.controlTowerIFB.barchartOverview || {}

// segment trade lanes
export const pieChartTradeLanesSelector = (state: RootState) =>
    state.controlTowerIFB.pieChartTradeLanes || {}
export const dataTradeLanesTableSelector = (state: RootState) =>
    state.controlTowerIFB.dataTradeLanesTable || {}

// segment customers
export const customerChartOrganisationDataSelector = (state: RootState) =>
    state.controlTowerIFB.customerChartOrganisationData || {}
export const customerTableDataSelector = (state: RootState) =>
    state.controlTowerIFB.customerTableData || {}

// segment finance
export const financeTableDataSelector = (state: RootState) =>
    state.controlTowerIFB.financeTableData || {}
export const financePieChartSelector = (state: RootState) =>
    state.controlTowerIFB.financePieChart || {}
export const averageMonthlyPerformanceSelector = (state: RootState) =>
    state.controlTowerIFB.averageMonthlyPerformance || {}

// all actions
export const { setHistoryData, setAverageMonthlyPerformance } =
    controlTowerSlice.actions

// Reducer
export default controlTowerSlice.reducer
