import * as Yup from 'yup'
import { startOfDay } from 'date-fns'

const today = startOfDay(new Date())
// interface for validation

export interface IDashboardFilterValidation {
    TransportModeCode: string
    ShipmentDateBy: string
    StartMonth: string
    EndMonth: string
}



export const useDashboardFilterValidation = () => {
    const dashboardFilterValidation = Yup.object().shape({
        StartMonth: Yup.date()
            .required('Start date is required')
            .nullable(),
        EndMonth: Yup.date()
            .required('End date is required')
            .nullable()
            .test('is-greater', 'End date must be later than start date', function (value) {
                const { StartMonth } = this.parent;
                return !StartMonth || !value || new Date(value) >= new Date(StartMonth);
            })
            .test('max-1-year', 'End date cannot be more than 1 year after start date', function (value) {
                const { StartMonth } = this.parent;
                if (!StartMonth || !value) return true;
                const startDateObj = new Date(StartMonth);
                const endDateObj = new Date(value);
                const oneYearLater = new Date(startDateObj.setFullYear(startDateObj.getFullYear() + 1));
                return endDateObj <= oneYearLater;
            }),
    })

    const dashboardFilterInitialValue: IDashboardFilterValidation = {
        TransportModeCode: 'all',
        ShipmentDateBy: 'eta',
        StartMonth: '3/2024',
        EndMonth: '9/2024',
    }


    return {
        dashboardFilterValidation,
        dashboardFilterInitialValue,
    }
}
