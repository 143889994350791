import { ITabItem } from '@components/tab/tab.interface'
import { IShipmentDetails } from 'client/ifb/repository/interface/shipment.interface'

export const tabDetailHeaders: ITabItem[] = [
    {
        label: 'Details',
        labelTranslationCode: 'tab_details.details',
        key: 'status',
        value: 'details',
        className: 'brand',
    },
    {
        label: 'Related Parties',
        key: 'status',
        value: 'relatedParties',
        className: 'brand',
    },
    {
        label: 'Pack Lines',
        labelTranslationCode: 'tab_details.pack_lines',
        key: 'status',
        value: 'packLines',
        className: 'brand',
    },
    // {
    //     label: 'Orders',
    //     labelTranslationCode: 'tab_details.order',
    //     key: 'status',
    //     value: 'orders',
    //     className: 'brand',
    // },
    {
        label: 'Tracking',
        labelTranslationCode: 'tab_details.tracking',
        key: 'status',
        value: 'tracking',
        className: 'brand',
    },
]

export const intialValueShipmentDetails: IShipmentDetails = {
    houseBill: '',
    shippersRef: '',
    orderRef: '',
    transportMode: '',
    containerMode: '',
    serviceLevelCode: '',
    serviceLevelDescription: '',
    goodsDescription: '',
    packs: 0,
    outerPacksUnitCode: '',
    outerPacksUnitDescription: '',
    totalWeight: 0,
    totalWeightUnit: '',
    totalVolume: 0,
    totalVolumeUnit: '',
    charges: {
        incoTerm: '',
        goodsValue: 0,
        goodsValueCurrencyCode: '',
        goodsValueCurrencyDescription: '',
        additionalTerms: '',
        insuranceValue: 0,
        insuranceValueCurrencyCode: '',
        insuranceValueCurrencyDescription: '',
        chargesApply: '',
    },
    origin: {
        portName: '',
        etdShipment: '',
        estimatedPickup: '',
        picRequiredBy: '',
        pickupTrnAdvised: '',
        actualPickup: '',
    },
    destination: {
        portName: '',
        etaShipment: '',
        estimatedDelivery: '',
        dlvRequiredBy: '',
        deliveryPortTransportAdvised: '',
        deliveryDate: '',
    },
    notes: {
        specialInstructions: '',
        detailedGoodsDesc: '',
        marksAndNumbers: '',
    },
    consignorPickupAddress: {
        company: '',
        address1: '',
        address2: '',
        city: '',
        state: {
            description: '',
            value: '',
        },
        postCode: '',
        countryCode: '',
        countryName: '',
        contactName: '',
        contactPhoneNumber: '',
        contactMobileNumber: '',
        contactEmailAddress: '',
    },
    consigneePickupAddress: {
        company: '',
        address1: '',
        address2: '',
        city: '',
        state: {
            description: '',
            value: '',
        },
        postCode: '',
        countryCode: '',
        countryName: '',
        contactName: '',
        contactPhoneNumber: '',
        contactMobileNumber: '',
        contactEmailAddress: '',
    },
    packLines: [],
    orders: [],
    relatedInvoices: null,
    shipmentTracking: [],
    consignorDocumentaryAddress: {
        company: '',
        address1: '',
        address2: '',
        city: '',
        state: {
            description: '',
            value: '',
        },
        postCode: '',
        countryCode: '',
        countryName: '',
        contactName: '',
        contactPhoneNumber: '',
        contactMobileNumber: '',
        contactEmailAddress: '',
    },
    consigneeDocumentaryAddress: {
        company: '',
        address1: '',
        address2: '',
        city: '',
        state: {
            description: '',
            value: '',
        },
        postCode: '',
        countryCode: '',
        countryName: '',
        contactName: '',
        contactPhoneNumber: '',
        contactMobileNumber: '',
        contactEmailAddress: '',
    },
}
