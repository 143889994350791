import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import {
    IFieldDropdownCompany,
    IFieldDropdownContactNameItem,
} from '../../bookings.interface'
import { useTranslation } from 'react-i18next'
import { IUseCreateBooking } from '../create-booking.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'

const RelatedParties = ({ CBService }: { CBService: IUseCreateBooking }) => {
    const { t } = useTranslation()
    const propertyForm = [
        {
            label: 'Consignor Pickup Address',
            entity: 'shipperAddress[0]',
            dropdownContact: CBService.dropdownDataContact.shipperAddress1,
            companyDropdown: CBService.dropdownData.shippercompanies1,
        },
        {
            label: 'Consignor Documentary Address',
            entity: 'shipperAddress[1]',
            dropdownContact: CBService.dropdownDataContact.shipperAddress2,
            companyDropdown: CBService.dropdownData.shippercompanies2,
        },
        {
            label: 'Consignee Pickup Address',
            entity: 'consigneeAddress[0]',
            dropdownContact: CBService.dropdownDataContact.consigneeAddress1,
            companyDropdown: CBService.dropdownData.consgineecompanies1,
        },
        {
            label: 'Consignee Documentary Address',
            entity: 'consigneeAddress[1]',
            dropdownContact: CBService.dropdownDataContact.consigneeAddress2,
            companyDropdown: CBService.dropdownData.consgineecompanies2,
        },
    ]
    return (
        <div className="p-3 grid grid-cols-4 gap-3">
            {propertyForm.map((item) => {
                return (
                    <div key={item.entity} className="flex flex-col gap-3">
                        <div>{item.label}</div>
                        <div key={`${item.entity}.company`}>
                            <FormDropdown<IFieldDropdownCompany>
                                isSearchable
                                label={t('bookings.company')}
                                name={`${item.entity}.company`}
                                options={item.companyDropdown}
                                defaultValue={item.companyDropdown}
                                isAsync={true}
                                loadOptions={(e: string) => {
                                    switch (item.entity) {
                                        case propertyForm[0].entity:
                                            return CBService.loadOptions({
                                                shippercompanies1: e,
                                            })
                                        case propertyForm[1].entity:
                                            return CBService.loadOptions({
                                                shippercompanies2: e,
                                            })
                                        case propertyForm[2].entity:
                                            return CBService.loadOptions({
                                                consgineecompanies1: e,
                                            })
                                        case propertyForm[3].entity:
                                            return CBService.loadOptions({
                                                consgineecompanies2: e,
                                            })
                                    }
                                }}
                                additionalOnClick={(data) => {
                                    if (
                                        item.entity === propertyForm[0].entity
                                    ) {
                                        CBService.additionalOnClickCompany(
                                            [
                                                item.entity,
                                                propertyForm[1].entity,
                                            ],
                                            data as IDropdownItem<IFieldDropdownCompany>,
                                        )
                                    } else if (
                                        item.entity === propertyForm[2].entity
                                    ) {
                                        CBService.additionalOnClickCompany(
                                            [
                                                item.entity,
                                                propertyForm[3].entity,
                                            ],
                                            data as IDropdownItem<IFieldDropdownCompany>,
                                        )
                                    } else if (
                                        item.entity ===
                                            propertyForm[1].entity ||
                                        item.entity === propertyForm[3].entity
                                    ) {
                                        CBService.additionalOnClickCompany(
                                            [item.entity],
                                            data as IDropdownItem<IFieldDropdownCompany>,
                                        )
                                    }
                                }}
                                placeholder={t('action.enter', {
                                    value: t('bookings.company'),
                                })}
                                parentDivClassName="!mb-0 col-span-2"
                                isClearable={true}
                            />
                        </div>
                        <FormInput
                            label={t('bookings.street')}
                            name={`${item.entity}.address1`}
                            placeholder={t('action.enter', {
                                value: t('bookings.street'),
                            })}
                            parentDivClassName="!mb-0 col-span-2"
                            disabled
                        />
                        <FormInput
                            label={t('bookings.additional_street')}
                            name={`${item.entity}.address2`}
                            placeholder={t('action.enter', {
                                value: t('bookings.additional_street'),
                            })}
                            parentDivClassName="!mb-0 col-span-2"
                            disabled
                        />
                        <FormInput
                            label={t('bookings.city')}
                            name={`${item.entity}.city`}
                            placeholder={t('action.enter', {
                                value: t('bookings.city'),
                            })}
                            parentDivClassName="!mb-0"
                            disabled
                        />
                        <FormInput
                            label={t('bookings.state')}
                            name={`${item.entity}.state.value`}
                            placeholder={t('action.enter', {
                                value: t('bookings.state'),
                            })}
                            parentDivClassName="!mb-0"
                            disabled
                        />
                        <FormInput
                            label={t('bookings.postcode')}
                            name={`${item.entity}.postCode`}
                            placeholder={t('action.enter', {
                                value: t('bookings.postcode'),
                            })}
                            parentDivClassName="!mb-0"
                            disabled
                        />

                        {/* temporarily replace with input form country  */}
                        <FormInput
                            label={t('bookings.country')}
                            name={`${item.entity}.countryCode`}
                            placeholder={t('action.enter', {
                                value: t('bookings.country'),
                            })}
                            parentDivClassName="!mb-0 "
                            disabled
                        />
                        <div key={`${item.entity}.contactName`}>
                            <FormDropdown<IFieldDropdownContactNameItem>
                                isSearchable
                                label={t('bookings.contact_name')}
                                name={`${item.entity}.contactName`}
                                placeholder={t('action.enter', {
                                    value: t('bookings.contact_name'),
                                })}
                                parentDivClassName="!mb-0 col-span-2"
                                options={item.dropdownContact}
                                isClearable
                                additionalOnClick={(data) => {
                                    if (
                                        item.entity === propertyForm[0].entity
                                    ) {
                                        CBService.additionalOnClickContactName(
                                            [
                                                item.entity,
                                                propertyForm[1].entity,
                                            ],
                                            data as IDropdownItem<IFieldDropdownContactNameItem>,
                                        )
                                    } else if (
                                        item.entity === propertyForm[1].entity
                                    ) {
                                        CBService.additionalOnClickContactName(
                                            [item.entity],
                                            data as IDropdownItem<IFieldDropdownContactNameItem>,
                                        )
                                    } else if (
                                        item.entity === propertyForm[2].entity
                                    ) {
                                        CBService.additionalOnClickContactName(
                                            [
                                                item.entity,
                                                propertyForm[3].entity,
                                            ],
                                            data as IDropdownItem<IFieldDropdownContactNameItem>,
                                        )
                                    } else if (
                                        item.entity === propertyForm[3].entity
                                    ) {
                                        CBService.additionalOnClickContactName(
                                            [item.entity],
                                            data as IDropdownItem<IFieldDropdownContactNameItem>,
                                        )
                                    }
                                }}
                            />
                        </div>
                        <FormInput
                            label={t('bookings.contact_email_address')}
                            name={`${item.entity}.contactEmailAddress`}
                            placeholder={t('action.enter', {
                                value: t('bookings.contact_email_address'),
                            })}
                            parentDivClassName="!mb-0 col-span-2"
                            disabled
                        />
                        <FormInput
                            label={t('bookings.contact_phone')}
                            name={`${item.entity}.contactPhoneNumber`}
                            placeholder={t('action.enter', {
                                value: t('bookings.contact_phone'),
                            })}
                            parentDivClassName="!mb-0"
                            disabled
                        />
                        <FormInput
                            label={t('bookings.contact_mobile')}
                            name={`${item.entity}.contactMobileNumber`}
                            placeholder={t('action.enter', {
                                value: t('bookings.contact_mobile'),
                            })}
                            parentDivClassName="!mb-0"
                            disabled
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default RelatedParties
