import { IResponseData } from 'common/common.interface'
import { fetch, post } from 'common/common.service'
import { ifbEndpoints } from '../endpoints/ifb.endpoints'
import {
    IFilterModuleTemplates,
    IManageUser,
    IManageUserFilter,
    IModuleTemplate,
} from '../pages/manage-users/manage-users.interface'
import { IManageUserLog } from '../pages/manage-users/manage-user-details/manage-users-details.interface'
import { Toast } from '@components/toast/toast.component'
import { ICreateUserValidation } from '../form-validation/create-user.validation'

export const getManageUserData = async (filter: IManageUserFilter) => {
    try {
        const response = await fetch<
            IResponseData<IManageUser[]>,
            IManageUserFilter
        >({
            endpoint: ifbEndpoints.manage_user_getAll,
            params: filter,
        })
        if (!response.isSuccess) {
            Toast({
                header: 'Manage User data',
                message: `Failed to fetch Manage User data.`,
                type: 'error',
            })
        }
        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Manage User data',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Manage User data',
                message: `Unknown error occurred while fetching Manage User data."`,
                type: 'error',
            })
        }
        const err = error as IResponseData<IManageUser[]>
        err.isSuccess = false
        return err
    }
}

export const getManageUserID = async (filter: IManageUserFilter) => {
    try {
        const endpoint = `${ifbEndpoints.manage_user_getAll}/${filter.id}`
        const response = await fetch<
            IResponseData<IManageUser>,
            IManageUserFilter
        >({
            endpoint: endpoint,
        })
        if (!response.isSuccess) {
            Toast({
                header: 'Manage User ID data',
                message: `Failed to fetch Manage User data.`,
                type: 'error',
            })
        }
        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Manage User ID data',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Manage User ID data',
                message: `Unknown error occurred while fetching Manage User ID data."`,
                type: 'error',
            })
        }
        const err = error as IResponseData<IManageUser>
        err.isSuccess = false
        return err
    }
}

export const getManageUserIDLog = async (filter: IManageUserFilter) => {
    try {
        const endpoint = `${ifbEndpoints.manage_user_getAll}/${filter.id}/activities`
        const response = await fetch<
            IResponseData<IManageUserLog[]>,
            IManageUserFilter
        >({
            endpoint: endpoint,
        })
        if (!response.isSuccess) {
            Toast({
                header: 'Manage User ID Log data',
                message: `Failed to fetch Manage User data.`,
                type: 'error',
            })
        }
        return response
    } catch (error: unknown) {
        if (error instanceof Error) {
            Toast({
                header: 'Manage User ID Log data',
                message: `${error.message}`,
                type: 'error',
            })
        } else {
            Toast({
                header: 'Manage User ID Log data',
                message: `Unknown error occurred while fetching Manage User ID Log data."`,
                type: 'error',
            })
        }
        const err = error as IResponseData<IManageUserLog[]>
        err.isSuccess = false
        return err
    }
}

export const getCurrentUser = async () => {
    try {
        const response = await fetch<IResponseData<IManageUser>, string>({
            endpoint: `${ifbEndpoints.manage_user_getCurrentUser}`,
        })
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IManageUser>
        err.isSuccess = false
        return err
    }
}

export const getAccessModule = async (filter: IFilterModuleTemplates) => {
    try {
        const response = await fetch<IResponseData<IModuleTemplate[]>, string>({
            endpoint: `${ifbEndpoints.manage_user_getModuleTemplates}`,
            params: filter,
        })
        return response
    } catch (error: any) {
        console.error(error)
        const err = error as IResponseData<IModuleTemplate[]>
        err.isSuccess = false

        return err
    }
}

export const createUser = async (payload: ICreateUserValidation) => {
    try {
        const response = await post<IResponseData<any>, ICreateUserValidation>({
            endpoint: `${ifbEndpoints.manage_user_getAll}`,
            payload,
        })
        return response
    } catch (error: any) {
        return error.response.data
    }
}
