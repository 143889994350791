var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useLayoutEffect, useRef, useState } from 'react';
import { Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import DonutLoading from '../loading/donat.loading.component';
function DoughnutChart(_a) {
    var data = _a.data, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b, colorChart = _a.colorChart;
    var _c = useState({ width: 0, height: 0 }), dimensions = _c[0], setDimensions = _c[1];
    var ref = useRef(null);
    var diameter = Math.min(dimensions.width, dimensions.height);
    var outerRadius = diameter / 2;
    var innerRadius = diameter / 4;
    useLayoutEffect(function () {
        var handleResize = function () {
            if (ref.current) {
                setDimensions({
                    width: ref.current.offsetWidth,
                    height: ref.current.offsetHeight,
                });
            }
        };
        // Initial dimension set
        handleResize();
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    }, [isLoading]);
    var CustomTooltip = function (_a) {
        var active = _a.active, payload = _a.payload;
        if (active && payload && payload.length) {
            var _b = payload[0], name_1 = _b.name, value = _b.value;
            return (_jsxs("div", __assign({ className: "custom-tooltip p-2 rounded-md themes-bg-gray-v9 opacity-80 themes-text-white flex items-center" }, { children: [_jsx("div", { className: "w-[20px] h-[20px] bg-white rounded-md mr-2", style: { backgroundColor: payload[0].payload.fill } }), _jsx("p", __assign({ className: "label" }, { children: "".concat(name_1, ": US$ ").concat(value.toLocaleString()) }))] })));
        }
        return null;
    };
    var renderColorfulLegendText = function (item) {
        var totalValue = item.reduce(function (acc, itemData) { return acc + itemData.value; }, 0);
        return (_jsx("div", __assign({ className: "col-span-6 lg:col-span-7 grid lg:grid-cols-2 gap-3" }, { children: item.map(function (itemData, index) {
                var value = itemData.value, name = itemData.name, fill = itemData.fill, textColor = itemData.textColor;
                var percentage = ((value / totalValue) * 100).toFixed(2);
                var formattedValue = value.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
                return (_jsxs("div", __assign({ className: "flex flex-col text-size-XS leading-none" }, { children: [name && (_jsx("div", __assign({ className: "thames-text-gray-v9 pt-1 whitespace-nowrap text-ellipsis overflow-hidden" }, { children: name }))), _jsx("div", __assign({ className: "thames-text-gray-v9 2xl:text-size-XS xl:text-[10px] font-bold" }, { children: "US$ ".concat(formattedValue) })), _jsx("div", __assign({ style: { background: "".concat(fill) }, className: "w-auto h-4 px-1 bg-red-100 rounded justify-center items-center gap-1 inline-flex " }, { children: _jsxs("div", __assign({ style: { color: "".concat(textColor) }, className: " font-bold leading-[18px]" }, { children: ["%", percentage !== 'NaN' ? percentage : 0] })) }))] }), index));
            }) })));
    };
    return (_jsx(_Fragment, { children: isLoading ? (_jsx(DonutLoading, {})) : (_jsxs("div", __assign({ className: "flex grid grid-cols-12 gap-3 flex-grow" }, { children: [_jsx(ResponsiveContainer, __assign({ className: "flex !col-span-6 lg:!col-span-5", ref: ref }, { children: _jsxs(PieChart, __assign({ className: "content-center" }, { children: [_jsx(Pie, { data: data, cx: "50%", cy: "50%", innerRadius: innerRadius, outerRadius: outerRadius, fill: "#8884d8", paddingAngle: 0, dataKey: "value" }), _jsx(Tooltip, { content: _jsx(CustomTooltip, {}) })] })) })), colorChart ? colorChart : renderColorfulLegendText(data)] }))) }));
}
export default DoughnutChart;
