import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import {
    IBookingStatusRespon,
    IContainerModerRespon,
    INewsData,
    IScfiData,
    IShipmentStatusRespon,
    IShipmentStatusRespon2,
} from './dashboard.interface'
import { faker } from '@faker-js/faker'
import {
    IBarChartStackedData,
    IDonatRechart,
} from '@components/rechart-component/rechart-interface'
import { IDashboardFilterValidation } from 'client/ifb/form-validation/dashboard.validation'
import {
    IShipment,
    IShipmentFilter,
} from 'client/ifb/repository/interface/shipment.interface'
import { IMeta, IResponseData } from 'common/common.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import {
    metaDataDropdown,
    IFieldDropdownMetaData,
} from '../bookings/bookings.interface'
import { ITabItem } from '@components/tab/tab.interface'

interface IDashboardState {
    news: INewsData[]
    scfi_index: IScfiData[]
    barInvoice: IBarChartStackedData
    barContainer: IBarChartStackedData
    transtMode: IDropdownItem[]
    statusContainerMode: {
        fclShipment: {
            sea: number
            road: number
            rail: number
        }
        lclShipment: {
            sea: number
            road: number
            rail: number
        }
    }
    filters: IDashboardFilterValidation
    itemInvoice: Array<{
        value: string
        label: string
    }>
    itemInvoiceUnPaid: Array<{
        value: string
        label: string
    }>
    donatItem: IDonatRechart[]
    dataItem: {
        quote: {
            total: number
            approved: number
            pending: number
            delayed: number
            newQuotes: number
        }
        bookings: IBookingStatusRespon
        shipment: IShipmentStatusRespon
        shipment2: IShipmentStatusRespon2
        warehouse: {
            total: number
            inStock: number
            lowStock: number
            outOfStock: number
            newOrders: number
        }
        co2Emissions: {
            total: number
            sea: number
            air: number
            road: number
            rail: number
        }
    }
    TrackingShipmentLocation: {
        data: IShipment[]
        meta: IMeta
    }
    filterShipmentLocation: IShipmentFilter
    statusSelected: ITabItem
}

export const initialState: IDashboardState = {
    news: [],
    scfi_index: [],
    filters: {
        TransportModeCode: '',
        ShipmentDateBy: '',
        StartMonth: '',
        EndMonth: '',
    },
    itemInvoice: [
        { value: '158', label: 'Total Invoice Paid' },
        { value: 'US$ 38,585.00', label: 'Average per month' },
        { value: 'US$ 100,585.00', label: 'Total paid amount' },
    ],
    itemInvoiceUnPaid: [
        { value: '2,203', label: 'Total unpaid invoices' },
        { value: '2,145', label: 'Total unpaid amount' },
    ],
    barContainer: {
        key: [
            {
                id: 'f1',
                color: '#D9DDE1',
            },
        ],
        data: [
            {
                name: 'FCL',
                f1: 0,
                amt: 5,
            },
            {
                name: 'LCL',
                f1: 0,
                amt: 5,
            },
            {
                name: 'Loose (AIR)',
                f1: 0,
                amt: 5,
            },
            {
                name: 'FTL (ROAD)',
                f1: 0,
                amt: 5,
            },
            {
                name: 'Courier',
                f1: 0,
                amt: 5,
            },
        ],
    },
    statusContainerMode: {
        fclShipment: {
            sea: 0,
            road: 0,
            rail: 0,
        },
        lclShipment: {
            sea: 0,
            road: 0,
            rail: 0,
        },
    },
    barInvoice: {
        key: [
            {
                id: 'f1',
                color: '#D9DDE1',
            },
        ],
        data: [
            {
                name: 'JAN',
                secName: '2024',
                f1: faker.number.int({ min: 10, max: 30 }),
                amt: 5,
            },
            {
                name: 'FEB',
                secName: '2024',
                f1: faker.number.int({ min: 10, max: 30 }),
                amt: 5,
            },
            {
                name: 'MAR',
                secName: '2024',
                f1: faker.number.int({ min: 10, max: 30 }),
                amt: 5,
            },
            {
                name: 'APR',
                secName: '2024',
                f1: faker.number.int({ min: 10, max: 30 }),
                amt: 5,
            },
            {
                name: 'MAY',
                secName: '2024',
                f1: faker.number.int({ min: 10, max: 30 }),
                amt: 5,
            },
            {
                name: 'JUN',
                secName: '2024',
                f1: faker.number.int({ min: 10, max: 30 }),
                amt: 5,
            },
            {
                name: 'JUL',
                secName: '2024',
                f1: faker.number.int({ min: 10, max: 30 }),
                amt: 5,
            },
        ],
    },
    donatItem: [
        {
            name: 'Within payment term',
            value: faker.number.int({ min: 20001, max: 49000 }),
            fill: '#C0C7CD',
            textColor: '#FFFFFF',
        },
        {
            name: '1 week overdue',
            value: faker.number.int({ min: 20001, max: 49000 }),
            fill: '#5B6C7C',
            textColor: '#FFFFFF',
        },
        {
            name: '2 weeks overdue',
            value: faker.number.int({ min: 20001, max: 49000 }),
            fill: '#FFE3A4',
            textColor: '#FFFFFF',
        },
        {
            name: '1 month overdue',
            value: faker.number.int({ min: 20001, max: 49000 }),
            fill: '#E3CD9A',
            textColor: '#FFFFFF',
        },
        {
            name: '2 months overdue',
            value: faker.number.int({ min: 20001, max: 49000 }),
            fill: '#F691A9',
            textColor: '#FFFFFF',
        },
        {
            name: '3 month overdue',
            value: faker.number.int({ min: 20001, max: 49000 }),
            fill: '#C9677F',
            textColor: '#FFFFFF',
        },
    ],
    dataItem: {
        quote: {
            total: 1500,
            approved: 1200,
            pending: 200,
            delayed: 50,
            newQuotes: 50,
        },
        bookings: {
            TotalBooking: 0,
            Booked: 0,
            Confirmed: 0,
            Cancelled: 0,
            NewBookings: 0,
        },
        shipment: {
            AheadOfSchedule: 0,
            Delayed: 0,
            NewShipment: 0,
            OnSchedule: 0,
            TotalShipment: 0,
        },
        warehouse: {
            total: 2500,
            inStock: 2000,
            lowStock: 300,
            outOfStock: 100,
            newOrders: 100,
        },
        co2Emissions: {
            total: 5000,
            sea: 3000,
            air: 1000,
            road: 800,
            rail: 200,
        },
        shipment2: {
            delayed: 0,
            completed: 0,
            completedLate: 0,
            totalStatus: 0,
        },
    },
    TrackingShipmentLocation: {
        data: [],
        meta: {
            current_page: 0,
            last_page: 0,
            per_page: 0,
            total_page: 0,
            total_Items: 0,
            from: 0,
            to: 0,
            index_end: 0,
            index_start: 0,
        },
    },
    filterShipmentLocation: {
        status: '',
        inProgressStatus: '',
        pageNumber: 1,
        pageSize: 15,
    },
    statusSelected: {
        label: 'All Invoces',
        key: 'status',
        value: 'allInvoices',
        className: 'brand',
    },
    transtMode: [{ value: 'all', label: 'All Mode' }],
}

const dashboardSlice = createSlice({
    name: 'Dashboard',
    initialState,
    reducers: {
        setNews: (state, action: PayloadAction<INewsData[]>) => {
            state.news = action.payload
        },
        setShipmentStatus: (
            state,
            action: PayloadAction<IShipmentStatusRespon>,
        ) => {
            state.dataItem.shipment = action.payload
        },
        setShipmentStatus2: (
            state,
            action: PayloadAction<IShipmentStatusRespon2>,
        ) => {
            state.dataItem.shipment2 = action.payload
        },
        setBookingStatus: (
            state,
            action: PayloadAction<IBookingStatusRespon>,
        ) => {
            state.dataItem.bookings = action.payload
        },
        setContainermodeStatus: (
            state,
            action: PayloadAction<IContainerModerRespon>,
        ) => {
            const statusContainerMode = {
                fclShipment: action.payload.fclShipment,
                lclShipment: action.payload.lclShipment,
            }
            state.statusContainerMode = statusContainerMode
            state.barContainer.data = state.barContainer.data.map((item) => {
                switch (item.name) {
                    case 'FCL':
                        return { ...item, f1: action.payload.container.fcl }
                    case 'LCL':
                        return { ...item, f1: action.payload.container.lcl }
                    case 'Loose (AIR)':
                        return { ...item, f1: action.payload.container.loose }
                    case 'FTL (ROAD)':
                        return { ...item, f1: action.payload.container.ftl }
                    case 'Courier':
                        return { ...item, f1: 50 }
                    default:
                        return item
                }
            })
        },
        setTrackingShipmentLocation(
            state,
            action: PayloadAction<IResponseData<IShipment[]>>,
        ) {
            const { data, meta } = action.payload
            const dataTrackingShipment = {
                meta: meta,
                data: data,
            }
            const d = {
                TrackingShipmentLocation: dataTrackingShipment,
            }
            return {
                ...state,
                ...d,
            }
        },
        setDashboardDropdown(state, action: PayloadAction<metaDataDropdown>) {
            const convertToDropdownItems = (
                dropdown: IFieldDropdownMetaData[],
            ): IDropdownItem[] => {
                const items = dropdown.map((data, index) => ({
                    label:
                        index === 0
                            ? 'All Mode'
                            : data.item + ' - ' + data.value,
                    value: index === 0 ? 'all' : data.item,
                }))
                return items
            }

            const item = action.payload.transportMode
            return {
                ...state,
                transtMode: convertToDropdownItems(item),
            }
        },
        setScfiIndex: (state, action: PayloadAction<IScfiData[]>) => {
            state.scfi_index = action.payload
        },
        setPageNumber(state, action: PayloadAction<number>) {
            const pageNumber = action.payload
            const filter = { ...state.filterShipmentLocation, pageNumber }
            return {
                ...state,
                filterShipmentLocation: filter,
            }
        },
        setSelectedStatus(state, action: PayloadAction<ITabItem>) {
            const statusSelected = action.payload

            return {
                ...state,
                statusSelected,
            }
        },
    },
})

export const {
    setNews,
    setScfiIndex,
    setShipmentStatus,
    setBookingStatus,
    setContainermodeStatus,
    setTrackingShipmentLocation,
    setDashboardDropdown,
    setPageNumber,
    setSelectedStatus,
    setShipmentStatus2,
} = dashboardSlice.actions

export const newsSelector = (state: RootState) => state.dashboardIFB.news
export const itemSelector = (state: RootState) => state.dashboardIFB.dataItem
export const filtersSelector = (state: RootState) => state.dashboardIFB.filters
export const dropdownTransModeSelector = (state: RootState) =>
    state.dashboardIFB.transtMode
export const barInvoiceSelector = (state: RootState) =>
    state.dashboardIFB.barInvoice
export const trackingShipmentLocationeSelector = (state: RootState) =>
    state.dashboardIFB.TrackingShipmentLocation
export const itemInvoiceSelector = (state: RootState) =>
    state.dashboardIFB.itemInvoice
export const itemInvoiceUnPaidSelector = (state: RootState) =>
    state.dashboardIFB.itemInvoiceUnPaid
export const barContainerSelector = (state: RootState) =>
    state.dashboardIFB.barContainer
export const statusContainerModeSelector = (state: RootState) =>
    state.dashboardIFB.statusContainerMode
export const itemDonatSelector = (state: RootState) =>
    state.dashboardIFB.donatItem
export const scfiIndexSelector = (state: RootState) =>
    state.dashboardIFB.scfi_index
export const filterShipmentDataSelector = (state: RootState) =>
    state.dashboardIFB.filterShipmentLocation
export const tabStatusFilterSelector = (state: RootState) =>
    state.dashboardIFB.statusSelected || {}

export default dashboardSlice.reducer
