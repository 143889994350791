import { IProgressBarChartData } from '../control-tower.interface'

const ProgressBarChart = ({
    containerClassNames = '',
    data,
}: {
    containerClassNames?: string
    data: IProgressBarChartData
}) => {
    return (
        <div className={`flex gap-1 ${containerClassNames}`}>
            {data.data.map((item) => {
                const total = data.total
                const width = `${Math.round((item.value / total) * 100)}%`
                return (
                    <div
                        className={`rounded h-[20px] flex items-center justify-center`}
                        style={{
                            width,
                            backgroundColor: item.bgColor,
                            color: item.textColor,
                        }}
                    >
                        {width}
                    </div>
                )
            })}
        </div>
    )
}

export default ProgressBarChart
