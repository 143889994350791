/* eslint-disable no-unused-vars */
import { ITabItem } from '@components/tab/tab.interface'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formBookingStatusHeader } from './create-booking.static'
import { FormikContextType, useFormik } from 'formik'
import {
    ICreateBookingValidation,
    ICreateBookingContainersValidation,
    useBookingsCreateValidation,
    ICreateBookingPackLinesValidationForm,
} from 'client/ifb/form-validation/booking-details.validation'
import { useDispatch, useSelector } from 'react-redux'
import {
    BookingsCreate,
    getGetContactData,
    getGetMetadataBookings,
} from 'client/ifb/repository/booking.repository'
import {
    bookingsDataDropdownContactSelector,
    bookingsDataDropdownSelector,
    idModeDropdownSelector,
    setBookingsDropdown,
    setBookingsDropdownContact,
    setLoadOptionsCreateBooking,
    setMode,
} from '../bookings.slice'
import {
    metaDataDropdown,
    IFieldDropdownCompany,
    IFieldDropdownContactName,
    IFieldDropdownContactNameItem,
} from '../bookings.interface'
import { IOption } from '@components/measurement-input/measurement-input.interface'
import {
    ILoadOptionsParams,
    IUseCreateBooking,
} from './create-booking.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { debounce } from 'common/common.service'

const useCreateBooking = (): IUseCreateBooking => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // validate
    const {
        createBookingInitialValue,
        createBookingPackLinesValidation,
        createBookingValidation,
        createContainerBookingsInitialValue,
        createContainersBookingsValidation,
        createBookingPackLinesInitialValueForm,
    } = useBookingsCreateValidation()

    // selectors
    const dropdownData = useSelector(bookingsDataDropdownSelector)
    const dropdownDataContact = useSelector(bookingsDataDropdownContactSelector)

    const modeTipe = useSelector(idModeDropdownSelector)

    // states
    const [tabFilter, setTabFilter] = useState<ITabItem>({
        label: 'Book By',
        key: 'status',
        value: 'bookBy',
        className: 'brand',
    })
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [tabFormBookingItems, setTabFormBookingItems] = useState<ITabItem[]>(
        formBookingStatusHeader,
    )
    const [tabFormBooking, setTabFormBooking] = useState<ITabItem>(
        formBookingStatusHeader[0],
    )

    // Pack Lines
    const [packLinesData, setPackLinesData] = useState<
        ICreateBookingPackLinesValidationForm[]
    >([])
    const [packLineData, setPackLineData] =
        useState<ICreateBookingPackLinesValidationForm>(
            createBookingPackLinesInitialValueForm,
        )
    const [packLineParams, setPackLineParams] = useState<
        'update' | 'add' | 'duplicate' | 'delete'
    >('update')

    // Containers
    const [containersData, setContainersData] = useState<
        ICreateBookingContainersValidation[]
    >([])
    const [containerData, setContainerData] =
        useState<ICreateBookingContainersValidation>(
            createContainerBookingsInitialValue,
        )
    const [containerParams, setContainerParams] = useState<
        'update' | 'add' | 'duplicate' | 'delete'
    >('update')

    // formik
    const formikCreateBookingPackLines =
        useFormik<ICreateBookingPackLinesValidationForm>({
            validationSchema: createBookingPackLinesValidation,
            initialValues: packLineData,
            enableReinitialize: true,
            onSubmit: (values, { resetForm }) => {
                // setValues
                const lengthDescription = getDescription(
                    dropdownData.lengthWidthHeightMeasure as IOption[],
                    values?.specialField?.lengthMeasure?.unit,
                )
                values.lengthMeasure =
                    values.specialField.lengthMeasure.qty || 0
                values.lengthMeasureUnitCode =
                    values.specialField.lengthMeasure.unit
                values.lengthMeasureUnitDescription = lengthDescription || ''

                const widthDescription = getDescription(
                    dropdownData.lengthWidthHeightMeasure as IOption[],
                    values.specialField.widthMeasure.unit,
                )
                values.widthMeasure = Number(
                    values.specialField.widthMeasure.qty,
                )
                values.widthMeasureUnitCode =
                    values.specialField.widthMeasure.unit
                values.widthMeasureUnitDescription = widthDescription || ''

                const heightDescription = getDescription(
                    dropdownData.lengthWidthHeightMeasure as IOption[],
                    values.specialField.heightMeasure.unit,
                )
                values.heightMeasure = Number(
                    values.specialField.heightMeasure.qty,
                )
                values.heightMeasureUnitCode =
                    values.specialField.heightMeasure.unit
                values.heightMeasureUnitDescription = heightDescription || ''

                const weightDescription = getDescription(
                    dropdownData.lengthWidthHeightMeasure as IOption[],
                    values.specialField.weightMeasure.unit,
                )
                values.weightMeasure = Number(
                    values.specialField.weightMeasure.qty,
                )
                values.weightMeasureUnitCode =
                    values.specialField.weightMeasure.unit
                values.weightMeasureUnitDescription = weightDescription || ''

                const volumeDescription = getDescription(
                    dropdownData.lengthWidthHeightMeasure as IOption[],
                    values.specialField.volumeMeasure.unit,
                )
                values.volumeMeasure = Number(
                    values.specialField.volumeMeasure.qty,
                )
                values.volumeMeasureUnitCode =
                    values.specialField.volumeMeasure.unit
                values.volumeMeasureUnitDescription = volumeDescription || ''

                let updateData = []
                let resetValues = null
                switch (packLineParams) {
                    case 'add':
                        updateData = [
                            ...packLinesData,

                            {
                                ...values,
                                specialField: {
                                    ...values.specialField,
                                    id: packLinesData.length + 1,
                                },
                            },
                        ]
                        resetValues = createBookingPackLinesInitialValueForm
                        break
                    case 'update':
                        updateData = packLinesData.map((item) =>
                            item.specialField.id === values.specialField.id
                                ? { ...item, ...values }
                                : item,
                        )
                        resetValues = values
                        break
                    case 'duplicate':
                        updateData = [
                            ...packLinesData,
                            {
                                ...values,
                                specialField: {
                                    ...values.specialField,
                                    id: packLinesData.length + 1,
                                },
                            },
                        ]
                        break
                    case 'delete':
                        updateData = packLinesData.filter(
                            (item) =>
                                item.specialField.id !== values.specialField.id,
                        )
                        resetValues = createBookingPackLinesInitialValueForm
                        break
                    default:
                        updateData = packLinesData
                        break
                }
                setPackLinesData(updateData)
                if (resetValues !== null) {
                    resetForm({
                        values: resetValues as ICreateBookingPackLinesValidationForm,
                    })
                }
            },
        })
    const formikCreateContainerBookings =
        useFormik<ICreateBookingContainersValidation>({
            validationSchema: createContainersBookingsValidation,
            initialValues: containerData,
            enableReinitialize: true,
            onSubmit: (values, { resetForm }) => {
                values.containerType.category.code = values.containerType.code
                values.containerType.category.description =
                    values.containerType.code
                values.containerType.isoCode = values.containerType.code

                let updateData = []
                let resetValues = null
                switch (containerParams) {
                    case 'add':
                        updateData = [
                            ...containersData,
                            { ...values, id: containersData.length + 1 },
                        ]
                        resetValues = createContainerBookingsInitialValue
                        break
                    case 'update':
                        updateData = containersData.map((item) =>
                            item.id === values.id
                                ? { ...item, ...values }
                                : item,
                        )
                        resetValues = values
                        break
                    case 'duplicate':
                        updateData = [
                            ...containersData,
                            { ...values, id: containersData.length + 1 },
                        ]
                        break
                    case 'delete':
                        updateData = containersData.filter(
                            (item) => item.id !== values.id,
                        )
                        resetValues = createContainerBookingsInitialValue
                        break
                    default:
                        updateData = containersData
                        break
                }

                setContainersData(updateData)
                if (resetValues !== null) {
                    resetForm({ values: resetValues })
                }
            },
        })

    const formikCreateBooking = useFormik<ICreateBookingValidation>({
        validationSchema: createBookingValidation,
        initialValues: createBookingInitialValue,
        onSubmit: async (values) => {
            // handle temporary values
            formikCreateBooking.setFieldValue(
                'shipperAddress[0].state.description',
                values.shipperAddress[0].state.value,
            )
            formikCreateBooking.setFieldValue(
                'shipperAddress[1].state.description',
                values.shipperAddress[1].state.value,
            )
            formikCreateBooking.setFieldValue(
                'consigneeAddress[0].state.description',
                values.consigneeAddress[0].state.value,
            )
            formikCreateBooking.setFieldValue(
                'consigneeAddress[1].state.description',
                values.consigneeAddress[1].state.value,
            )

            // handle value in detail content
            const transportModeDesc = getDescription(
                sortDataDropdown() as IOption[],
                values.transportModeCode,
            )
            values.transportModeDesc = transportModeDesc

            // handle field measurementInput
            const weightDescription = getDescription(
                dropdownData.weightMeasure as IOption[],
                values.specialField.weightMeasure.unit,
            )
            values.loglinesBookingCreateBookingDetails.weightMeasure =
                values.specialField.weightMeasure.qty || 0
            values.loglinesBookingCreateBookingDetails.weightMeasureUnitCode =
                values.specialField.weightMeasure.unit
            values.loglinesBookingCreateBookingDetails.weightMeasureUnitDescription =
                weightDescription || ''

            const volumeDescription = getDescription(
                dropdownData.volumeMeasure as IOption[],
                values.specialField.volumeMeasure.unit,
            )
            values.loglinesBookingCreateBookingDetails.volumeMeasure =
                values.specialField.volumeMeasure.qty || 0
            values.loglinesBookingCreateBookingDetails.volumeMeasureUnitCode =
                values.specialField.volumeMeasure.unit
            values.loglinesBookingCreateBookingDetails.volumeMeasureUnitDescription =
                volumeDescription || ''

            values.loglinesBookingCreatePacklines = packLinesData.map(
                ({ specialField, ...rest }) => rest,
            )
            values.loglinesContainerCollection = containersData.map(
                ({ id, ...rest }) => rest,
            )
            try {
                setLoading(true)
                const response: any = await BookingsCreate(values)
                setLoading(false)
                const id = response?.data?.key
                if (response?.isSuccess) navigate('/booking-details/' + id)
            } catch (error) {
                setLoading(false)
                console.error(error)
            }
        },
    })

    const getDescription = (options: IOption[], value: string) => {
        const option = options.find((item) => item.value === value)
        return option?.label?.toString().split('-')[1].trim() || '-'
    }

    // Get Data Function
    const loadData = async (e: string) => {
        try {
            setLoading(true)
            const actionResultDropdown = (await getGetMetadataBookings({
                idForm: e,
                country: '',
                origin: '',
                destination: '',
                currency: '',
                company: '',
                container: '',
            })) as metaDataDropdown
            dispatch(setBookingsDropdown(actionResultDropdown))
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    const getContactOptions = async ({
        idCompany,
        goal,
    }: {
        idCompany: string
        goal: string
    }) => {
        try {
            const actionResultDropdown = await getGetContactData({
                idCompany: idCompany,
            })
            dispatch(
                setBookingsDropdownContact({
                    goal,
                    contact: actionResultDropdown as IFieldDropdownContactName,
                }),
            )
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    const loadOptions = debounce(
        async <T,>({
            countryItem,
            originsItem,
            destinationItem,
            currencyItem,
            containerItem,
            shippercompanies1,
            shippercompanies2,
            consgineecompanies1,
            consgineecompanies2,
            insuranceCurrencyItem,
        }: ILoadOptionsParams) => {
            try {
                const actionResult = (await getGetMetadataBookings({
                    idForm: modeTipe,
                    country: countryItem,
                    origin: originsItem,
                    destination: destinationItem,
                    currency: currencyItem || insuranceCurrencyItem,
                    container: containerItem,
                    company:
                        shippercompanies1 ||
                        shippercompanies2 ||
                        consgineecompanies1 ||
                        consgineecompanies2,
                })) as metaDataDropdown

                let setOptions: IDropdownItem<T>[] = []
                let loadType

                switch (true) {
                    case !!originsItem:
                        setOptions = actionResult.origins.map((item) => ({
                            label: `${item.location} - ${item.name}`,
                            value: item.location,
                        }))
                        loadType = 'origin'
                        break
                    case !!destinationItem:
                        setOptions = actionResult.destinations.map((item) => ({
                            label: `${item.location} - ${item.name}`,
                            value: item.location,
                        }))
                        loadType = 'destination'
                        break
                    case !!containerItem:
                        setOptions = actionResult.containerModes.map(
                            (item) => ({
                                label: `${item.code} - ${item.description}`,
                                value: item.code,
                            }),
                        )
                        loadType = 'container'
                        break
                    case !!currencyItem:
                        setOptions = actionResult.currency.map((item) => ({
                            label: `${item.code} - ${item.title}`,
                            value: item.code,
                        }))
                        loadType = 'currency'
                        break
                    case !!shippercompanies1:
                        setOptions = actionResult.companies.map((item) => ({
                            label: item.company,
                            value: item.company,
                            additionalData: item,
                        })) as IDropdownItem<T>[]
                        loadType = 'shippercompanies'
                        break
                    case !!shippercompanies2:
                        setOptions = actionResult.companies.map((item) => ({
                            label: item.company,
                            value: item.company,
                        }))
                        loadType = 'shippercompanies2'
                        break
                    case !!consgineecompanies1:
                        setOptions = actionResult.companies.map((item) => ({
                            label: item.company,
                            value: item.company,
                        }))
                        loadType = 'consgineecompanies1'
                        break
                    case !!consgineecompanies2:
                        setOptions = actionResult.companies.map((item) => ({
                            label: item.company,
                            value: item.company,
                        }))
                        loadType = 'consgineecompanies2'
                        break
                    case !!insuranceCurrencyItem:
                        setOptions = actionResult.currency.map((item) => ({
                            label: `${item.code} - ${item.title}`,
                            value: item.code,
                        }))
                        loadType = 'insuranceCurrency'
                        break
                    default:
                        break
                }
                if (setOptions.length && loadType) {
                    dispatch(
                        setLoadOptionsCreateBooking({
                            options: setOptions,
                            loadType,
                        }),
                    )
                    return setOptions
                }
            } catch (error) {
                console.error(error)
            }
        },
        500,
    )

    const formatContainerMode = (mode: string) => {
        switch (mode) {
            case 'loose':
                return 'Loose'
            case 'fcl':
                return 'Full Container Load'
            case 'ftl':
                return 'Full Truck Load'
            case 'courier':
                return 'Courier'
            case 'lcl':
                return 'Less Container Load'
            default:
                return mode
        }
    }

    const sortDataDropdown = () => {
        let options
        switch (modeTipe) {
            case 'loose':
                options = [
                    {
                        label: 'AIR - Air Freight',
                        value: 'AIR',
                    },
                ]
                break
            case 'fcl':
            case 'lcl':
                options = [
                    {
                        label: 'SEA - Sea Freight',
                        value: 'SEA',
                    },
                    {
                        label: 'ROA - Road Freight',
                        value: 'ROA',
                    },
                    {
                        label: 'RAI - Rail Freight',
                        value: 'RAI',
                    },
                ]
                break
            case 'ftl':
                options = [
                    {
                        label: 'ROA - Road Freight',
                        value: 'ROA',
                    },
                ]
                break
            case 'courier':
                options = [
                    {
                        label: 'COU - COURIER',
                        value: 'COU',
                    },
                ]
                break
            default:
                options = [
                    {
                        label: 'AIR - Air Freight',
                        value: 'AIR',
                    },
                ]
                break
        }
        return options
    }

    const goToFormCreate = useCallback(
        async (e: string) => {
            const formattedValue = formatContainerMode(e)
            formikCreateBooking.setFieldValue('containerMode', formattedValue)
            dispatch(setMode(e))
            loadData(e)
            setTabFilter({
                label: 'Details',
                key: 'status',
                value: 'goToFormCreate',
                className: 'brand',
            })
        },
        [formikCreateBooking, dispatch, setTabFilter, modeTipe],
    )

    const additionalOnClickCompany = (
        entity: string[],
        data?: IDropdownItem<IFieldDropdownCompany>,
    ) => {
        const id = data?.additionalData?.id as string
        entity.forEach(async (item) => {
            formikCreateBooking?.setFieldValue(`${item}.idCompany`, id || '')
            formikCreateBooking?.setFieldValue(
                `${item}.company`,
                data?.additionalData?.company || '',
            )
            formikCreateBooking?.setFieldValue(
                `${item}.address1`,
                data?.additionalData?.address1 || '',
            )
            formikCreateBooking?.setFieldValue(
                `${item}.address2`,
                data?.additionalData?.address2 || '',
            )
            formikCreateBooking?.setFieldValue(
                `${item}.city`,
                data?.additionalData?.city || '',
            )
            formikCreateBooking?.setFieldValue(
                `${item}.state.value`,
                data?.additionalData?.state || '',
            )
            formikCreateBooking?.setFieldValue(
                `${item}.postCode`,
                data?.additionalData?.postCode || '',
            )
            formikCreateBooking?.setFieldValue(
                `${item}.countryCode`,
                data?.additionalData?.countryCode || '',
            )
            formikCreateBooking?.setFieldValue(
                `${item}.countryName`,
                data?.additionalData?.countryName || '',
            )
            if (id) {
                await getContactOptions({
                    idCompany: id,
                    goal: `${item}.idCompany`,
                })
            }
            if (!data) {
                // remove value in contact name
                formikCreateBooking?.setFieldValue(`${item}.contactName`, '')
                formikCreateBooking?.setFieldValue(
                    `${item}.contactEmailAddress`,
                    '',
                )
                formikCreateBooking?.setFieldValue(
                    `${item}.contactPhoneNumber`,
                    '',
                )
                formikCreateBooking?.setFieldValue(
                    `${item}.contactMobileNumber`,
                    '',
                )
            }
        })
    }

    const additionalOnClickContactName = (
        entity: string[],
        data?: IDropdownItem<IFieldDropdownContactNameItem>,
    ) => {
        entity.forEach((item) => {
            formikCreateBooking?.setFieldValue(
                `${item}.contactName`,
                data?.additionalData?.contactName || '',
            )
            formikCreateBooking?.setFieldValue(
                `${item}.contactEmailAddress`,
                data?.additionalData?.contactEmailAddress || '',
            )
            formikCreateBooking?.setFieldValue(
                `${item}.contactPhoneNumber`,
                data?.additionalData?.contactPhoneNumber || '',
            )
            formikCreateBooking?.setFieldValue(
                `${item}.contactMobileNumber`,
                data?.additionalData?.contactMobileNumber || '',
            )
        })
    }

    const loadContainerDropdown = async (containerItem: string) => {
        try {
            const options = (await getGetMetadataBookings({
                idForm: modeTipe,
                container: containerItem,
            })) as metaDataDropdown
            if (options) dispatch(setBookingsDropdown(options))
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
            setErrorMessage(errorMessage)
        }
    }

    const handleClickBack = () => {
        if (tabFilter.value === 'bookBy') {
            navigate(-1)
        } else {
            setTabFormBooking(formBookingStatusHeader[0])
            setTabFilter({
                label: 'Book By',
                key: 'status',
                value: 'bookBy',
                className: 'brand',
            })
        }
    }

    const additionalClickSetDesc = <T,>(
        formik: FormikContextType<T>,
        field: string,
        data: IDropdownItem,
    ) => {
        const _data = data as IDropdownItem
        const label = _data?.label as string
        const value = label?.split('-')[1].trim()
        formik.setFieldValue(field, value)
    }

    const handleFormRequired = () => {
        if (formikCreateBooking?.submitCount === 0) return

        const formError = formikCreateBooking?.errors

        const relatedPartiesFormErrors =
            (formError?.shipperAddress?.length &&
                formError?.shipperAddress?.length > 0) ||
            (formError?.consigneeAddress?.length &&
                formError?.consigneeAddress?.length > 0)

        const detailsFormErrors =
            formError.pickupRequiredBy ||
            formError.deliveryRequiredBy ||
            formError.transportModeCode ||
            formError.containerMode ||
            formError.originPortCode ||
            formError.destinationPortCode ||
            formError.loglinesBookingCreateBookingDetails?.goodsValue ||
            formError.loglinesBookingCreateBookingDetails?.insuranceValue ||
            formError.loglinesBookingCreateBookingDetails?.packs

        const updatedTabs: ITabItem[] = tabFormBookingItems.map((tab) => {
            if (tab.value === 'relatedParties' && relatedPartiesFormErrors) {
                return { ...tab, className: 'red-error' }
            } else if (tab.value === 'details' && detailsFormErrors) {
                return { ...tab, className: 'red-error' }
            } else {
                return { ...tab, className: 'brand' }
            }
        })
        setTabFormBookingItems(updatedTabs)
    }

    // useEffect
    useEffect(() => {
        handleFormRequired()
    }, [formikCreateBooking.submitCount, formikCreateBooking.errors])

    return {
        // common
        loading,
        setLoading,
        modeTipe,
        errorMessage,
        loadOptions,
        navigate,
        dropdownData,
        tabFilter,
        setTabFilter,
        goToFormCreate,
        tabFormBooking,
        setTabFormBooking,
        handleClickBack,
        sortDataDropdown,
        additionalClickSetDesc,
        tabFormBookingItems,

        // segment details
        additionalOnClickCompany,
        additionalOnClickContactName,
        formikCreateBooking,

        // segment related parties
        dropdownDataContact,
        getContactOptions,

        // segment packlines
        packLinesData,
        setPackLineData,
        setPackLineParams,
        formikCreateBookingPackLines,

        // segment container
        containersData,
        setContainerData,
        setContainerParams,
        formikCreateContainerBookings,
        loadContainerDropdown,
    }
}

export default useCreateBooking
