/* eslint-disable no-unused-vars */
import { IBookingsDetailsResponse } from '../../bookings.interface'

const Details = ({
    data,
    bookingNo,
}: {
    data: IBookingsDetailsResponse
    bookingNo?: string
}) => {
    const loadDataComponent = ({
        label,
        value,
    }: {
        label: string
        value?: string | number
    }) => (
        <div className="leading-none">
            <div className="themes-text-gray-v5">{label}</div>
            <div className="themes-text-gray-v9">{value || '-'}</div>
        </div>
    )
    const serviceLevelData =
        data?.shipmentDetails[0]?.serviceLevelCode &&
        data?.shipmentDetails[0]?.serviceLevelDescription
            ? `${data?.shipmentDetails[0]?.serviceLevelCode}, ${data?.shipmentDetails[0]?.serviceLevelDescription}`
            : ''

    return (
        <div className="p-3 h-full">
            <div className="grid grid-cols-4 gap-3  h-full">
                {/* shipments details */}
                <div className="flex flex-col gap-1">
                    <div className="text-size-L themes-text-gray-v9 mb-1">
                        Shipment Details
                    </div>
                    {loadDataComponent({
                        label: 'Booking No',
                        value: bookingNo,
                    })}
                    {loadDataComponent({
                        label: 'Container Mode',
                        value: data?.shipmentDetails[0]?.containerMode,
                    })}
                    {loadDataComponent({
                        label: 'Transport Mode',
                        value: data?.shipmentDetails[0]?.transportMode,
                    })}
                    {loadDataComponent({
                        label: "Shipper's Ref",
                        value: data?.shipmentDetails[0]?.shippersRef,
                    })}
                    {loadDataComponent({
                        label: 'Service Level',
                        value: serviceLevelData,
                    })}
                    {loadDataComponent({
                        label: 'Outer Packs',
                        value: `${data?.shipmentDetails[0]?.packs} ${data?.shipmentDetails[0]?.outerPacksUnitCode}`,
                    })}
                    {loadDataComponent({
                        label: 'Weight Measure',
                        value: data?.shipmentDetails[0]?.weightMeasureKG,
                    })}
                    {loadDataComponent({
                        label: 'Volume Measure',
                        value: data?.shipmentDetails[0]?.volumeMeasureM3,
                    })}
                </div>

                {/* Origin & destination */}
                <div className="flex flex-col gap-3 border-l pl-3">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Origin
                        </div>
                        {loadDataComponent({
                            label: 'Origin Port',
                            value: data?.detailsOrigin?.originPort,
                        })}
                        {loadDataComponent({
                            label: 'Pickup Required By',
                            value: data?.detailsOrigin?.pickupRequiredBy,
                        })}
                        {loadDataComponent({
                            label: 'Estimated Pickup',
                            value: data?.detailsOrigin?.estimatedPickup,
                        })}
                        {loadDataComponent({
                            label: 'Pickup Equipment Needed',
                            value: data?.detailsOrigin?.pickupEquipmentNeeded,
                        })}
                    </div>
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 my-1">
                            Destination
                        </div>
                        {loadDataComponent({
                            label: 'Destination Port',
                            value: data?.detailsDestination?.destinationPort,
                        })}
                        {loadDataComponent({
                            label: 'Delivery Required By',
                            value: data?.detailsDestination?.deliveryRequiredBy,
                        })}
                        {loadDataComponent({
                            label: 'Estimated Delivery',
                            value: data?.detailsDestination?.estimatedDelivery,
                        })}
                        {loadDataComponent({
                            label: 'Delivery Equipment Needed',
                            value: data?.detailsDestination
                                ?.deliveryEquipmentNeeded,
                        })}
                    </div>
                </div>

                {/* charges */}
                <div className="flex flex-col gap-3 border-l pl-3">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Charges
                        </div>
                        {loadDataComponent({
                            label: 'INCO Term',
                            value: data?.detailsCharges?.incoTerm,
                        })}

                        {loadDataComponent({
                            label: 'Goods Value',
                            value: `${data?.detailsCharges?.goodsValue} ${data?.detailsCharges?.goodsValueCurrency}`,
                        })}
                        {loadDataComponent({
                            label: 'Additional Terms',
                            value: data?.detailsCharges?.additionalTerms,
                        })}
                        {loadDataComponent({
                            label: 'Insurance Value',
                            value: `${data?.detailsCharges?.insuranceValue} ${data?.detailsCharges?.insuranceValueCurrencyCode}`,
                        })}
                        {loadDataComponent({
                            label: 'Charges Apply',
                            value: data?.detailsCharges?.chargeApply,
                        })}
                    </div>
                </div>

                {/* notes */}
                <div className="flex flex-col gap-3 border-l pl-3">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Notes
                        </div>
                        {loadDataComponent({
                            label: 'Marks and Numbers',
                            value: data?.shipmentDetails[0]?.marksAndNumbers,
                        })}
                        {loadDataComponent({
                            label: 'Goods Description',
                            value: data?.shipmentDetails[0]?.goodsDescription,
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Details
