var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell, } from 'recharts';
import BarLoading from '../loading/bar-loading.component';
var BarChartStacked = function (_a) {
    var data = _a.data, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b;
    var _c = useState(window.innerWidth), widthSize = _c[0], setWidthSize = _c[1];
    var ref = useRef(null);
    var updateDimensions = function () {
        setWidthSize(window.innerWidth);
    };
    useEffect(function () {
        window.addEventListener('resize', updateDimensions);
        return function () {
            window.removeEventListener('resize', updateDimensions);
        };
    });
    // remove this function if it is no longer used
    // const RoundedTopBar = (props: IRoundedBarProps) => {
    //     const { x, y, width, height, fill, index, isLastIndex } = props
    //     const isTopBar = index === data.key.length - 1
    //     const rounded = isLastIndex ? 2 : 0
    //     return (
    //         <g>
    //             <rect
    //                 x={x}
    //                 y={y}
    //                 width={width}
    //                 height={height}
    //                 fill={fill}
    //                 // rx={rounded}
    //                 // ry={rounded}
    //                 // radius={[0, 10, 10, 0]}
    //                 radius={10}
    //                 rx={isLastIndex ? 3 : 0}
    //                 ry={isLastIndex ? 3 : 0}
    //             />
    //             {isTopBar && (
    //                 <rect
    //                     x={x}
    //                     y={y ? y - 4 : y}
    //                     width={width}
    //                     height={5}
    //                     fill={fill}
    //                     rx={5}
    //                     ry={5}
    //                 />
    //             )}
    //         </g>
    //     )
    // }
    // CustomTooltip Component
    var CustomTooltip = function (_a) {
        var active = _a.active, payload = _a.payload, label = _a.label;
        if (active && payload && payload.length) {
            return (_jsxs("div", __assign({ className: "custom-tooltip p-2 rounded shadow-md themes-bg-gray-v9 opacity-80 themes-text-white" }, { children: [_jsx("p", __assign({ className: "label text-sm font-semibold" }, { children: "".concat(label) })), payload.map(function (entry, index) {
                        return (_jsxs("p", __assign({ className: "intro text-xs flex mb-1 items-centes" }, { children: [_jsx("div", { style: { backgroundColor: entry.fill }, className: "w-[20px] h-[20px] rounded-sm mr-3" }), "item ".concat(index + 1, " : ").concat(entry.value.toLocaleString())] }), "item-".concat(index)));
                    })] })));
        }
        return null;
    };
    var CustomTick = function (_a) {
        var x = _a.x, y = _a.y, payload = _a.payload;
        var matchingData = data.data.find(function (item) { return item.name === payload.value; });
        var fontSize = widthSize > 1279 && widthSize < 1440 ? '10px' : '12px';
        // Function untuk memotong teks dan menambahkan ellipsis
        var getEllipsisText = function (text, maxLength) {
            // Step 1: Check if text exceeds maxLength
            if (text.length > maxLength) {
                // Step 2: Check if text contains '('
                var indexOfParenthesis = text.indexOf('(');
                if (indexOfParenthesis !== -1) {
                    // Step 3: Remove the text inside parentheses including the parentheses
                    text = text.substring(0, indexOfParenthesis).trim();
                }
                // Step 4: Check again if text exceeds maxLength after removing parentheses
                if (text.length > maxLength) {
                    return text.substring(0, maxLength) + '...';
                }
            }
            // Return the original or trimmed text
            return text;
        };
        var calculateWidthContent = function () {
            var _a;
            var width = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.offsetWidth;
            if (!width)
                return 0;
            var lengthBar = data.data.length;
            var calculate = (width / lengthBar) * 0.3; // 0.3 from barCategoryGap in BarChart
            return calculate;
        };
        var maxLength = calculateWidthContent() < 20 ? 4 : 20;
        var displayText = getEllipsisText(payload.value, maxLength);
        var secondaryText = getEllipsisText((matchingData === null || matchingData === void 0 ? void 0 : matchingData.secName) || '', maxLength);
        return (_jsxs("g", __assign({ transform: "translate(".concat(x, ",").concat(y, ")") }, { children: [_jsx("text", __assign({ x: 0, y: 0, dy: 16, textAnchor: "middle", fill: "#20384E", fontSize: fontSize }, { children: displayText })), _jsx("text", __assign({ x: 0, y: 0, dy: 30, textAnchor: "middle", fill: "#C0C7CD", fontSize: fontSize }, { children: secondaryText }))] })));
    };
    var tickFormatter = function (value, index) {
        var limit = 1; // put your maximum character
        if (value.length < limit)
            return value;
        return "".concat(value.substring(0, limit), "...");
    };
    return (_jsx(_Fragment, { children: isLoading ? (_jsx(BarLoading, {})) : (_jsx(ResponsiveContainer, __assign({ width: "100%", height: "100%", ref: ref }, { children: _jsxs(BarChart, __assign({ width: 500, height: 500, data: data.data, margin: {
                    top: 0,
                    right: 0,
                    left: -35,
                    bottom: -20,
                }, barCategoryGap: "30%" }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "name", height: 60, style: { fontSize: '6px' }, tick: _jsx(CustomTick, {}), interval: 0, tickFormatter: tickFormatter }), _jsx(YAxis, { tick: { fill: '#666' }, style: { fontSize: '12px' } }), _jsx(Tooltip, { content: _jsx(CustomTooltip, {}) }), data.key.map(function (item, idx) {
                        return (_jsx(Bar, __assign({ dataKey: item.id, stackId: "a", fill: item.color, radius: [3, 3, 0, 0] }, { children: data.data.map(function (entry, index) {
                                var keys = Object.keys(entry);
                                var values = Object.values(entry);
                                var channelNameIndex = keys.findIndex(function (key) { return key === item.id; });
                                var lastBarIndex = values.findLastIndex(function (value) { return value !== 0; });
                                if (channelNameIndex ===
                                    lastBarIndex - 1) {
                                    return (_jsx(Cell, {}, "cell-".concat(index)));
                                }
                                return (_jsx(Cell, { radius: 0 }, "cell-".concat(index)));
                            }) }), "item-bar-".concat(idx)));
                    })] })) }))) }));
};
export default BarChartStacked;
