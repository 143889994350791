import TableExpandableDetail from '@components/table-expandable-detail/table-expandable-detail.component'
import { TrackingSummaryDetailHeader } from 'client/ifb/pages/trackings/trackings.static'
import GoogleMap from '@components/google-map/google-map.component'
import { ITrackingSumary } from 'client/ifb/pages/trackings/trackings.interface'

const Tracking = ({ data }: { data: ITrackingSumary[] }) => {
    return (
        <div className="flex w-full h-full">
            <div className="w-1/2">
                <TableExpandableDetail<ITrackingSumary>
                    data={data || []}
                    loading={false}
                    summaryDetail={TrackingSummaryDetailHeader}
                    nextHandling={(page) => {}}
                    previousHandling={(page) => {}}
                    moduleTitle={'Trackings'}
                    enableExport={false}
                />
            </div>
            <div className="w-1/2 themes-bg-white rounded-[10px] p-[5px] sm:h-[500px] md:h-full  shadow-md ">
                <GoogleMap />
            </div>
        </div>
    )
}

export default Tracking
