import * as Yup from 'yup'
import { startOfDay } from 'date-fns'

const today = startOfDay(new Date())
// interface for validation

export interface IVolumeFilterValidation {
    MeasureCategory: string
    UnitMeasure:string
    ShipmentDateBy:string
    StartDate: string
    EndDate:string
}


export const useVolumeFilterValidation = () => {

    const volumeFilterValidation = Yup.object().shape({
 
    })

    const volumeFilterInitialValue: IVolumeFilterValidation = {
        MeasureCategory: 'volume',
        UnitMeasure: 'M3',
        ShipmentDateBy: 'eta',
        StartDate: '3/2024',
        EndDate: '9/2024'
    }


    return {
        volumeFilterValidation,
        volumeFilterInitialValue,
    }
}
