import TabMaster from '@components/tab-master/tab-master.component'
import useTrackings from './trackings.service'
import TableExpandableDetail from '@components/table-expandable-detail/table-expandable-detail.component'
import GoogleMap from '@components/google-map/google-map.component'
import TrackingsFilterOverlay from './components/trackins-filter-overlay.component'
import { useTranslation } from 'react-i18next'
import { ITrackingSumary } from './trackings.interface'
import {
    trackingMoreOptions,
    TrackingSummaryDetailHeader,
} from './trackings.static'

const Trackings = () => {
    const { t } = useTranslation()
    const {
        tabItems,
        tabFilter,
        trackingsData,
        loading,
        setTabFilter,
        filterOverlayService,
        setPageData,
        handleSearch,
        navigate,
        trackingsDataMeta,
        widthSize
    } = useTrackings()

    return (
        <div className="content-full-height grid grid-cols-12 gap-4">
            <div className={`${widthSize > 1279 && widthSize < 1441 ? 'lg:col-span-7' :'lg:col-span-6'} md:col-span-12 sm:col-span-12 flex flex-col container-global`}>
                <TabMaster
                    items={tabItems || []}
                    tabFilter={tabFilter}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={t('action.search', {
                        value: t('tracking.shipment_id'),
                    })}
                    onSearchSubmit={handleSearch}
                    containerSearchClassName={'flex-1'}
                    //  button for overlay or something ----------------------------------------
                    // iconButton1={{
                    //     onClick: () =>
                    //         filterOverlayService.toggleOverlayHandling(),
                    //     icon: 'ri-filter-2-line',
                    //     filterOverlayComponent: (
                    //         <TrackingsFilterOverlay
                    //             filterOverlayService={filterOverlayService}
                    //         />
                    //     ),
                    // }}
                />

                <TableExpandableDetail<ITrackingSumary>
                    data={trackingsData}
                    loading={loading}
                    summaryDetail={TrackingSummaryDetailHeader}
                    nextHandling={(page) => {
                        setPageData(page)
                    }}
                    previousHandling={(page) => {
                        setPageData(page)
                    }}
                    onRowClick={(data, moduleDetails) => {
                        navigate(`/trackings-details/${moduleDetails}/2`)
                    }}
                    meta={trackingsDataMeta}
                    moreOptions={trackingMoreOptions}
                    moduleTitle={'Trackings'}
                    enableExport={false}
                />
            </div>
            <div className={`${widthSize > 1279 && widthSize < 1440 ? 'lg:col-span-5' :'lg:col-span-6'}  md:col-span-12 sm:hidden themes-bg-white rounded-[10px] p-[5px] w-full sm:h-[500px] md:h-full shadow-md`}>
                <GoogleMap />
            </div>
        </div>
    )
}

export default Trackings
