var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Text, } from 'recharts';
import BarLoading from '../loading/bar-loading.component';
var BarChartDouble = function (_a) {
    var data = _a.data, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b;
    var _c = useState(window.innerWidth), widthSize = _c[0], setWidthSize = _c[1];
    var _d = useState(window.innerHeight), heightSize = _d[0], setHeightSize = _d[1];
    var _e = useState({
        width: widthSize,
        height: 235,
    }), dimension = _e[0], setDimension = _e[1];
    var updateDimensions = function () {
        setWidthSize(window.innerWidth);
        setHeightSize(window.innerHeight);
        var newDimension = __assign({}, dimension);
        if (heightSize === 1080)
            newDimension.height = 250;
        else if (heightSize < 1440 && heightSize > 1300) {
            newDimension.height = 440;
        }
        else if (heightSize === 911)
            newDimension.height = 320;
        setDimension(newDimension);
    };
    useEffect(function () {
        window.addEventListener('resize', updateDimensions);
        return function () {
            window.removeEventListener('resize', updateDimensions);
        };
    });
    var RoundedBar = function (props) {
        var x = props.x, y = props.y, width = props.width, height = props.height;
        return (_jsx("g", { children: _jsx("rect", { x: x, y: y, width: width, height: height, fill: props.fill, rx: 2, ry: 2 }) }));
    };
    var CustomizedXAxisTick = function (_a) {
        var x = _a.x, y = _a.y, payload = _a.payload, width = _a.width;
        if (payload === undefined) {
            if (width === undefined)
                width = 0;
            payload = { value: '' };
        }
        var words = payload.value.split(' ');
        var maxWordsPerLine = 3;
        var lines = [];
        for (var i = 0; i < words.length; i += maxWordsPerLine) {
            lines.push(words.slice(i, i + maxWordsPerLine).join(' '));
        }
        var isTextOverlapping = width ? width < lines.join('').length * 15 : 0;
        return (_jsx("g", __assign({ transform: "translate(".concat(x, ",").concat(y, ")") }, { children: isTextOverlapping
                ? null
                : lines.map(function (line, index) { return (_jsx("text", __assign({ x: width ? width / 1000 : 0, y: index * 16, dy: 16, textAnchor: "middle", fill: "#666", fontSize: heightSize < 900 ? 9 : 12 }, { children: line }), index)); }) })));
    };
    var CustomizedLabel = function (props) {
        var x = props.x, y = props.y, width = props.width, height = props.height, value = props.value;
        return (_jsx(Text, { x: x + width / 2, y: y + height / 2, dy: 5, fill: "#fff", textAnchor: "middle" }));
    };
    return (_jsx(_Fragment, { children: isLoading ? (_jsx(BarLoading, {})) : (_jsx(ResponsiveContainer, __assign({ width: "100%", height: "100%" }, { children: _jsxs(BarChart, __assign({ data: data.item, margin: {
                    top: 0,
                    right: 0,
                    left: -25,
                    bottom: -30,
                } }, { children: [_jsx(CartesianGrid, { stroke: "#f5f5f5" }), _jsx(XAxis, { dataKey: "name", interval: 0, height: 70, tick: _jsx(CustomizedXAxisTick, {}) }), _jsx(YAxis, { type: 'number' }), _jsx(Tooltip, {}), _jsx(Bar, { dataKey: "pv", fill: data.barColor1, shape: _jsx(RoundedBar, {}), label: _jsx(CustomizedLabel, {}) }), _jsx(Bar, { dataKey: "uv", fill: data.barColor2, shape: _jsx(RoundedBar, {}), label: _jsx(CustomizedLabel, {}) })] })) }))) }));
};
export default BarChartDouble;
