import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import "./checkbox.style.css";
function Checkbox(_a) {
    var _b = _a.isChecked, isChecked = _b === void 0 ? false : _b, value = _a.value, onChecked = _a.onChecked, disabled = _a.disabled;
    var _c = useState(false), checked = _c[0], setChecked = _c[1];
    useEffect(function () {
        setChecked(isChecked);
    }, [isChecked]);
    return (_jsx("input", { className: "rounded-logistical-radius-2 cursor-pointer loglines-checkbox themes-border-color", type: "checkbox", checked: checked, disabled: disabled, onClick: function () {
            var val = !checked;
            setChecked(val);
            if (val === true) {
                onChecked(value !== null && value !== void 0 ? value : true);
            }
            else {
                onChecked(false);
            }
        }, onChange: function () { } }));
}
export default Checkbox;
