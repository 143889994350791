import {
    IBarChartStackedData,
    IDonatRechart,
} from '@components/rechart-component/rechart-interface'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { IBarDataRespon, IDonatChartShipmentRespon } from './volumes.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import {
    metaDataDropdown,
    IFieldDropdownMetaData,
} from '../bookings/bookings.interface'

interface IVolumeState {
    itemTotalBarChart: {
        sea: {
            fcl: number
            lcl: number
        }
        road: {
            fcl: number
            lcl: number
            ftl: number
        }
        rail: {
            fcl: number
            lcl: number
        }
        air: {
            lse: number
        }
        cour: {
            fcl: number
        }
        donatShipment: number
    }
    barChart: {
        road: IBarChartStackedData
        sea: IBarChartStackedData
        rail: IBarChartStackedData
        air: IBarChartStackedData
        courier: IBarChartStackedData
    }
    donatChart: IDonatRechart[]
    volumeMeasure: IDropdownItem[]
    weightMeasure: IDropdownItem[]
}

export const initialState: IVolumeState = {
    barChart: {
        road: {
            key: [
                {
                    id: 'f1',
                    color: '#00A0AA',
                },
                {
                    id: 'f2',
                    color: '#06CAD7',
                },
                {
                    id: 'f3',
                    color: '#CDF4F7',
                },
            ],
            data: [],
        },
        sea: {
            key: [
                {
                    id: 'f1',
                    color: '#A647F0',
                },
                {
                    id: 'f2',
                    color: '#EDDAFC',
                },
            ],
            data: [],
        },
        rail: {
            key: [
                {
                    id: 'f1',
                    color: '#FFD167',
                },
                {
                    id: 'f2',
                    color: '#FFF6E1',
                },
            ],
            data: [],
        },
        air: {
            key: [
                {
                    id: 'f1',
                    color: '#266EDC',
                },
            ],
            data: [],
        },
        courier: {
            key: [
                {
                    id: 'f1',
                    color: '#F04770',
                },
            ],
            data: [],
        },
    },
    itemTotalBarChart: {
        sea: {
            fcl: 0,
            lcl: 0,
        },
        road: {
            fcl: 0,
            lcl: 0,
            ftl: 0,
        },
        rail: {
            fcl: 0,
            lcl: 0,
        },
        air: {
            lse: 0,
        },
        cour: {
            fcl: 0,
        },
        donatShipment: 0,
    },
    donatChart: [
        {
            name: 'SEA',
            value: 0,
            fill: '#EDDAFC',
            textColor: '#20384E',
        },
        {
            name: 'AIR',
            value: 0,
            fill: '#D8E7FF',
            textColor: '#20384E',
        },
        {
            name: 'ROAD',
            value: 0,
            fill: '#CDF4F7',
            textColor: '#20384E',
        },
        {
            name: 'RAIL',
            value: 0,
            fill: '#FFF6E1',
            textColor: '#20384E',
        },
        {
            name: 'COURIER',
            value: 0,
            fill: '#FCE4DA',
            textColor: '#20384E',
        },
    ],
    volumeMeasure: [],
    weightMeasure: [],
}

const volumeSlice = createSlice({
    name: 'VolumeIFB',
    initialState,
    reducers: {
        setBarChartSea: (state, action: PayloadAction<IBarDataRespon>) => {
            state.itemTotalBarChart.sea.fcl = action.payload.totalFclVolume
            state.itemTotalBarChart.sea.lcl = action.payload.totalLclVolume
            const fclPerMonth = action.payload.fclPerMonth
            const lclPerMonth = action.payload.lclPerMonth
            const sea = {
                key: [
                    {
                        id: 'f1',
                        color: '#A647F0',
                    },
                    {
                        id: 'f2',
                        color: '#EDDAFC',
                    },
                ],
                data: fclPerMonth.map((fclItem, index) => {
                    const lclItem = lclPerMonth[index]

                    return {
                        name: fclItem.month.toUpperCase(),
                        secName: fclItem.year.toString(),
                        f1: fclItem.count,
                        f2: lclItem ? lclItem.count : 0,
                        amt: 10, //
                    }
                }),
            }
            state.barChart.sea = sea
        },
        setBarChartRoad: (state, action: PayloadAction<IBarDataRespon>) => {
            state.itemTotalBarChart.road.fcl = action.payload.totalFclVolume
            state.itemTotalBarChart.road.lcl = action.payload.totalLclVolume
            state.itemTotalBarChart.road.ftl = action.payload.totalFtlVolume
            const fclPerMonth = action.payload.fclPerMonth
            const lclPerMonth = action.payload.lclPerMonth
            const ftlerMonth = action.payload.ftlPerMoth
            const road = {
                key: [
                    {
                        id: 'f1',
                        color: '#00A0AA',
                    },
                    {
                        id: 'f2',
                        color: '#06CAD7',
                    },
                    {
                        id: 'f3',
                        color: '#CDF4F7',
                    },
                ],
                data: fclPerMonth.map((fclItem, index) => {
                    const lclItem = lclPerMonth[index]
                    const ftlItem = ftlerMonth[index]
                    return {
                        name: fclItem.month.toUpperCase(),
                        secName: fclItem.year.toString(),
                        f1: fclItem.count,
                        f2: lclItem ? lclItem.count : 0,
                        f3: lclItem ? ftlItem.count : 0,
                        amt: 10, //
                    }
                }),
            }
            state.barChart.road = road
        },
        setBarChartAir: (state, action: PayloadAction<IBarDataRespon>) => {
            state.itemTotalBarChart.air.lse = action.payload.totalLseVolume
            const lsePerMonth = action.payload.lsePerMonth
            const air = {
                key: [
                    {
                        id: 'f1',
                        color: '#266EDC',
                    },
                ],
                data: lsePerMonth.map((fclItem, index) => {
                    return {
                        name: fclItem.month.toUpperCase(),
                        secName: fclItem.year.toString(),
                        f1: fclItem.count,
                        amt: 10, //
                    }
                }),
            }
            state.barChart.air = air
        },
        setBarChartRail: (state, action: PayloadAction<IBarDataRespon>) => {
            state.itemTotalBarChart.rail.fcl = action.payload.totalFclVolume
            state.itemTotalBarChart.rail.lcl = action.payload.totalLclVolume
            const fclPerMonth = action.payload.fclPerMonth
            const lclPerMonth = action.payload.lclPerMonth
            const rail = {
                key: [
                    {
                        id: 'f1',
                        color: '#FFD167',
                    },
                    {
                        id: 'f2',
                        color: '#FFF6E1',
                    },
                ],
                data: fclPerMonth.map((fclItem, index) => {
                    const lclItem = lclPerMonth[index]
                    return {
                        name: fclItem.month.toUpperCase(),
                        secName: fclItem.year.toString(),
                        f1: fclItem.count,
                        f2: lclItem ? lclItem.count : 0,
                        amt: 10, //
                    }
                }),
            }
            state.barChart.rail = rail
        },
        setBarChartCour: (state, action: PayloadAction<IBarDataRespon>) => {
            state.itemTotalBarChart.cour.fcl = action.payload.totalFclVolume
            const fclPerMonth = action.payload.fclPerMonth
            const cour = {
                key: [
                    {
                        id: 'f1',
                        color: '#F04770',
                    },
                ],
                data: fclPerMonth.map((fclItem, index) => {
                    return {
                        name: fclItem.month.toUpperCase(),
                        secName: fclItem.year.toString(),
                        f1: fclItem.count,
                        amt: 10, //
                    }
                }),
            }
            state.barChart.courier = cour
        },
        setDonatChartShipment: (
            state,
            action: PayloadAction<IDonatChartShipmentRespon>,
        ) => {
            state.itemTotalBarChart.donatShipment = action.payload.total

            state.donatChart = state.donatChart.map((item) => {
                switch (item.name) {
                    case 'SEA':
                        return { ...item, value: action.payload.sea }
                    case 'AIR':
                        return { ...item, value: action.payload.air }
                    case 'ROAD':
                        return { ...item, value: action.payload.road }
                    case 'RAIL':
                        return { ...item, value: action.payload.rail }
                    case 'COURIER':
                        return { ...item, value: action.payload.courier }
                    default:
                        return item
                }
            })
        },
        setVolumeDropdown(state, action: PayloadAction<metaDataDropdown>) {
            const convertToDropdownItems = (
                dropdown: IFieldDropdownMetaData[],
            ): IDropdownItem[] => {
                return dropdown.map((data) => ({
                    label: data.item + ' - ' + data.value,
                    value: data.item,
                }))
            }
            const itemVolume = action.payload.volumeMeasure
            const itemWeight = action.payload.weightMeasure
            return {
                ...state,
                volumeMeasure: convertToDropdownItems(itemVolume),
                weightMeasure: convertToDropdownItems(itemWeight),
            }
        },
    },
})

export const {
    setBarChartSea,
    setBarChartRoad,
    setBarChartAir,
    setBarChartRail,
    setBarChartCour,
    setDonatChartShipment,
    setVolumeDropdown,
} = volumeSlice.actions

export const barChartSelector = (state: RootState) => state.volumeIFB.barChart
export const donatChartSelector = (state: RootState) =>
    state.volumeIFB.donatChart
export const volumeDropdownSelector = (state: RootState) =>
    state.volumeIFB.volumeMeasure
export const weightDropdownSelector = (state: RootState) =>
    state.volumeIFB.weightMeasure
export const itemTotalChartSelector = (state: RootState) =>
    state.volumeIFB.itemTotalBarChart

export default volumeSlice.reducer
