import { IVolumeFilterValidation } from './volumes.interface'
import { useFormik } from 'formik'
import { useVolumeFilterValidation } from 'client/ifb/form-validation/volume.validation'
import {
    barChartSelector,
    donatChartSelector,
    itemTotalChartSelector,
    setBarChartAir,
    setBarChartCour,
    setBarChartRail,
    setBarChartRoad,
    setBarChartSea,
    setDonatChartShipment,
    setVolumeDropdown,
    volumeDropdownSelector,
    weightDropdownSelector,
} from './volume.slice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import {
    fetchBarData,
    fetchDonatData,
} from 'client/ifb/repository/volume.repository'
import { fetchtDropdownDashboard } from 'client/ifb/repository/dashboard.repository'
import { metaDataDropdown } from '../bookings/bookings.interface'

const useVolumes = () => {
    const dispatch = useDispatch()
    const barData = useSelector(barChartSelector)
    const itemTotalChart = useSelector(itemTotalChartSelector)
    const donatDatashipment = useSelector(donatChartSelector)
    const volemeDropdown = useSelector(volumeDropdownSelector)
    const weightDropdown = useSelector(weightDropdownSelector)
    const [isLoading, setIsLoading] = useState({
        sea: true,
        air: true,
        road: true,
        rail: true,
        courier: true,
        donat: true,
    })
    const { volumeFilterInitialValue, volumeFilterValidation } =
        useVolumeFilterValidation()

    const formikVolume = useFormik<IVolumeFilterValidation>({
        validationSchema: volumeFilterValidation,
        initialValues: volumeFilterInitialValue,
        onSubmit: async () => {},
    })

    const fetchData = async () => {
        try {
            const seaPromise = fetchBarData(formikVolume.values, 'sea')
                .then((sea) => {
                    dispatch(setBarChartSea(sea.data))
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        sea: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching sea data:', error)
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        sea: false,
                    }))
                })

            const airPromise = fetchBarData(formikVolume.values, 'air')
                .then((air) => {
                    dispatch(setBarChartAir(air.data))
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        air: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching air data:', error)
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        air: false,
                    }))
                })

            const roadPromise = fetchBarData(formikVolume.values, 'road')
                .then((road) => {
                    dispatch(setBarChartRoad(road.data))
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        road: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching road data:', error)
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        road: false,
                    }))
                })

            const railPromise = fetchBarData(formikVolume.values, 'rail')
                .then((rail) => {
                    dispatch(setBarChartRail(rail.data))
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        rail: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching rail data:', error)
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        rail: false,
                    }))
                })

            const courierPromise = fetchBarData(formikVolume.values, 'courier')
                .then((courier) => {
                    dispatch(setBarChartCour(courier.data))
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        courier: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching courier data:', error)
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        courier: false,
                    }))
                })

            const donatPromise = fetchDonatData(formikVolume.values)
                .then((donat) => {
                    console.log('donat', donat)

                    dispatch(setDonatChartShipment(donat.data))
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        donat: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching donat data:', error)
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        donat: false,
                    }))
                })

            await Promise.allSettled([
                seaPromise,
                airPromise,
                roadPromise,
                railPromise,
                courierPromise,
                donatPromise,
            ])
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    const fetchDataOnlyOnce = async () => {
        try {
            const [dataDropdown] = await Promise.all([
                fetchtDropdownDashboard(),
            ])
            dispatch(setVolumeDropdown(dataDropdown as metaDataDropdown))
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    useEffect(() => {
        const handler = setTimeout(() => {
            setIsLoading({
                sea: true,
                air: true,
                road: true,
                rail: true,
                courier: true,
                donat: true,
            })
            fetchData()
        }, 1000)
        return () => clearTimeout(handler)
    }, [formikVolume.values])

    useEffect(() => {
        fetchDataOnlyOnce()
    }, [])

    return {
        volemeDropdown,
        weightDropdown,
        itemTotalChart,
        isLoading,
        formikVolume,
        barData,
        donatDatashipment,
    }
}

export default useVolumes
