import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useModal } from '@components//modal/modal.service'
import { useDispatch, useSelector } from 'react-redux'
import { getManageUserID, getManageUserIDLog } from 'client/ifb/repository/manage-user.repository'
import { manageUserDataLogSelector, manageUserDetailSelector, setManageUserDataDetail, setManageUserDataLog } from '../manage-users.slice'

const useManageUserDetails = () => {
    // initial
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // state
    const manageUserDataLog = useSelector(manageUserDataLogSelector)
    const manageUserDetail = useSelector(manageUserDetailSelector)
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    // modal service
    const modalConfirmationService = useModal()

    function openEmailClient() {
        var emailAddress = 'test@gmail.com' // Replace with the desired email address
        var subject = 'Subject'
        var body = '--- Body ---'

        var mailtoUrl =
            'mailto:' +
            encodeURIComponent(emailAddress) +
            '?subject=' +
            encodeURIComponent(subject) +
            '&body=' +
            encodeURIComponent(body)

        window.open(mailtoUrl)
    }

    useEffect(() => {
        loadData()
    }, [])

    // Get Data Function
    const loadData = async () => {
        try {
            setLoading(true)
            const actionResultDetail = await getManageUserID({id})
            const actionResult = await getManageUserIDLog({id})
            dispatch(setManageUserDataDetail(actionResultDetail))
            dispatch(setManageUserDataLog(actionResult))
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    return {
        id,
        loading,
        errorMessage,
        manageUserDataLog,
        manageUserDetail,
        modalConfirmationService,
        navigate,
        openEmailClient,
    }
}

export default useManageUserDetails
