import CardsDashboard from '@components/card-dashboard/card-dashboard.component'
import InfoCardGroup from '@components/InfoCardGroup/Info-card-group.component'
import { BarChartStacked, DoughnutChart } from '@components/rechart-component'
import ItemListInfo from '@components/item-list-info/item-list-info.component'
import {
    itemListMonthlyCost,
    itemListShipmentsCosts,
} from '../performances.static'
import { IUsePerformances } from '../performances.interface'
import Pagination from '@components/pagination/pagination.component'
import { metaDummy } from '../../bookings/bookings.dummy'
import ColorPieChart from '../components/color-pie-chart.component'

function Costs({
    performancesService,
}: {
    performancesService: IUsePerformances
}) {
    return (
        <>
            <CardsDashboard className="!p-0 flex flex-col row-span-2 col-span-12 xl:col-span-4 h-[550px] xl:h-full">
                <div className="border-b p-2 h-1/2">
                    <div className="font-bold text-size-S">
                        Total Cost by Modes
                    </div>
                    <InfoCardGroup
                        items={
                            performancesService.totalCostByModes
                                .infoCardGroupItems
                        }
                        titleClass="!text-size-XS"
                        descClass="!text-[9px]"
                    />
                    <DoughnutChart
                        isLoading={
                            performancesService.loadingCost.totalCostByModes
                        }
                        data={performancesService.totalCostByModes.chartData}
                        colorChart={
                            <ColorPieChart
                                items={
                                    performancesService.totalCostByModes
                                        .chartData
                                }
                                content={performancesService.tabFilter}
                            />
                        }
                    />
                </div>
                <div className="p-2 h-1/2">
                    <div className="font-bold flex text-size-XS justify-between items-center">
                        Port Charges
                    </div>
                    <InfoCardGroup
                        items={[
                            {
                                value: 'PLN 12,519.02',
                                label: 'Total Cost Overall',
                            },
                        ]}
                        titleClass="!text-size-XS"
                        descClass="!text-[9px]"
                    />
                    <DoughnutChart
                        data={performancesService.donatChart.donatCostPort}
                        colorChart={
                            <ColorPieChart
                                items={
                                    performancesService.donatChart.donatCostPort
                                }
                                content={performancesService.tabFilter}
                            />
                        }
                    />
                </div>
            </CardsDashboard>
            <CardsDashboard className="!p-0 flex gap-3  w-full col-span-12 row-span-2 xl:col-span-8 xl:row-span-1 xl:h-full  lg:flex-row lg:h-[492px] md:flex-col md:h-[500px] sm:flex-col sm:h-[500px]">
                <div className="flex w-full flex-col h-full">
                    <div className="flex justify-between items-center border-b flex-wrap p-2">
                        <div className="font-bold text-size-S flex-grow">
                            Monthly Total Cost
                        </div>

                        <div
                            className="flex justify-end gap-1 flex-grow max-w-[764px]
                            flex-wrap 
                        "
                        >
                            <ItemListInfo
                                itemListInfo={itemListMonthlyCost}
                                containerClassNames="themes-bg-white
                                min-w-[100px]
                                mobile:flex-wrap
                                "
                                itemClassNames="flex-grow mobile:!w-[182px]"
                            />
                        </div>
                    </div>
                    <div className="flex-grow p-2">
                        <BarChartStacked
                            data={performancesService.monthlyTotalCost}
                            isLoading={
                                performancesService.loadingCost.monthlyTotalCost
                            }
                        />
                    </div>
                </div>
            </CardsDashboard>
            <CardsDashboard className="!p-0 flex w-full col-span-12 row-span-2 lg:h-[492px] xl:col-span-8 xl:row-span-1 xl:h-full lg:flex-row md:flex-col md:h-[500px] sm:flex-col sm:h-[722px]">
                <div className="flex w-full flex-col h-full">
                    <div className="flex justify-between items-center border-b flex-wrap p-2">
                        <div className="font-bold text-size-S flex-grow">
                            Monthly Delayed Shipments
                        </div>

                        <div
                            className="flex justify-end gap-1 flex-grow max-w-[645px]
                            mobile:flex-wrap 
                        "
                        >
                            <ItemListInfo
                                itemListInfo={itemListShipmentsCosts}
                                containerClassNames="themes-bg-white
                                min-w-[100px]
                                mobile:flex-grow mobile:flex-wrap
                                "
                            />
                            <Pagination
                                containerClassName="!p-0 flex
                                "
                                className="!h-[27px]"
                                btnClassName="!h-[27px] flex items-center justify-center cursor-pointer"
                                meta={metaDummy}
                                previousHandling={(page) => {}}
                                nextHandling={(page) => {}}
                            />
                        </div>
                    </div>
                    <div className="flex-grow p-2">
                        <BarChartStacked
                            data={performancesService.barChart.barCostDeleyed}
                        />
                    </div>
                </div>
            </CardsDashboard>
        </>
    )
}

export default Costs
