import { useModal } from '@components//modal/modal.service'
import useOverlay from '@components/overlay/overlay.service'
import { ITabItem } from '@components/tab/tab.interface'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import {
    filterSelector,
    tabStatusFilterSelector,
    trackingsMetaSelector,
    trackingsDataSelector,
    trackingsStatusTotalSelector,
    setTrackingData,
    setSelectedStatus,
    setPageNumber,
    setFilter,
} from './trackings.slice'
import { ITrackingFilter } from 'client/ifb/repository/interface/tracking.interface'
import { getTrackingsData } from 'client/ifb/repository/tracking.repository'

const useTrackings = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    // selector
    const trackingsData = useSelector(trackingsDataSelector)
    const totalStatus = useSelector(trackingsStatusTotalSelector)
    const tabFilter = useSelector(tabStatusFilterSelector)
    const filter = useSelector(filterSelector)
    const trackingsDataMeta = useSelector(trackingsMetaSelector)

    // states
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [tabItems, setTabItems] = useState<ITabItem[]>()
    const [widthSize, setWidthSize] = useState(window.innerWidth)


    // variable
    const { pageNumber, pageSize, status } = filter

    // overlay
    const filterOverlayService = useOverlay()

    // modal
    const generateReportModalService = useModal()

    // Get Data Function
    const loadData = async () => {
        let tmpFilter: ITrackingFilter = filter
        if (filter.status === 'all') tmpFilter.status = ''

        try {
            setLoading(true)
            const actionResult = await getTrackingsData(tmpFilter)
            dispatch(setTrackingData(actionResult))
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    // generate tab Items
    const generateTabItems = () => {
        const tabItems: ITabItem[] = [
            {
                label: 'All Mode',
                labelTranslationCode: 'status.all_trackings',
                totalData: totalStatus.all,
                key: 'status',
                value: 'all',
                className: 'brand',
            },
            {
                label: 'SEA',
                labelTranslationCode: 'status.sea',
                totalData: totalStatus.sea,
                key: 'typeTransport',
                value: 'SEA',
                className: 'green',
            },
            {
                label: 'AIR',
                labelTranslationCode: 'status.air',
                totalData: totalStatus.air,
                key: 'status',
                value: 'AIR',
                className: 'yellow',
            },
            {
                label: 'ROAD',
                labelTranslationCode: 'status.road',
                totalData: totalStatus.land,
                key: 'status',
                value: 'LAND',
                className: 'red',
            },
        ]

        setTabItems(tabItems)
    }

    const setTabFilter = async (data: ITabItem) => {
        dispatch(setSelectedStatus(data))
        dispatch(setPageNumber(1))
    }

    const setPageData = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber))
    }

    const handleSearch = (values: string) => {
        if (values.length >= 3) {
            dispatch(setFilter({ ...filter, search: values }))
        } else if (values.length <= 0)
            dispatch(setFilter({ ...filter, search: '' }))
    }

    const updateDimensions = () => {
        setWidthSize(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    })

    useEffect(() => {
        generateTabItems()
    }, [totalStatus])

    useEffect(() => {
        loadData()
    }, [pageNumber, pageSize, status, tabFilter, filter])


    return {
        filterOverlayService,
        generateReportModalService,
        tabItems,
        tabFilter,
        trackingsData,
        loading,
        errorMessage,
        widthSize,
        trackingsDataMeta,
        handleSearch,
        setLoading,
        setErrorMessage,
        navigate,
        setTabItems,
        setTabFilter,
        setPageData,
    }
}

export default useTrackings
