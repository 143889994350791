import SimpleTable from '@components/simple-table/simple-table.component'
import { containerHeaders } from '../booking-details.static'
import { metaDummy } from '../booking-details.dummy'
import { IBookingDetailsContainer } from '../../bookings.interface'

const Container = ({ data }: { data: IBookingDetailsContainer[] }) => {
    return (
        <SimpleTable<IBookingDetailsContainer>
            headers={containerHeaders}
            data={data}
            usePagination
            nextHandling={(page) => {
                console.log('hit Next API -> ' + page)
            }}
            previousHandling={(page) => {
                console.log('hit Prev API -> ' + page)
            }}
            meta={metaDummy}
        />
    )
}

export default Container
