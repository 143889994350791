import CardsDashboard from '@components/card-dashboard/card-dashboard.component'
import InfoCardGroup from '@components/InfoCardGroup/Info-card-group.component'
import {
    BarChartCustom,
    BarChartDouble,
    BarChartStacked,
} from '@components/rechart-component'
import ItemListInfo from '@components/item-list-info/item-list-info.component'
import Pagination from '@components/pagination/pagination.component'
import {
    averageTransitTimeByModeBarColors,
    itemListShipmentsTransit,
    tabServiceTypeItems,
} from '../performances.static'
import { metaDummy } from '../../bookings/bookings.dummy'
import { IUsePerformances } from '../performances.interface'
import Tab from '@components/tab/tab.component'
import TransitShipmentDelivery from '../components/transit-shipment-delivery.component'

function Transit({
    performancesService,
}: {
    performancesService: IUsePerformances
}) {
    return (
        <>
            <CardsDashboard className="!p-0 flex flex-col row-span-2 col-span-12 xl:col-span-4 h-[750px] xl:h-full">
                <div className="p-3 h-1/2 border-b flex flex-col">
                    <div className="font-bold text-size-S">
                        Average Transit Time
                    </div>
                    <InfoCardGroup
                        items={
                            performancesService.averageTransitTime
                                .infoCardGroupItems
                        }
                        titleClass="!text-size-XS"
                        descClass="!text-[9px]"
                    />
                    <BarChartStacked
                        data={performancesService.averageTransitTime.chartData}
                        isLoading={
                            performancesService.loadingTransitTime
                                .averageTransitTime
                        }
                    />
                </div>
                <div className="flex flex-col h-1/2 p-3">
                    <div className="font-bold text-size-S">
                        Average Transit Time by Mode (Days)
                    </div>
                    <div className="flex-grow">
                        <BarChartCustom
                            data={performancesService.averageTransitTimeByMode}
                            tooltipSingle="Total Shipments"
                            barColors={averageTransitTimeByModeBarColors}
                        />
                    </div>
                </div>
            </CardsDashboard>
            <CardsDashboard className="!p-0 flex gap-3 w-full col-span-12 row-span-2 xl:col-span-8 xl:row-span-1 xl:h-full  lg:flex-row lg:h-[492px] md:flex-col md:h-[500px] sm:flex-col sm:h-[500px]">
                <div className="flex w-full flex-col h-full">
                    <div className="flex justify-between items-center border-b flex-wrap p-2">
                        <div className="font-bold text-size-S flex-grow">
                            Shipments Transit Time by Ports
                        </div>

                        <div
                            className="flex justify-end gap-1 flex-grow max-w-[645px]
                            mobile:flex-wrap 
                        "
                        >
                            <ItemListInfo
                                itemListInfo={itemListShipmentsTransit}
                                containerClassNames="themes-bg-white
                                min-w-[100px]
                                mobile:flex-grow mobile:flex-wrap
                                "
                            />
                            <Pagination
                                containerClassName="!p-0 flex
                                "
                                className="!h-[27px]"
                                btnClassName="!h-[27px] flex items-center justify-center cursor-pointer"
                                meta={
                                    performancesService
                                        .shipmentsTransitTimeByPorts.meta
                                }
                                previousHandling={(page) =>
                                    performancesService.setPageNumberShipmentsTransitTimeByPorts(
                                        page,
                                    )
                                }
                                nextHandling={(page) =>
                                    performancesService.setPageNumberShipmentsTransitTimeByPorts(
                                        page,
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className="flex-grow p-2">
                        <BarChartDouble
                            data={
                                performancesService.shipmentsTransitTimeByPorts
                                    .chartData
                            }
                            isLoading={
                                performancesService.loadingTransitTime
                                    .shipmentsTransitTimeByPorts
                            }
                        />
                    </div>
                </div>
            </CardsDashboard>
            <CardsDashboard className="!p-0 flex w-full col-span-12 row-span-2 lg:h-[492px] xl:col-span-8 xl:row-span-1 xl:h-full lg:flex-row md:flex-col md:h-[500px] sm:flex-col sm:h-[722px]">
                <div className="flex flex-col w-full h-full">
                    <div className="flex gap-2 justify-between items-center border-b flex-wrap ">
                        <Tab
                            containerClassName="!overflow-y-hidden !text-size-XS flex flex-grow items-center pt-3 pl-3"
                            items={tabServiceTypeItems}
                            tabFilter={performancesService.tabFilterTransitTime}
                            onChange={(item) => {
                                performancesService.setTabFilterTransitTime(
                                    item,
                                )
                            }}
                        />

                        <div
                            className="flex justify-end gap-1 flex-grow max-w-[820px]
                            mobile:flex-wrap 
                        "
                        >
                            <ItemListInfo
                                itemListInfo={[
                                    {
                                        label: 'Actual Transit Time',
                                        color: 'gray-v5',
                                    },
                                ]}
                                containerClassNames="themes-bg-white
                                min-w-[100px]
                                mobile:flex-grow mobile:flex-wrap
                                "
                            />
                            <Pagination
                                containerClassName="!p-0 flex
                                "
                                className="!h-[27px]"
                                btnClassName="!h-[27px] flex items-center justify-center cursor-pointer"
                                meta={metaDummy}
                                previousHandling={(page) => {}}
                                nextHandling={(page) => {}}
                            />
                        </div>
                    </div>
                    <TransitShipmentDelivery />
                </div>
            </CardsDashboard>
        </>
    )
}

export default Transit
