var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './estimation-info.style.css';
var EstimationInfo = function (_a) {
    var initialCountryName = _a.initialCountryName, initialCountryCode = _a.initialCountryCode, destinationCountryName = _a.destinationCountryName, destinationCountryCode = _a.destinationCountryCode, ETD = _a.ETD, ETA = _a.ETA, iconMap = _a.iconMap, status = _a.status;
    var originImgCode = initialCountryCode.slice(0, 2).toLowerCase();
    var destinationImgCode = destinationCountryCode.slice(0, 2).toLowerCase();
    var originImg = "https://flagcdn.com/".concat(originImgCode, ".svg");
    var destinationImg = "https://flagcdn.com/".concat(destinationImgCode.toLowerCase(), ".svg");
    return (_jsxs("div", __assign({ className: 'mt-2 flex ' }, { children: [_jsxs("div", __assign({ className: 'flex items-center', style: { lineHeight: '14px' } }, { children: [iconMap && _jsx("i", { className: "ri-map-pin-line mr-1" }), _jsxs("div", __assign({ className: 'estimation-text items-end justify-center flex flex-col mr-1' }, { children: [_jsx("div", __assign({ className: 'themes-text-gray-v5' }, { children: "Load" })), _jsxs("div", __assign({ className: 'font-bold' }, { children: [initialCountryName, _jsxs("span", __assign({ className: 'sm:hidden' }, { children: [" ( ", initialCountryCode, " )"] }))] })), _jsx("div", { children: ETD })] })), _jsx("img", { src: originImg, className: 'estimation-flag sm:hidden', alt: initialCountryName })] })), _jsx("div", __assign({ className: 'flex-grow mx-2 flex items-center' }, { children: _jsx("div", __assign({ className: "w-full flex items-center rounded-md  estimation-card text-center justify-center items-center themes-bg-".concat(status.color, "-v1 themes-text-").concat(status.color, "-v4") }, { children: status.text })) })), _jsxs("div", __assign({ className: 'flex items-center', style: { lineHeight: '14px' } }, { children: [iconMap && _jsx("i", { className: "ri-map-pin-line themes-text-brand-v5 mr-1" }), _jsx("img", { src: destinationImg, className: 'estimation-flag sm:hidden', alt: destinationCountryName }), _jsxs("div", __assign({ className: 'estimation-text items-center ml-1' }, { children: [_jsx("div", __assign({ className: 'themes-text-gray-v5' }, { children: "Discharge" })), _jsxs("div", __assign({ className: 'font-bold' }, { children: [destinationCountryName, _jsxs("span", __assign({ className: 'sm:hidden' }, { children: ["( ", destinationCountryCode, " )"] }))] })), _jsx("div", { children: ETA })] }))] }))] })));
};
export default EstimationInfo;
