import { IMeta, IResponseData } from 'common/common.interface'
import {
    ITracking,
    ITrackingFilter,
    ITrackingStatusTotal,
} from 'client/ifb/repository/interface/tracking.interface'
import { ITabItem } from '@components/tab/tab.interface'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { formatDateTime } from 'common/common.service'
import { ITrackingSumary } from './trackings.interface'

interface ITrackingsSlice {
    data: ITrackingSumary[]
    meta: IMeta
    responseMessage: string
    filter: ITrackingFilter
    statusSelected: ITabItem
    statusTotal: ITrackingStatusTotal

    // segment details
    // detailsData: IShipmentDetails
}

export const initialState: ITrackingsSlice = {
    data: [],
    meta: {
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total_page: 0,
        total_Items: 0,
        from: 0,
        to: 0,
        index_end: 0,
        index_start: 0,
    },
    responseMessage: '',
    filter: {
        status: '',
        pageNumber: 1,
        pageSize: 50,
    },
    statusTotal: {
        all: 0,
        sea: 0,
        air: 0,
        land: 0,
    },
    statusSelected: {
        value: 'all',
        key: 'status',
        label: 'All Mode',
        totalData: 0,
    },
}

const trackingSlice = createSlice({
    name: 'trackingIFB',
    initialState,
    reducers: {
        setTrackingData(
            state,
            action: PayloadAction<IResponseData<ITracking[]>>,
        ) {
            const { data, isSuccess, message, meta, links, additionals } =
                action.payload

            const updatedStatusTotal: any = {
                all: 0,
                sea: 0,
                air: 0,
                land: 0,
            }
            additionals.stats.forEach((stat: any) => {
                const statusName = stat.status.toLowerCase() // Convert status to lowercase to match keys in updatedStatusTotal
                if (statusName in updatedStatusTotal) {
                    updatedStatusTotal[statusName] = stat.count
                    if (stat.status !== 'All') {
                        // Assuming 'All' means everything else
                        updatedStatusTotal.all += stat.count
                    }
                }
            })
            const setData: ITrackingSumary[] = data.map((d) => {
                const trackingData = {
                    idShipment: d?.containerID,
                    initialCountryCode: 'USNYC',
                    initialCountryName: 'New York',
                    destinationCountryCode: 'THBKK',
                    destinationCountryName: 'Bangkok',
                    ETD: '07 MAR 24',
                    ETA: '15 APR 24',
                    status:{color:'green', text:'IN TRANSIT'},
                    detail: {
                        containerType: d.containerType || '',
                        containerMode: d.containerMode,
                        deliveryMode: 'CFS/CFS',
                        relatedShipments: d.relatedShipments,
                        consoleNo: 'C00001481',
                        sealNo: '987654321',
                        weight: d.weight,
                        vessel: d.vessel,
                        voyage: 'S072',
                        emptyReturnedOn: '12-Aug-2024',
                    },
                    track: d.trackingHistoryMilestone.map((t) => ({
                        label: t.eventCode,
                        desc: t.description,
                        time:
                            t?.estimatedDate !== null
                                ? formatDateTime(t.estimatedDate)
                                : '',
                    })),
                }

                return trackingData
            })

            // set total status
            const d = {
                data: setData,
                isSuccess,
                message,
                meta,
                links,
                statusTotal: updatedStatusTotal,
            }

            return {
                ...state,
                ...d,
            }
        },
        setSelectedStatus(state, action: PayloadAction<ITabItem>) {
            const statusSelected = action.payload

            return {
                ...state,
                statusSelected,
            }
        },
        setFilter(state, action: PayloadAction<ITrackingFilter>) {
            const filter = action.payload
            return {
                ...state,
                filter,
            }
        },
        setPageNumber(state, action: PayloadAction<number>) {
            const pageNumber = action.payload
            const filter = { ...state.filter, pageNumber }
            return {
                ...state,
                filter,
            }
        },
    },
})

// these all the variables used for selector
export const trackingsDataSelector = (state: RootState) =>
    state.trackingIFB.data || {}
export const filterSelector = (state: RootState) =>
    state.trackingIFB.filter || {}
export const trackingsMetaSelector = (state: RootState) =>
    state.trackingIFB.meta || {}
export const trackingsFilterSelector = (state: RootState) =>
    state.trackingIFB.filter || {}
export const trackingsStatusTotalSelector = (state: RootState) =>
    state.trackingIFB.statusTotal || {}
export const tabStatusFilterSelector = (state: RootState) =>
    state.trackingIFB.statusSelected || {}

// all actions
export const { setTrackingData, setSelectedStatus, setPageNumber, setFilter } =
    trackingSlice.actions

// Reducer
export default trackingSlice.reducer
