import CardsDashboard from '@components/card-dashboard/card-dashboard.component'
import useVolumes from './volumes.service'
import { BarChartStacked, DoughnutChart } from '@components/rechart-component'
import { FormikProvider } from 'formik'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import { useState } from 'react'
import InfoCardGroup from '@components/InfoCardGroup/Info-card-group.component'
import { formatNumber } from 'common/common.service'
import FormMonthYearPicker from '@components/form-month-year-picker/form-month-year-picker.component'
import Button from '@components/button/button.component'

function Volumes() {
    const {
        volemeDropdown,
        weightDropdown,
        formikVolume,
        barData,
        itemTotalChart,
        donatDatashipment,
        isLoading,
    } = useVolumes()
    const [itemActive, setItemActive] = useState<boolean>(true)
    const typeData = itemActive ? 'Volume' : 'Weight'
    const typeDropdown = itemActive ? volemeDropdown : weightDropdown

    return (
        <div className="flex flex-col gap-3 overflow-auto xl:overflow-hidden">
            <CardsDashboard className="grid grid-cols-12 gap-3">
                <div className="col-span-12 flex flex-col gap-3 justify-center xl:border-r xl:pr-3 xl:col-span-3">
                    <Button
                        onClick={() => {
                            setItemActive(true)
                            formikVolume.setFieldValue(
                                'MeasureCategory',
                                'volume',
                            )
                        }}
                        className={`${itemActive ? 'themes-bg-brand-v4 themes-text-white ' : ' themes-bg-brand-v2 themes-text-brand-v4'} transition-all duration-300 cursor-pointer px-5 py-1 rounded-md flex items-center text-[12px]`}
                        icon="ri-box-3-line text-size-XL"
                        label="VOLUME MEASURE"
                        labelClass="sm:!block sm:!pl-2"
                    />
                    <Button
                        onClick={() => {
                            setItemActive(false)
                            formikVolume.setFieldValue(
                                'MeasureCategory',
                                'weight',
                            )
                        }}
                        className={`${!itemActive ? 'themes-bg-brand-v4 themes-text-white ' : ' themes-bg-brand-v2 themes-text-brand-v4'} transition-all duration-300 cursor-pointer px-5 py-1 rounded-md flex items-center text-[12px]`}
                        icon="ri-box-3-line text-size-XL"
                        label="WEIGHT MEASURE"
                        labelClass="sm:!block sm:!pl-2"
                    />
                </div>
                <FormikProvider value={formikVolume}>
                    <div className="col-span-12 grid grid-cols-12 gap-3 xl:col-span-9">
                        <div className="grid grid-cols-2 gap-3 md:border-r md:pr-3 xl:col-span-5 md:col-span-7 sm:col-span-12">
                            <FormDropdown
                                isSearchable
                                label={'SHIPMENT DATE BY'}
                                name={`shipmentDateBy`}
                                placeholder="Select Here"
                                parentDivClassName="!mb-0 sm:col-span-2 lg:col-span-2 md:col-span-2 h-[40px]"
                                className="h-[40px]"
                                options={[
                                    { value: 'eta', label: 'ETA' },
                                    { value: 'etd', label: 'ETD' },
                                    {
                                        value: 'newshipment',
                                        label: 'New Shipment',
                                    },
                                ]}
                            />
                            <FormMonthYearPicker
                                name="StartDate"
                                label="START MONTH"
                                placeholder="MM/YYYY"
                            />
                            <FormMonthYearPicker
                                name="EndDate"
                                label="END MONTH"
                                placeholder="MM/YYYY"
                            />
                        </div>
                        <FormDropdown
                            isSearchable
                            label={'UNIT MEASUREMENT'}
                            name={`UnitMeasure`}
                            placeholder="Select Here"
                            parentDivClassName="xl:col-span-5 md:col-span-5 sm:col-span-12"
                            options={typeDropdown}
                        />
                    </div>
                </FormikProvider>
            </CardsDashboard>
            <div className="grid grid-cols-12 grid-rows-2 gap-3 xl:h-[calc(100vh-15.2rem)]">
                <CardsDashboard className="!p-0 flex flex-col row-span-2 col-span-12 xl:col-span-4 h-[750px] xl:h-full">
                    <div className="p-2 h-1/2 flex flex-col">
                        <div className="text-size-XS font-bold">
                            Total Shipments Invoice Unpaid
                        </div>
                        <InfoCardGroup
                            items={[
                                {
                                    value: `${formatNumber(itemTotalChart.donatShipment)} ${formikVolume.values.UnitMeasure}`,
                                    label: `Total Shipments ${typeData}`,
                                },
                            ]}
                            titleClass="!text-size-XS"
                            descClass="!text-[9px]"
                        />
                        <div className="flex-grow">
                            <DoughnutChart
                                data={donatDatashipment}
                                isLoading={isLoading.donat}
                            />
                        </div>
                    </div>
                    <div className="border-b"></div>
                    <div className="px-2 pb-2 h-1/2 flex flex-col">
                        <div className="font-bold text-size-XS">ROAD</div>
                        <InfoCardGroup
                            items={[
                                {
                                    value: `${formatNumber(itemTotalChart.road.fcl)} ${formikVolume.values.UnitMeasure}`,
                                    label: `Total ${typeData} (FCL)`,
                                },
                                {
                                    value: `${formatNumber(itemTotalChart.road.lcl)} ${formikVolume.values.UnitMeasure}`,
                                    label: `Total ${typeData} (LCL)`,
                                },
                                {
                                    value: `${formatNumber(itemTotalChart.road.ftl)} ${formikVolume.values.UnitMeasure}`,
                                    label: `Total ${typeData} (FTL)`,
                                },
                            ]}
                            titleClass="!text-size-XS"
                            descClass="!text-[9px]"
                        />
                        <div className="flex-grow">
                            <BarChartStacked
                                data={barData.road}
                                isLoading={isLoading.road}
                            />
                        </div>
                    </div>
                </CardsDashboard>
                <CardsDashboard className="flex  w-full col-span-12 row-span-2 xl:col-span-8 xl:row-span-1 xl:h-full  lg:flex-row md:flex-col lg:h-[492px] md:h-[950px] sm:flex-col">
                    <div className="flex flex-col lg:w-1/2 md:w-full mobile:w-full">
                        <div className="text-size-XS font-bold">SEA</div>
                        <InfoCardGroup
                            items={[
                                {
                                    value: `${formatNumber(itemTotalChart.sea.fcl)} ${formikVolume.values.UnitMeasure}`,
                                    label: `Total ${typeData} (FCL)`,
                                },
                                {
                                    value: `${formatNumber(itemTotalChart.sea.lcl)} ${formikVolume.values.UnitMeasure}`,
                                    label: `Total ${typeData} (LCL)`,
                                },
                            ]}
                            titleClass="!text-size-XS"
                            descClass="!text-[9px]"
                        />
                        <div className="flex-grow">
                            <div className="lg:h-full sm:h-[35vh] md:h-[40vh]">
                                <BarChartStacked
                                    data={barData.sea}
                                    isLoading={isLoading.sea}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col lg:w-1/2 md:w-full mobile:w-full">
                        <div className="font-bold text-size-XS">AIR</div>
                        <InfoCardGroup
                            items={[
                                {
                                    value: `${formatNumber(itemTotalChart.air.lse)} ${formikVolume.values.UnitMeasure}`,
                                    label: `Total ${typeData} (LSE)`,
                                },
                            ]}
                            titleClass="!text-size-XS"
                            descClass="!text-[9px]"
                        />
                        <div className="flex-grow">
                            <div className="lg:h-full sm:h-[35vh] md:h-[40vh]">
                                <BarChartStacked
                                    data={barData.air}
                                    isLoading={isLoading.air}
                                />
                            </div>
                        </div>
                    </div>
                </CardsDashboard>
                <CardsDashboard className="flex w-full col-span-12 row-span-2 lg:h-[492px] xl:col-span-8 xl:row-span-1 xl:h-full lg:flex-row md:flex-col md:h-[950px] sm:flex-col sm:h-[722px]">
                    <div className="flex flex-col lg:w-1/2 md:w-full mobile:w-full">
                        <div className="font-bold text-size-XS">RAIL</div>
                        <InfoCardGroup
                            items={[
                                {
                                    value: `${formatNumber(itemTotalChart.rail.fcl)} ${formikVolume.values.UnitMeasure}`,
                                    label: `Total ${typeData} (FCL)`,
                                },
                                {
                                    value: `${formatNumber(itemTotalChart.rail.lcl)} ${formikVolume.values.UnitMeasure}`,
                                    label: `Total ${typeData} (LCL)`,
                                },
                            ]}
                            titleClass="!text-size-XS"
                            descClass="!text-[9px]"
                        />
                        <div className="flex-grow">
                            <div className="lg:h-full sm:h-[35vh] md:h-[40vh]">
                                <BarChartStacked
                                    data={barData.rail}
                                    isLoading={isLoading.rail}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col lg:w-1/2 md:w-full mobile:w-full">
                        <div className="font-bold text-size-XS">COURIER</div>
                        <InfoCardGroup
                            items={[
                                {
                                    value: `${formatNumber(itemTotalChart.cour.fcl)} ${formikVolume.values.UnitMeasure}`,
                                    label: `Total ${typeData} (Courier)`,
                                },
                            ]}
                            titleClass="!text-size-XS"
                            descClass="!text-[9px]"
                        />
                        <div className="flex-grow">
                            <div className="lg:h-full sm:h-[35vh] md:h-[40vh]">
                                <BarChartStacked
                                    data={barData.courier}
                                    isLoading={isLoading.courier}
                                />
                            </div>
                        </div>
                    </div>
                </CardsDashboard>
            </div>
        </div>
    )
}

export default Volumes
