import CardsDashboard from '@components/card-dashboard/card-dashboard.component'
import InfoCardGroup from '@components/InfoCardGroup/Info-card-group.component'
import {
    BarChartStacked,
    DoughnutChart,
    JointLineScatterChart,
} from '@components/rechart-component'
import ItemListInfo from '@components/item-list-info/item-list-info.component'
import Pagination from '@components/pagination/pagination.component'
import {
    itemListDelayedShipments,
    itemListShipmentsPerformance,
    itemListTableCardPerfom,
    tabPerformanceItems,
} from '../performances.static'
import { metaDummy } from '../../bookings/bookings.dummy'
import Tab from '@components/tab/tab.component'
import { IUsePerformances } from '../performances.interface'
import PerformanceShipmentDelivery from '../components/performance-shipment-delivery.component'
import ColorPieChart from '../components/color-pie-chart.component'

function Perfom({
    performancesService,
}: {
    performancesService: IUsePerformances
}) {
    return (
        <>
            <CardsDashboard className="!px-0 flex flex-col row-span-2 col-span-12 xl:col-span-4 h-[595px] xl:h-full gap-1">
                <div className="h-1/2 px-3 border-b">
                    <div className="font-bold text-size-S ">
                        Shipments Performance Status
                    </div>
                    <InfoCardGroup
                        items={
                            performancesService.shipmentPerformanceStatus
                                .infoCardGroupItems
                        }
                        titleClass="!text-size-XS"
                        descClass="!text-[9px]"
                    />
                    <DoughnutChart
                        data={
                            performancesService.shipmentPerformanceStatus
                                .chartData
                        }
                        colorChart={
                            <ColorPieChart
                                items={
                                    performancesService
                                        .shipmentPerformanceStatus.chartData
                                }
                                content={performancesService.tabFilter}
                            />
                        }
                        isLoading={performancesService.loading.shipmentStatus}
                    />
                </div>
                <div className="h-1/2 px-2 flex flex-col">
                    <div className="font-bold flex text-size-XS justify-between items-center py-1">
                        Monthly Delayed Shipments
                        <div className="font-normal border-l pl-2">
                            <ItemListInfo
                                itemListInfo={itemListDelayedShipments}
                            />
                        </div>
                    </div>
                    <InfoCardGroup
                        items={[
                            { value: '30,000', label: 'Total Shipments' },
                            {
                                value: '21,000',
                                label: 'Total Shipments Delayed',
                            },
                        ]}
                        titleClass="!text-size-XS"
                        descClass="!text-[9px]"
                    />
                    <div className="flex-grow">
                        <JointLineScatterChart
                            data={
                                performancesService.avarageDaysDelayed.chartData
                            }
                            tickItems={
                                performancesService.avarageDaysDelayed.tickItems
                            }
                            isLoading={
                                performancesService.loading.avarageDaysDelayed
                            }
                        />
                    </div>
                </div>
            </CardsDashboard>
            <CardsDashboard className="!p-0 flex gap-3  w-full col-span-12 row-span-2 xl:col-span-8 xl:row-span-1 xl:h-full  lg:flex-row lg:h-[492px] md:flex-col md:h-[500px] sm:flex-col sm:h-[500px]">
                <div className="flex w-full flex-col h-full">
                    <div className="flex justify-between items-center border-b flex-wrap p-2">
                        <div className="font-bold text-size-S flex-grow">
                            Shipments Performance by Ports
                        </div>

                        <div
                            className="flex justify-end gap-1 flex-grow max-w-[645px]
                            mobile:flex-wrap 
                        "
                        >
                            <ItemListInfo
                                itemListInfo={itemListShipmentsPerformance}
                                containerClassNames="themes-bg-white
                                min-w-[100px]
                                mobile:flex-grow mobile:flex-wrap
                                "
                            />
                            <Pagination
                                containerClassName="!p-0 flex
                                "
                                className="!h-[27px]"
                                btnClassName="!h-[27px] flex items-center justify-center cursor-pointer"
                                meta={
                                    performancesService
                                        .shipmentPerformanceByPorts.meta
                                }
                                previousHandling={(page) =>
                                    performancesService.setPageNumberPerformanceByPorts(
                                        page,
                                    )
                                }
                                nextHandling={(page) =>
                                    performancesService.setPageNumberPerformanceByPorts(
                                        page,
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className="flex-grow p-2">
                        <BarChartStacked
                            data={
                                performancesService.shipmentPerformanceByPorts
                                    .chartData
                            }
                            isLoading={
                                performancesService.loading
                                    .shipmentPerformanceByPorts
                            }
                        />
                    </div>
                </div>
            </CardsDashboard>
            <CardsDashboard className="!p-0 flex w-full col-span-12 row-span-2 lg:h-[492px] xl:col-span-8 xl:row-span-1 xl:h-full lg:flex-row md:flex-col md:h-[500px] sm:flex-col sm:h-[722px]">
                <div className="flex flex-col w-full">
                    <div className="flex justify-end gap-2 items-center  flex-wrap">
                        <Tab
                            containerClassName="!overflow-y-hidden !text-size-XS flex flex-grow items-center pt-3 pl-3 "
                            items={tabPerformanceItems}
                            tabFilter={performancesService.tabFilterPerformance}
                            onChange={(item) => {
                                performancesService.setTabFilterPerformance(
                                    item,
                                )
                            }}
                        />

                        <div
                            className="flex gap-1 flex-grow max-w-[500px] pl-2 
                            mobile:flex-wrap
                        "
                        >
                            <ItemListInfo
                                itemListInfo={itemListTableCardPerfom}
                                containerClassNames="themes-bg-white
                                min-w-[100px]
                                mobile:flex-grow mobile:flex-wrap
                                "
                            />
                            <Pagination
                                containerClassName="!p-0 flex
                                "
                                className="!h-[27px]"
                                btnClassName="!h-[27px] flex items-center justify-center cursor-pointer"
                                meta={metaDummy}
                                previousHandling={(page) => {}}
                                nextHandling={(page) => {}}
                            />
                        </div>
                    </div>
                    <div className="border-b"></div>
                    <PerformanceShipmentDelivery />
                </div>
            </CardsDashboard>
        </>
    )
}

export default Perfom
