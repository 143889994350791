import { IAddress } from 'client/ifb/repository/interface/shipment.interface'

const RelatedParties = ({
    consignorPickupData,
    consigneePickupData,
    consignorDocumentaryAddress,
    consigneeDocumentaryAddress
}: {
    consignorPickupData: IAddress
    consigneePickupData: IAddress
    consignorDocumentaryAddress:IAddress
    consigneeDocumentaryAddress:IAddress
}) => {
    const loadDataComponent = ({
        label,
        value,
    }: {
        label: string
        value?: string | number
    }) => (
        <div className="leading-none">
            <div className="themes-text-gray-v5">{label}</div>
            <div className="themes-text-gray-v9">{value || '-'}</div>
        </div>
    )

    return (
        <div className="p-3 h-full">
            <div className="grid grid-cols-4 gap-3  h-full">
                {/* Consignor Pickup Address */}
                <div className="flex flex-col gap-1">
                    <div className="text-size-L themes-text-gray-v9 mb-1">
                        Consignor Pickup Address
                    </div>
                    {loadDataComponent({
                        label: 'Company Name',
                        value: consignorPickupData.company,
                    })}
                    {loadDataComponent({
                        label: 'Address 1',
                        value: consignorPickupData.address1,
                    })}
                    {loadDataComponent({
                        label: 'Address 2',
                        value: consignorPickupData.address2,
                    })}
                    {loadDataComponent({
                        label: 'City',
                        value: consignorPickupData.city,
                    })}
                    {loadDataComponent({
                        label: 'State',
                        value: consignorPickupData.state.description ?? '',
                    })}
                    {loadDataComponent({
                        label: 'Postcode',
                        value: consignorPickupData.postCode,
                    })}
                    {loadDataComponent({
                        label: 'Country',
                        value: `${consignorPickupData.countryCode}, ${consignorPickupData.countryName}`,
                    })}
                    {loadDataComponent({
                        label: 'Contact Name',
                        value: consignorPickupData.contactName,
                    })}
                </div>

                {/* Consignor Documentary Address */}
                <div className="flex flex-col gap-3 border-l pl-3">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Consignor Documentary Address
                        </div>
                        {loadDataComponent({
                            label: 'Company Name',
                            value: consignorDocumentaryAddress.company,
                        })}
                        {loadDataComponent({
                            label: 'Address 1',
                            value: consignorDocumentaryAddress.address1,
                        })}
                        {loadDataComponent({
                            label: 'Address 2',
                            value: consignorDocumentaryAddress.address2,
                        })}
                        {loadDataComponent({
                            label: 'City',
                            value: consignorDocumentaryAddress.city,
                        })}
                        {loadDataComponent({
                            label: 'State',
                            value: consignorDocumentaryAddress.state.description ?? '',
                        })}
                        {loadDataComponent({
                            label: 'Postcode',
                            value: consignorDocumentaryAddress.postCode,
                        })}
                        {loadDataComponent({
                            label: 'Country',
                            value: `${consignorDocumentaryAddress.countryCode}, ${consignorDocumentaryAddress.countryName}`,
                        })}
                        {loadDataComponent({
                            label: 'Contact Name',
                            value: consignorDocumentaryAddress.contactName,
                        })}
                    </div>
                </div>

                {/* Consignee Delivery Address */}
                <div className="flex flex-col gap-3 border-l pl-3">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Consignee Delivery Address
                        </div>
                        {loadDataComponent({
                            label: 'Company Name',
                            value: consigneePickupData.company,
                        })}
                        {loadDataComponent({
                            label: 'Address 1',
                            value: consigneePickupData.address1,
                        })}
                        {loadDataComponent({
                            label: 'Address 2',
                            value: consigneePickupData.address2,
                        })}
                        {loadDataComponent({
                            label: 'City',
                            value: consigneePickupData.city,
                        })}
                        {loadDataComponent({
                            label: 'State',
                            value: consigneePickupData.state.description ?? '',
                        })}
                        {loadDataComponent({
                            label: 'Postcode',
                            value: consigneePickupData.postCode,
                        })}
                        {loadDataComponent({
                            label: 'Country',
                            value: `${consigneePickupData.countryCode}, ${consigneePickupData.countryName}`,
                        })}
                        {loadDataComponent({
                            label: 'Contact Name',
                            value: consigneePickupData.contactName,
                        })}
                    </div>
                </div>

                {/* Consignee Documentary Address */}
                <div className="flex flex-col gap-3 border-l pl-3">
                    <div className="flex flex-col gap-1">
                        <div className="text-size-L themes-text-gray-v9 mb-1">
                            Consignee Documentary Address
                        </div>
                        {loadDataComponent({
                            label: 'Company Name',
                            value: consigneeDocumentaryAddress.company,
                        })}
                        {loadDataComponent({
                            label: 'Address 1',
                            value: consigneeDocumentaryAddress.address1,
                        })}
                        {loadDataComponent({
                            label: 'Address 2',
                            value: consigneeDocumentaryAddress.address2,
                        })}
                        {loadDataComponent({
                            label: 'City',
                            value: consigneeDocumentaryAddress.city,
                        })}
                        {loadDataComponent({
                            label: 'State',
                            value: consigneeDocumentaryAddress.state.description ?? '',
                        })}
                        {loadDataComponent({
                            label: 'Postcode',
                            value: consigneeDocumentaryAddress.postCode,
                        })}
                        {loadDataComponent({
                            label: 'Country',
                            value: `${consigneeDocumentaryAddress.countryCode}, ${consigneeDocumentaryAddress.countryName}`,
                        })}
                        {loadDataComponent({
                            label: 'Contact Name',
                            value: consigneeDocumentaryAddress.contactName,
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RelatedParties
