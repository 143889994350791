import {
    IBarChartStackedData,
    IDataJointChart,
    IDonatRechart,
    ILineChartItem,
} from '@components/rechart-component/rechart-interface'
import { faker } from '@faker-js/faker'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    IFetchAverageTransitTime,
    IFetchAverageTransitTimeByMode,
    IFetchShipmentsTransitTimeByPorts,
    IFetchTotalCostByModes,
    IShipmentStatusCount,
    IShipmentStatusData,
    shipmentPerformanceByPorts,
} from 'client/ifb/repository/interface/performance.interface'
import { RootState } from 'store'
import {
    IAvarageDaysDelayed,
    IAverageTransitTime,
    ILoadAvarageDaysDelayed,
    IMonthlyTotalCostData,
    IShipmentPerformanceByPorts,
    IShipmentPerformanceStatus,
    IShipmentsTransitTimeByPorts,
} from './performances.interface'
import { numberWithCommas } from 'common/common.service'

interface IPerformancesState {
    // segment performance
    shipmentPerformanceStatus: IShipmentPerformanceStatus
    shipmentPerformanceByPorts: IShipmentPerformanceByPorts
    avarageDaysDelayed: IAvarageDaysDelayed

    // segment transit time
    averageTransitTime: IAverageTransitTime
    averageTransitTimeByMode: ILineChartItem[]

    // segment cost
    totalCostByModes: IShipmentPerformanceStatus
    monthlyTotalCost: IBarChartStackedData
    shipmentsTransitTimeByPorts: IShipmentsTransitTimeByPorts

    donatChart: {
        donatTransit: IDonatRechart<IShipmentStatusCount>[]
        donatCostModes: IDonatRechart<IShipmentStatusCount>[]
        donatCostPort: IDonatRechart<IShipmentStatusCount>[]
    }
    barChart: {
        barPefom: IBarChartStackedData
        barTransitTime: IBarChartStackedData
        barCostMonthly: IBarChartStackedData
        barCostDeleyed: IBarChartStackedData
        barTransitTimeByMode: {
            data: ILineChartItem[]
            color: string[]
        }
    }
    joinChartPefom: IDataJointChart
}

export const initialState: IPerformancesState = {
    donatChart: {
        donatTransit: [
            {
                name: 'SEA',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#A647F0',
                textColor: '#FFFFFF',
            },
            {
                name: 'AIR',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#4758F0',
                textColor: '#FFFFFF',
            },
            {
                name: 'ROAD',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#06CAD7',
                textColor: '#20384E',
            },
            {
                name: 'RAIL',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#FFD167',
                textColor: '#20384E',
            },
            {
                name: 'COURIER',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#F07A47',
                textColor: '#20384E',
            },
        ],
        donatCostModes: [
            {
                name: 'SEA',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#4758F0',
                textColor: '#FFFFFF',
            },
            {
                name: 'AIR',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#06CAD7',
                textColor: '#20384E',
            },
            {
                name: 'ROAD',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#A647F0',
                textColor: '#20384E',
            },
            {
                name: 'RAIL',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#FFD167',
                textColor: '#20384E',
            },
            {
                name: 'COURIER',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#F07A47',
                textColor: '#20384E',
            },
        ],
        donatCostPort: [
            {
                name: 'Loading Costs',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#D9DDE1',
                textColor: '#20384E',
            },
            {
                name: 'Main Freight Costs',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#8D99A5',
                textColor: '#20384E',
            },
            {
                name: 'Discharge Costs',
                value: faker.number.int({ min: 1000, max: 10000 }),
                fill: '#465A6C',
                textColor: '#20384E',
            },
        ],
    },
    joinChartPefom: {
        key: [
            {
                id: 'line1',
                color: '#f04770',
            },
            {
                id: 'line2',
                color: '#8D99A5',
            },
        ],
        line1: [
            { x: 1, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 2, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            // { x: 3, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            // { x: 4, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            // { x: 5, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            // { x: 6, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            // { x: 7, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            // { x: 8, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            // { x: 9, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            // {
            //     x: 10,
            //     secName: '2014',
            //     y: faker.number.int({ min: 0, max: 10 }),
            // },
            // {
            //     x: 11,
            //     secName: '2014',
            //     y: faker.number.int({ min: 0, max: 10 }),
            // },
            // {
            //     x: 12,
            //     secName: '2014',
            //     y: faker.number.int({ min: 0, max: 10 }),
            // },
        ],
        line2: [
            { x: 1, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 2, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 3, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 4, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 5, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 6, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 7, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 8, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            { x: 9, secName: '2014', y: faker.number.int({ min: 0, max: 10 }) },
            {
                x: 10,
                secName: '2014',
                y: faker.number.int({ min: 0, max: 10 }),
            },
            {
                x: 11,
                secName: '2014',
                y: faker.number.int({ min: 0, max: 10 }),
            },
            {
                x: 12,
                secName: '2014',
                y: faker.number.int({ min: 0, max: 10 }),
            },
        ],
    },
    barChart: {
        barPefom: {
            key: [
                {
                    id: 'f1',
                    color: '#F04770',
                },
                {
                    id: 'f2',
                    color: '#10D3A0',
                },
            ],
            data: [
                {
                    name: 'AUMEL',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'IDBTM',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'SGSIN',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'TWTPE',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'THBKK',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'JPKYT',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'JPOSA',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'JPFJS',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'USDMK',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'MYJHB',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'AUSYD',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'CNNBO',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
            ],
        },
        barTransitTime: {
            key: [
                {
                    id: 'f1',
                    color: '#8D99A5',
                },
            ],
            data: [
                {
                    name: 'Planned Transit Time',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    amt: 200,
                },
                {
                    name: 'Actual Transit Time',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    amt: 200,
                },
            ],
        },
        barCostMonthly: {
            key: [
                {
                    id: 'f1',
                    color: '#A647F0',
                },
                {
                    id: 'f2',
                    color: '#4758F0',
                },
                {
                    id: 'f3',
                    color: '#06CAD7',
                },
                {
                    id: 'f4',
                    color: '#FFD167',
                },
                {
                    id: 'f5',
                    color: '#F07A47',
                },
            ],
            data: [
                {
                    name: 'JAN',
                    secName: '2024',
                    f1: faker.number.int({ min: 40, max: 100 }),
                    amt: 10,
                },
                {
                    name: 'FEB',
                    secName: '2024',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    f4: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'MAR',
                    secName: '2024',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    f4: faker.number.int({ min: 10, max: 30 }),
                    f5: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'APR',
                    secName: '2024',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    f4: faker.number.int({ min: 10, max: 30 }),
                    f5: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'MAY',
                    secName: '2024',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    f4: faker.number.int({ min: 10, max: 30 }),
                    f5: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'JUN',
                    secName: '2024',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    f4: faker.number.int({ min: 10, max: 30 }),
                    f5: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'JUL',
                    secName: '2024',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    f4: faker.number.int({ min: 10, max: 30 }),
                    f5: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'AUG',
                    secName: '2024',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    f4: faker.number.int({ min: 10, max: 30 }),
                    f5: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'SEP',
                    secName: '2024',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    f4: faker.number.int({ min: 10, max: 30 }),
                    f5: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'OCT',
                    secName: '2024',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    f4: faker.number.int({ min: 10, max: 30 }),
                    f5: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'NOV',
                    secName: '2024',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    f4: faker.number.int({ min: 10, max: 30 }),
                    f5: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'DEC',
                    secName: '2024',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    f4: faker.number.int({ min: 10, max: 30 }),
                    f5: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
            ],
        },
        barCostDeleyed: {
            key: [
                {
                    id: 'f1',
                    color: '#D9DDE1',
                },
                {
                    id: 'f2',
                    color: '#8D99A5',
                },
                {
                    id: 'f3',
                    color: '#465A6C',
                },
            ],
            data: [
                {
                    name: 'AUMEL',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'IDBTM',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'SGSIN',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'TWTPE',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'THBKK',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'JPKYT',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'JPOSA',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'JPFJS',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'USDMK',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'MYJHB',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'AUSYD',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
                {
                    name: 'CNNBO',
                    f1: faker.number.int({ min: 10, max: 30 }),
                    f2: faker.number.int({ min: 10, max: 30 }),
                    f3: faker.number.int({ min: 10, max: 30 }),
                    amt: 10,
                },
            ],
        },
        barTransitTimeByMode: {
            color: ['#5280C6', '#76C6CC', '#9D75AD', '#E3CD9A', '#C98167'],
            data: [
                {
                    name: 'SEA',
                    number: faker.number.int({ min: 20001, max: 49000 }),
                },
                {
                    name: 'AIR',
                    number: faker.number.int({ min: 20001, max: 49000 }),
                },
                {
                    name: 'ROAD',
                    number: faker.number.int({ min: 20001, max: 49000 }),
                },
                {
                    name: 'RAIL',
                    number: faker.number.int({ min: 20001, max: 49000 }),
                },
                {
                    name: 'COURIER',
                    number: faker.number.int({ min: 20001, max: 49000 }),
                },
            ],
        },
    },

    // segment performance
    shipmentPerformanceStatus: {
        chartData: [
            {
                name: 'On Time',
                value: 0,
                fill: '#69BAA4',
                textColor: '#20384E',
            },
            {
                name: '1-2 days delay',
                value: 0,
                fill: '#FCDAE2',
                textColor: '#20384E',
            },
            {
                name: '3-7 days delay',
                value: 0,
                fill: '#F691A9',
                textColor: '#FFFFFF',
            },
            {
                name: '7-14 days delay',
                value: 0,
                fill: '#C9677F',
                textColor: '#FFFFFF',
            },
            {
                name: '14-31 days delay',
                value: 0,
                fill: '#A14158',
                textColor: '#FFFFFF',
            },
            {
                name: '> 1 month delay',
                value: 0,
                fill: '#670E23',
                textColor: '#FFFFFF',
            },
        ],
        infoCardGroupItems: [
            { value: '0', label: 'Total Shipments' },
            {
                value: '0',
                label: 'Total Shipments Delayed',
            },
        ],
    },
    shipmentPerformanceByPorts: {
        chartData: {
            key: [
                {
                    id: 'f1',
                    color: '#C9677F',
                },
                {
                    id: 'f2',
                    color: '#69BAA4',
                },
            ],
            data: [],
        },
        meta: {
            current_page: 1,
            last_page: 0,
            per_page: 12,
            total_page: 0,
            from: 0,
            to: 0,
            index_end: 0,
            index_start: 0,
        },
    },
    avarageDaysDelayed: {
        chartData: {
            key: [
                {
                    id: 'line1',
                    color: '#f04770',
                },
                {
                    id: 'line2',
                    color: '#8D99A5',
                },
            ],
            line1: [
                {
                    x: 1,
                    secName: '2024',
                    y: faker.number.int({ min: 0, max: 10 }),
                },
                {
                    x: 2,
                    secName: '2024',
                    y: faker.number.int({ min: 0, max: 10 }),
                },
                {
                    x: 3,
                    secName: '2024',
                    y: faker.number.int({ min: 0, max: 10 }),
                },
                {
                    x: 4,
                    secName: '2024',
                    y: faker.number.int({ min: 0, max: 10 }),
                },
                {
                    x: 5,
                    secName: '2024',
                    y: faker.number.int({ min: 0, max: 10 }),
                },
                {
                    x: 6,
                    secName: '2024',
                    y: faker.number.int({ min: 0, max: 10 }),
                },
            ],
            line2: [
                {
                    x: 1,
                    secName: '2024',
                    y: faker.number.int({ min: 0, max: 10 }),
                },
                {
                    x: 2,
                    secName: '2024',
                    y: faker.number.int({ min: 0, max: 10 }),
                },
                {
                    x: 3,
                    secName: '2024',
                    y: faker.number.int({ min: 0, max: 10 }),
                },
                {
                    x: 4,
                    secName: '2024',
                    y: faker.number.int({ min: 0, max: 10 }),
                },
                {
                    x: 5,
                    secName: '2024',
                    y: faker.number.int({ min: 0, max: 10 }),
                },
                {
                    x: 6,
                    secName: '2024',
                    y: faker.number.int({ min: 0, max: 10 }),
                },
            ],
        },
        tickItems: [],
    },

    // segment transit time
    averageTransitTime: {
        chartData: {
            key: [
                {
                    id: 'f1',
                    color: '#8D99A5',
                },
            ],
            data: [
                {
                    name: 'Planned Transit Time',
                    f1: 0,
                    amt: 200,
                },
                {
                    name: 'Actual Transit Time',
                    f1: 0,
                    amt: 200,
                },
            ],
        },
        infoCardGroupItems: [
            {
                value: '0 Days',
                label: 'Average Planned Transit Time',
            },
            {
                value: '0 Days',
                label: 'Average Actual Transit Time',
            },
        ],
    },
    shipmentsTransitTimeByPorts: {
        chartData: {
            barColor1: '#D9DDE1',
            barColor2: '#8D99A5',
            item: [],
        },
        meta: {
            current_page: 1,
            last_page: 0,
            per_page: 12,
            total_page: 0,
            from: 0,
            to: 0,
            index_end: 0,
            index_start: 0,
        },
    },
    averageTransitTimeByMode: [
        {
            name: 'SEA',
            number: 0,
        },
        {
            name: 'AIR',
            number: 0,
        },
        {
            name: 'ROAD',
            number: 0,
        },
        {
            name: 'RAIL',
            number: 0,
        },
        {
            name: 'COURIER',
            number: 0,
        },
    ],

    // segment cost
    totalCostByModes: {
        chartData: [
            {
                name: 'SEA',
                value: 0,
                fill: '#5280C6',
                textColor: '#FFFFFF',
            },
            {
                name: 'AIR',
                value: 0,
                fill: '#76C6CC',
                textColor: '#FFFFFF',
            },
            {
                name: 'ROAD',
                value: 0,
                fill: '#9D75AD',
                textColor: '#FFFFFF',
            },
            {
                name: 'RAIL',
                value: 0,
                fill: '#E3CD9A',
                textColor: '#FFFFFF',
            },
            {
                name: 'COURIER',
                value: 0,
                fill: '#C98167',
                textColor: '#FFFFFF',
            },
        ],
        infoCardGroupItems: [
            {
                value: '0',
                label: 'Total Shipments',
            },
            {
                value: '0',
                label: 'Total Cost Overall',
            },
        ],
    },
    monthlyTotalCost: {
        key: [
            {
                id: 'f1',
                color: '#5280C6',
            },
            {
                id: 'f2',
                color: '#76C6CC',
            },
            {
                id: 'f3',
                color: '#9D75AD',
            },
            {
                id: 'f4',
                color: '#E3CD9A',
            },
            {
                id: 'f5',
                color: '#C98167',
            },
        ],
        data: [],
    },
}

const performancesSlice = createSlice({
    name: 'performancesIFB',
    initialState,
    reducers: {
        setLoadShipmentStatus: (
            state,
            action: PayloadAction<IShipmentStatusData>,
        ) => {
            const payload = action.payload
            const { chartData, infoCardGroupItems } =
                state.shipmentPerformanceStatus

            // mapping data
            chartData[0].value = payload.onTime.value
            chartData[0].additionalData = payload.onTime
            chartData[1].value = payload.oneTwoDays.value
            chartData[1].additionalData = payload.oneTwoDays
            chartData[2].value = payload.threeSevenDays.value
            chartData[2].additionalData = payload.threeSevenDays
            chartData[3].value = payload.sevenFourteenDays.value
            chartData[3].additionalData = payload.sevenFourteenDays
            chartData[4].value = payload.fourteenThirtyOneDays.value
            chartData[4].additionalData = payload.fourteenThirtyOneDays
            chartData[5].value = payload.morethanAMonth.value
            chartData[5].additionalData = payload.morethanAMonth

            infoCardGroupItems[0].value = numberWithCommas(
                payload.totalShipment.toString(),
            )
            infoCardGroupItems[1].value = numberWithCommas(
                payload.totalDelayed.toString(),
            )
        },
        setShipmentPerformanceByPorts: (
            state,
            action: PayloadAction<shipmentPerformanceByPorts>,
        ) => {
            const { delayed, onTime } = action.payload
            const { meta, chartData } = state.shipmentPerformanceByPorts

            // meta
            const total_Items = onTime.length
            const index_start = (meta.current_page - 1) * meta.per_page + 1
            const index_end = Math.min(
                index_start + meta.per_page - 1,
                total_Items,
            )
            const last_page = Math.ceil(total_Items / meta.per_page)

            state.shipmentPerformanceByPorts.meta = {
                ...meta,
                last_page,
                index_start,
                index_end,
                total_Items,
            }

            // sort data
            const sortData = onTime
                .map((onTimeItem) => {
                    const delayedItem = delayed.find(
                        (item) => item.port === onTimeItem.port,
                    )

                    return {
                        name: onTimeItem.port,
                        f1: onTimeItem.count,
                        f2: delayedItem ? delayedItem.count : 0, // Jika tidak ada, beri nilai 0
                        amt: 10,
                    }
                })
                .slice(index_start - 1, index_end)

            state.shipmentPerformanceByPorts.chartData = {
                ...chartData,
                data: sortData,
            }
        },
        setPageShipmentPerformanceByPorts: (
            state,
            action: PayloadAction<number>,
        ) => {
            state.shipmentPerformanceByPorts.meta.current_page = action.payload
        },
        setAvarageDaysDelayed: (
            state,
            action: PayloadAction<ILoadAvarageDaysDelayed>,
        ) => {
            const { line1, line2, tickItems } = action.payload
            state.avarageDaysDelayed.chartData.line1 = line1
            state.avarageDaysDelayed.chartData.line2 = line2
            state.avarageDaysDelayed.tickItems = tickItems
        },

        // segment transit time
        setAverageTransitTime: (
            state,
            action: PayloadAction<IFetchAverageTransitTime>,
        ) => {
            const { totalPlaned, totalActual, avgPlanned, avgActual } =
                action.payload
            state.averageTransitTime.chartData.data[0].f1 = avgPlanned
            state.averageTransitTime.chartData.data[1].f1 = avgActual
            state.averageTransitTime.infoCardGroupItems[0].value = `${numberWithCommas(totalPlaned.toString())} Days`
            state.averageTransitTime.infoCardGroupItems[1].value = `${numberWithCommas(totalActual.toString())} Days`
        },
        setShipmentsTransitTimeByPorts: (
            state,
            action: PayloadAction<IFetchShipmentsTransitTimeByPorts[]>,
        ) => {
            const payloadData = action.payload
            const meta = state.shipmentsTransitTimeByPorts.meta

            // meta
            const total_Items = payloadData.length
            const index_start = (meta.current_page - 1) * meta.per_page + 1
            const index_end = Math.min(
                index_start + meta.per_page - 1,
                total_Items,
            )
            const last_page = Math.ceil(total_Items / meta.per_page)

            state.shipmentsTransitTimeByPorts.meta = {
                ...meta,
                last_page,
                index_start,
                index_end,
                total_Items,
            }

            // sort data
            const sortData = payloadData
                .map((item) => ({
                    name: item.ports,
                    uv: item.plannedTransitTime,
                    pv: item.actualTransitTime,
                    amt: 200,
                }))
                .slice(index_start - 1, index_end)

            state.shipmentsTransitTimeByPorts.chartData.item = sortData
        },
        setPageShipmentsTransitTimeByPorts: (
            state,
            action: PayloadAction<number>,
        ) => {
            state.shipmentsTransitTimeByPorts.meta.current_page = action.payload
        },
        setAverageTransitTimeByMode: (
            state,
            action: PayloadAction<IFetchAverageTransitTimeByMode[]>,
        ) => {
            const payloadData = action.payload
            const modeMap = {
                SEA: 'SEA',
                AIR: 'AIR',
                ROA: 'ROAD',
                RAI: 'RAIL',
                COURIER: 'COURIER',
            }

            const updatedData = state.averageTransitTimeByMode.map((item) => {
                const matchingTransport = payloadData.find(
                    (data) =>
                        modeMap[data.mode as keyof typeof modeMap] ===
                        item.name,
                )

                return matchingTransport
                    ? {
                          ...item,
                          number: matchingTransport.shipmentCount,
                      }
                    : item
            })
            state.averageTransitTimeByMode = updatedData
        },

        // segment cost
        setMonthlyTotalCost: (
            state,
            action: PayloadAction<IMonthlyTotalCostData[]>,
        ) => {
            const payloadData = action.payload
            const data = payloadData.map((item) => ({ ...item, amt: 10 }))
            state.monthlyTotalCost.data = data
        },
        setTotalCostByModes: (
            state,
            action: PayloadAction<IFetchTotalCostByModes>,
        ) => {
            const { totalShipment, transports, overalAmount } = action.payload
            const modeMap = {
                ROA: 'ROAD',
                SEA: 'SEA',
                AIR: 'AIR',
                RAI: 'RAIL',
                COURIER: 'COURIER',
            }

            const updatedChartData = state.totalCostByModes.chartData.map(
                (chartItem) => {
                    const matchingTransport = transports.find(
                        (transport) =>
                            modeMap[transport.mode as keyof typeof modeMap] ===
                            chartItem.name,
                    )

                    return matchingTransport
                        ? {
                              ...chartItem,
                              value: matchingTransport.shipmentCount,
                              additionalData: {
                                  value: matchingTransport.shipmentCount,
                                  persentage: matchingTransport.percentage,
                              },
                          }
                        : chartItem
                },
            )
            state.totalCostByModes.chartData = updatedChartData
            state.totalCostByModes.infoCardGroupItems[0].value =
                numberWithCommas(totalShipment.toString())
            state.totalCostByModes.infoCardGroupItems[1].value =
                numberWithCommas(overalAmount.toFixed(2).toString())
        },
    },
})

export const {
    setLoadShipmentStatus,
    setShipmentPerformanceByPorts,
    setPageShipmentPerformanceByPorts,
    setAvarageDaysDelayed,
    setAverageTransitTime,
    setMonthlyTotalCost,
    setShipmentsTransitTimeByPorts,
    setPageShipmentsTransitTimeByPorts,
    setAverageTransitTimeByMode,
    setTotalCostByModes,
} = performancesSlice.actions

// segment performance
export const shipmentPerformanceStatusSelector = (state: RootState) =>
    state.performancesIFB.shipmentPerformanceStatus
export const avarageDaysDelayedSelector = (state: RootState) =>
    state.performancesIFB.avarageDaysDelayed
export const shipmentPerformanceByPortsSelector = (state: RootState) =>
    state.performancesIFB.shipmentPerformanceByPorts

// segment transit time
export const averageTransitTimeSelector = (state: RootState) =>
    state.performancesIFB.averageTransitTime
export const shipmentsTransitTimeByPortsSelector = (state: RootState) =>
    state.performancesIFB.shipmentsTransitTimeByPorts
export const averageTransitTimeByModeSelector = (state: RootState) =>
    state.performancesIFB.averageTransitTimeByMode

// segment cost
export const totalCostByModesSelector = (state: RootState) =>
    state.performancesIFB.totalCostByModes
export const monthlyTotalCostSelector = (state: RootState) =>
    state.performancesIFB.monthlyTotalCost

export const donatChartSelector = (state: RootState) =>
    state.performancesIFB.donatChart
export const joinChartPefomSelector = (state: RootState) =>
    state.performancesIFB.joinChartPefom
export const barChartSelector = (state: RootState) =>
    state.performancesIFB.barChart

export default performancesSlice.reducer
