import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import {
    IPerformanceFilterValidation,
    usePerformanceFilterValidation,
} from 'client/ifb/form-validation/performance.validation'
import { ITabItem } from '@components/tab/tab.interface'
import { tabPerformanceItems, tabServiceTypeItems } from './performances.static'
import {
    ILoadingCost,
    ILoadingFetchPerformance,
    ILoadingTransitTime,
    IMonthlyTotalCostData,
    IPerformanceContent,
    IUsePerformances,
} from './performances.interface'
import { useDispatch, useSelector } from 'react-redux'
import {
    avarageDaysDelayedSelector,
    averageTransitTimeByModeSelector,
    averageTransitTimeSelector,
    barChartSelector,
    donatChartSelector,
    joinChartPefomSelector,
    monthlyTotalCostSelector,
    setAvarageDaysDelayed,
    setAverageTransitTime,
    setAverageTransitTimeByMode,
    setLoadShipmentStatus,
    setMonthlyTotalCost,
    setPageShipmentPerformanceByPorts,
    setPageShipmentsTransitTimeByPorts,
    setShipmentPerformanceByPorts,
    setShipmentsTransitTimeByPorts,
    setTotalCostByModes,
    shipmentPerformanceByPortsSelector,
    shipmentPerformanceStatusSelector,
    shipmentsTransitTimeByPortsSelector,
    totalCostByModesSelector,
} from './performances.slice'
import {
    fetchAverageTransitTime,
    fetchAverageTransitTimeByMode,
    fetchShipmentPerformanceByPorts,
    fetchShipmentPerformanceStatus,
    fetchShipmentsTransitTimeByPorts,
    fetchTotalCostByModes,
} from 'client/ifb/repository/performance.repository'
import moment from 'moment'
import { faker } from '@faker-js/faker'

const usePerformances = (): IUsePerformances => {
    const dispatch = useDispatch()

    // selectors
    const donatChart = useSelector(donatChartSelector)
    const joinChartPerfom = useSelector(joinChartPefomSelector)
    const barChart = useSelector(barChartSelector)

    // shipment selector
    const shipmentPerformanceStatus = useSelector(
        shipmentPerformanceStatusSelector,
    )
    const shipmentPerformanceByPorts = useSelector(
        shipmentPerformanceByPortsSelector,
    )
    const avarageDaysDelayed = useSelector(avarageDaysDelayedSelector)

    // selector transit time
    const averageTransitTime = useSelector(averageTransitTimeSelector)
    const shipmentsTransitTimeByPorts = useSelector(
        shipmentsTransitTimeByPortsSelector,
    )
    const averageTransitTimeByMode = useSelector(
        averageTransitTimeByModeSelector,
    )

    // selector cost
    const totalCostByModes = useSelector(totalCostByModesSelector)
    const monthlyTotalCost = useSelector(monthlyTotalCostSelector)

    // states
    const [tabFilter, setTabFilter] =
        useState<IPerformanceContent>('performance')
    const [isInitialRender, setIsInitialRender] = useState(true)

    // state performance
    const [tabFilterPerformance, setTabFilterPerformance] = useState<ITabItem>(
        tabPerformanceItems[0],
    )
    const [loadingPerformance, setLoadingPeformance] =
        useState<ILoadingFetchPerformance>({
            shipmentStatus: true,
            avarageDaysDelayed: true,
            shipmentPerformanceByPorts: true,
        })

    // state performance
    const [tabFilterTransitTime, setTabFilterTransitTime] = useState<ITabItem>(
        tabServiceTypeItems[0],
    )
    const [loadingTransitTime, setLoadingTransitTime] =
        useState<ILoadingTransitTime>({
            averageTransitTime: true,
            shipmentsTransitTimeByPorts: true,
        })

    // state costs
    const [loadingCost, setLoadingCost] = useState<ILoadingCost>({
        monthlyTotalCost: true,
        totalCostByModes: true,
    })

    // formik validation
    const { peformanceFilterValidation, peformanceFilterInitialValue } =
        usePerformanceFilterValidation()

    // formik
    const formikPerformance = useFormik<IPerformanceFilterValidation>({
        validationSchema: peformanceFilterValidation,
        initialValues: peformanceFilterInitialValue,
        onSubmit: async () => {},
    })

    // functions
    const wrapFetchDataContent = () => {
        if (
            formikPerformance.errors.endMonth ||
            formikPerformance.errors.startMonth
        )
            return
        if (tabFilter === 'performance') {
            setLoadingPeformance({
                shipmentStatus: true,
                avarageDaysDelayed: true,
                shipmentPerformanceByPorts: true,
            })
            fetchData()
        } else if (tabFilter === 'transitTime') {
            setLoadingTransitTime({
                averageTransitTime: true,
                shipmentsTransitTimeByPorts: true,
            })
            fetchDataTransitTime()
        } else if (tabFilter === 'costs') {
            setLoadingCost({
                monthlyTotalCost: true,
                totalCostByModes: true,
            })
            fetchDataCosts()
        }
    }

    // functions performance
    const fetchData = async () => {
        try {
            const statusShipment = fetchShipmentPerformanceStatus(
                formikPerformance.values,
            )
                .then((statusShipment) => {
                    dispatch(setLoadShipmentStatus(statusShipment.data))
                    setLoadingPeformance((prevLoading) => ({
                        ...prevLoading,
                        shipmentStatus: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching sea data:', error)
                    setLoadingPeformance((prevLoading) => ({
                        ...prevLoading,
                        shipmentStatus: false,
                    }))
                })

            const shipmentPerformanceByPortsDebounce =
                getshipmentPerformanceByPorts()
            const avarageDaysDelayed = loadAvarageDaysDelayed()
            await Promise.allSettled([
                statusShipment,
                avarageDaysDelayed,
                shipmentPerformanceByPortsDebounce,
            ])
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }
    const setPageNumberPerformanceByPorts = (pageNumber: number) => {
        if (pageNumber <= 0) return
        dispatch(setPageShipmentPerformanceByPorts(pageNumber))
    }
    const getshipmentPerformanceByPorts = async () => {
        try {
            setLoadingPeformance((prevLoading) => ({
                ...prevLoading,
                shipmentPerformanceByPorts: true,
            }))
            const actionResult = await fetchShipmentPerformanceByPorts(
                formikPerformance.values,
            )
            dispatch(setShipmentPerformanceByPorts(actionResult.data))
            setLoadingPeformance((prevLoading) => ({
                ...prevLoading,
                shipmentPerformanceByPorts: false,
            }))
        } catch (error) {
            console.error('Error fetching sea data:', error)
            setLoadingPeformance((prevLoading) => ({
                ...prevLoading,
                shipmentPerformanceByPorts: false,
            }))
        }
    }
    const loadAvarageDaysDelayed = () => {
        const start = moment(formikPerformance.values.startMonth, 'MM/YYYY')
        const end = moment(formikPerformance.values.endMonth, 'MM/YYYY')

        const rangeMonth: string[] = []
        const line1 = []
        const line2 = []
        let index = 1

        while (start.isSameOrBefore(end, 'month')) {
            rangeMonth.push(start.format('MMM'))
            line1.push({
                x: index,
                secName: start.format('YYYY'),
                y: faker.number.int({ min: 0, max: 10 }),
            })
            line2.push({
                x: index,
                secName: start.format('YYYY'),
                y: faker.number.int({ min: 0, max: 10 }),
            })

            start.add(1, 'month')
            index++
        }

        dispatch(setAvarageDaysDelayed({ line1, line2, tickItems: rangeMonth }))
        setTimeout(() => {
            setLoadingPeformance((prevLoading) => ({
                ...prevLoading,
                avarageDaysDelayed: false,
            }))
        }, 1000)
    }

    // functions transit time
    const fetchDataTransitTime = async () => {
        try {
            const averageTransitTime = fetchAverageTransitTime(
                formikPerformance.values,
            )
                .then((res) => {
                    dispatch(setAverageTransitTime(res.data[0]))
                    setLoadingTransitTime((prevLoading) => ({
                        ...prevLoading,
                        averageTransitTime: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching sea data:', error)
                    setLoadingTransitTime((prevLoading) => ({
                        ...prevLoading,
                        averageTransitTime: false,
                    }))
                })
            const shipmentsTransitTimeByPorts = getShipmentsTransitTimeByPorts()

            const averageTransitTimeByMode = fetchAverageTransitTimeByMode(
                formikPerformance.values,
            )
                .then((res) => {
                    dispatch(setAverageTransitTimeByMode(res.data))
                    setLoadingTransitTime((prevLoading) => ({
                        ...prevLoading,
                        averageTransitTime: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching sea data:', error)
                    setLoadingTransitTime((prevLoading) => ({
                        ...prevLoading,
                        averageTransitTime: false,
                    }))
                })

            await Promise.allSettled([
                averageTransitTime,
                shipmentsTransitTimeByPorts,
                averageTransitTimeByMode,
            ])
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }
    const getShipmentsTransitTimeByPorts = async () => {
        try {
            setLoadingTransitTime((prevLoading) => ({
                ...prevLoading,
                shipmentsTransitTimeByPorts: true,
            }))
            const actionResult = await fetchShipmentsTransitTimeByPorts(
                formikPerformance.values,
            )
            dispatch(setShipmentsTransitTimeByPorts(actionResult.data))
            setLoadingTransitTime((prevLoading) => ({
                ...prevLoading,
                shipmentsTransitTimeByPorts: false,
            }))
        } catch (error) {
            console.error('Error fetching sea data:', error)
            setLoadingPeformance((prevLoading) => ({
                ...prevLoading,
                shipmentPerformanceByPorts: false,
            }))
        }
    }
    const setPageNumberShipmentsTransitTimeByPorts = (pageNumber: number) => {
        if (pageNumber <= 0) return
        dispatch(setPageShipmentsTransitTimeByPorts(pageNumber))
    }

    // functions costs
    const fetchDataCosts = async () => {
        try {
            const totalCostByModes = fetchTotalCostByModes(
                formikPerformance.values,
            )
                .then((res) => {
                    dispatch(setTotalCostByModes(res.data))
                    setLoadingCost((prevLoading) => ({
                        ...prevLoading,
                        totalCostByModes: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching data:', error)
                    setLoadingCost((prevLoading) => ({
                        ...prevLoading,
                        totalCostByModes: false,
                    }))
                })

            const monthlyTotalCost = loadMonthlyTotalCost()

            await Promise.allSettled([totalCostByModes, monthlyTotalCost])
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }
    const loadMonthlyTotalCost = () => {
        const start = moment(formikPerformance.values.startMonth, 'MM/YYYY')
        const end = moment(formikPerformance.values.endMonth, 'MM/YYYY')

        let index = 1
        const data: IMonthlyTotalCostData[] = []

        while (start.isSameOrBefore(end, 'month')) {
            data.push({
                name: start.format('MMM'),
                secName: start.format('YYYY'),
                f1: faker.number.int({ min: 0, max: 10 }),
                ...(index !== 1 && {
                    f2: faker.number.int({ min: 0, max: 10 }),
                    f3: faker.number.int({ min: 0, max: 10 }),
                    f4: faker.number.int({ min: 0, max: 10 }),
                    f5: faker.number.int({ min: 0, max: 10 }),
                }),
            })

            start.add(1, 'month')
            index++
        }
        dispatch(setMonthlyTotalCost(data))
        setTimeout(() => {
            setLoadingCost((prevLoading) => ({
                ...prevLoading,
                monthlyTotalCost: false,
            }))
        }, 1000)
    }

    // useEffects
    useEffect(() => {
        formikPerformance.submitForm()
    }, [])
    useEffect(() => {
        const handler = setTimeout(() => {
            setIsInitialRender(false)
            wrapFetchDataContent()
        }, 1000)
        return () => clearTimeout(handler)
    }, [formikPerformance.values, formikPerformance.errors])
    useEffect(() => {
        if (!isInitialRender) {
            wrapFetchDataContent()
        }
    }, [tabFilter, formikPerformance.errors])

    // useEffect performance
    useEffect(() => {
        if (!isInitialRender) {
            getshipmentPerformanceByPorts()
        }
    }, [shipmentPerformanceByPorts.meta.current_page])

    // useEffect transit time
    useEffect(() => {
        if (!isInitialRender) {
            getShipmentsTransitTimeByPorts()
        }
    }, [shipmentsTransitTimeByPorts.meta.current_page])

    return {
        formikPerformance,
        tabFilter,
        barChart,
        tabFilterPerformance,
        donatChart,
        joinChartPerfom,
        tabFilterTransitTime,
        setTabFilterTransitTime,
        setTabFilterPerformance,
        setTabFilter,
        loading: loadingPerformance,
        shipmentPerformanceStatus,
        shipmentPerformanceByPorts,
        setPageNumberPerformanceByPorts,
        avarageDaysDelayed,
        loadingTransitTime,
        averageTransitTime,
        monthlyTotalCost,
        loadingCost,
        shipmentsTransitTimeByPorts,
        setPageNumberShipmentsTransitTimeByPorts,
        averageTransitTimeByMode,
        totalCostByModes,
    }
}

export default usePerformances
