import { IResponseData } from 'common/common.interface'
import { fetch } from 'common/common.service'
import { ifbEndpoints } from '../endpoints/ifb.endpoints'
import { IPerformanceFilterValidation } from '../form-validation/performance.validation'
import {
    IFetchAverageTransitTime,
    IFetchAverageTransitTimeByMode,
    IFetchMonthlyTotalCost,
    IFetchShipmentsTransitTimeByPorts,
    IFetchTotalCostByModes,
    IFilterPerformance,
    IShipmentStatusData,
    shipmentPerformanceByPorts,
} from './interface/performance.interface'
import moment from 'moment'
import { Toast } from '@components/toast/toast.component'

const getLastDateOfMonth = (monthYear: string): string => {
    const endMonth = monthYear
    const lastDate = moment(endMonth, 'MM/YYYY').endOf('month')

    if (!lastDate.isValid()) {
        return 'Invalid Date'
    }

    return lastDate.format('YYYY-MM-DD')
}

const setUpdatedFilter = (
    filter: IPerformanceFilterValidation,
    performanceMode: string,
) => {
    const { startMonth, endMonth, currency } = filter

    const [_startMonth, _startYear] = startMonth.split('/')

    const formattedStartMonth = `${_startYear}-${_startMonth}-01`
    const formattedEndMonth = getLastDateOfMonth(endMonth)

    const updatedFilter = {
        StartDate: formattedStartMonth,
        EndDate: formattedEndMonth,
        PerformanceMode: performanceMode,
        ...(currency && {
            CurrencyCode: currency,
        }),
    }

    return updatedFilter
}

export const fetchShipmentPerformanceStatus = async (
    filter: IPerformanceFilterValidation,
) => {
    const updatedFilter = setUpdatedFilter(filter, 'performance')
    try {
        const response = await fetch<
            IResponseData<IShipmentStatusData>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.performance_getStatus,
            params: updatedFilter,
        })
        if (!response.isSuccess) {
            Toast({
                header: 'Shipments Performance Status',
                message: `Failed to fetch shipments performance status data.`,
                type: 'error',
            })
        }
        return response
    } catch (error: any) {
        const err = error as IResponseData<IShipmentStatusData>
        err.isSuccess = false
        Toast({
            header: 'Shipments Performance Status',
            message: `${error.message}`,
            type: 'error',
        })
        return err
    }
}

export const fetchShipmentPerformanceByPorts = async (
    filter: IPerformanceFilterValidation,
) => {
    const updatedFilter = setUpdatedFilter(filter, 'performance')

    try {
        const response = await fetch<
            IResponseData<shipmentPerformanceByPorts>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.performance_getByPorts,
            params: updatedFilter,
        })
        if (!response.isSuccess) {
            Toast({
                header: 'Shipments Performance by Ports',
                message: `Failed to fetch shipments performance by ports data.`,
                type: 'error',
            })
        }
        return response
    } catch (error: any) {
        const err = error as IResponseData<shipmentPerformanceByPorts>
        err.isSuccess = false
        Toast({
            header: 'Shipments Performance by Ports',
            message: `${error.message}`,
            type: 'error',
        })
        return err
    }
}

export const fetchAverageTransitTime = async (
    filter: IPerformanceFilterValidation,
) => {
    const updatedFilter = setUpdatedFilter(filter, 'transitTime')

    try {
        const response = await fetch<
            IResponseData<IFetchAverageTransitTime[]>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.performance_averageTransitTime,
            params: updatedFilter,
        })
        if (!response.isSuccess) {
            Toast({
                header: 'Average Transit Time',
                message: `Failed to fetch average transit time data.`,
                type: 'error',
            })
        }
        return response
    } catch (error: any) {
        const err = error as IResponseData<IFetchAverageTransitTime[]>
        err.isSuccess = false
        Toast({
            header: 'Average Transit Time',
            message: `${error.message}`,
            type: 'error',
        })
        return err
    }
}

export const fetchShipmentsTransitTimeByPorts = async (
    filter: IPerformanceFilterValidation,
) => {
    const updatedFilter = setUpdatedFilter(filter, 'transitTime')
    try {
        const response = await fetch<
            IResponseData<IFetchShipmentsTransitTimeByPorts[]>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.performance_shipmentsTransitTimeByPorts,
            params: updatedFilter,
        })
        if (!response.isSuccess) {
            Toast({
                header: 'Shipments Transit Time by Ports',
                message: `Failed to fetch shipments Transit Time by Ports data.`,
                type: 'error',
            })
        }
        return response
    } catch (error: any) {
        const err = error as IResponseData<IFetchShipmentsTransitTimeByPorts[]>
        err.isSuccess = false
        Toast({
            header: 'Shipments Transit Time by Ports',
            message: `${error.message}`,
            type: 'error',
        })
        return err
    }
}

export const fetchAverageTransitTimeByMode = async (
    filter: IPerformanceFilterValidation,
) => {
    const updatedFilter = setUpdatedFilter(filter, 'transitTime')
    try {
        const response = await fetch<
            IResponseData<IFetchAverageTransitTimeByMode[]>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.performance_averageTransitTimeByMode,
            params: updatedFilter,
        })
        if (!response.isSuccess) {
            Toast({
                header: 'Shipments Transit Time by Ports',
                message: `Failed to fetch shipments transit time by ports data`,
                type: 'error',
            })
        }
        return response
    } catch (error: any) {
        const err = error as IResponseData<IFetchAverageTransitTimeByMode[]>
        err.isSuccess = false
        Toast({
            header: 'Shipments Transit Time by Ports',
            message: `${error.message}`,
            type: 'error',
        })
        return err
    }
}

export const fetchTotalCostByModes = async (
    filter: IPerformanceFilterValidation,
) => {
    const updatedFilter = setUpdatedFilter(filter, 'cost')
    try {
        const response = await fetch<
            IResponseData<IFetchTotalCostByModes>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.performance_TotalCostByModes,
            params: updatedFilter,
        })
        if (!response.isSuccess) {
            Toast({
                header: 'Total Cost by Modes',
                message: `Failed to fetch total cost by modes data`,
                type: 'error',
            })
        }
        return response
    } catch (error: any) {
        const err = error as IResponseData<IFetchTotalCostByModes>
        err.isSuccess = false
        Toast({
            header: 'Total Cost by Modes',
            message: `${error.message}`,
            type: 'error',
        })
        return err
    }
}

export const fetchMonthlyTotalCost = async (
    filter: IPerformanceFilterValidation,
) => {
    const updatedFilter = setUpdatedFilter(filter, 'cost')
    try {
        const response = await fetch<
            IResponseData<IFetchMonthlyTotalCost[]>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.performance_TotalMonthlyCost,
            params: updatedFilter,
        })
        if (!response.isSuccess) {
            Toast({
                header: 'Monthly Total Cost',
                message: `Failed to fetch monthly total cost data`,
                type: 'error',
            })
        }
        return response
    } catch (error: any) {
        const err = error as IResponseData<IFetchMonthlyTotalCost[]>
        err.isSuccess = false
        Toast({
            header: 'Monthly Total Cost',
            message: `${error.message}`,
            type: 'error',
        })
        return err
    }
}
