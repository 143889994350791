/* eslint-disable no-unused-vars */
import Button from '@components/button/button.component'
import SimpleTable from '@components/simple-table/simple-table.component'
import { containersHeaders } from '../../booking-details/booking-details.static'
import { metaDummy } from '../../bookings.dummy'
import FormInput from '@components/form-input/form-input.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import { FormikProvider } from 'formik'
import {
    ICreateBookingContainersValidation,
    useBookingsCreateValidation,
} from 'client/ifb/form-validation/booking-details.validation'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IUseCreateBooking } from '../create-booking.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'

const ContainerBookings = ({ CBService }: { CBService: IUseCreateBooking }) => {
    const [isNewForm, setIsNewForm] = useState<boolean>(true)
    const { t } = useTranslation()
    const { createContainerBookingsInitialValue } =
        useBookingsCreateValidation()

    return (
        <div className="h-full grid grid-cols-12">
            <div className="col-span-9">
                <SimpleTable<ICreateBookingContainersValidation>
                    headers={containersHeaders}
                    data={CBService.containersData || []}
                    usePagination
                    nextHandling={(page) => {
                        console.log('hit Next API -> ' + page)
                    }}
                    previousHandling={(page) => {
                        console.log('hit Prev API -> ' + page)
                    }}
                    meta={metaDummy}
                    onRowClick={(data) => {
                        setIsNewForm(false)
                        CBService.setContainerData(data)
                    }}
                />
            </div>
            <FormikProvider value={CBService.formikCreateContainerBookings}>
                <div className="col-span-3 border-y-2 flex flex-col justify-between">
                    <div>
                        <div className="flex themes-text-gray-v5 justify-between items-center p-3 text-[16px] border-b-2">
                            {!isNewForm ? (
                                <>
                                    <span>Selected Container</span>
                                    <div className="text-[24px] flex">
                                        <div className="border-x-2 px-2">
                                            <i
                                                className="ri-file-copy-line themes-text-hover-green-v3 cursor-pointer"
                                                onClick={() => {
                                                    CBService.setContainerParams(
                                                        'duplicate',
                                                    )
                                                    CBService.formikCreateContainerBookings.submitForm()
                                                }}
                                            />
                                        </div>
                                        <div className="pl-2">
                                            <i
                                                className="ri-delete-bin-line themes-text-hover-red-v3 cursor-pointer"
                                                onClick={() => {
                                                    CBService.setContainerParams(
                                                        'delete',
                                                    )
                                                    CBService.formikCreateContainerBookings.submitForm()
                                                    setIsNewForm(true)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>Add New Container</>
                            )}
                        </div>
                        <div className="p-3 grid grid-cols-2 gap-3">
                            <FormInput
                                label={'Container Count'}
                                name={`containerCount`}
                                placeholder={'Enter here'}
                                parentDivClassName="!mb-0"
                                type={'number'}
                            />
                            <FormDropdown
                                isSearchable
                                isClearable
                                label={'Container Type'}
                                name={`containerType.code`}
                                placeholder={'Select here'}
                                options={CBService.dropdownData.containerModes}
                                defaultValue={
                                    CBService.dropdownData.containerModes
                                }
                                parentDivClassName="!mb-0"
                                isAsync={true}
                                loadOptions={(e: string) =>
                                    CBService.loadOptions({ containerItem: e })
                                }
                                additionalOnClick={(data) => {
                                    CBService.additionalClickSetDesc<ICreateBookingContainersValidation>(
                                        CBService.formikCreateContainerBookings,
                                        'containerType.description',
                                        data as IDropdownItem,
                                    )
                                }}
                            />
                            <FormInput
                                label={'Container Number'}
                                name={`containerNumber`}
                                placeholder={'Select here'}
                                parentDivClassName="!mb-0"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 p-3">
                        <Button
                            onClick={() => {
                                if (isNewForm) {
                                    CBService.setContainerParams('add')
                                } else {
                                    CBService.setContainerParams('update')
                                }
                                CBService.formikCreateContainerBookings.submitForm()
                            }}
                            label={
                                isNewForm
                                    ? 'Add New Container'
                                    : 'Update Container'
                            }
                            variant="brand"
                            isDisabled={
                                isNewForm
                                    ? false
                                    : !CBService.formikCreateContainerBookings
                                          .dirty
                            }
                        />
                        <Button
                            onClick={() => {
                                CBService.setContainerData(
                                    createContainerBookingsInitialValue,
                                )
                                CBService.formikCreateContainerBookings.resetForm()
                                setIsNewForm(true)
                            }}
                            label={t('action.discard_changes')}
                            variant="red-inverse"
                            isDisabled={
                                !isNewForm
                                    ? false
                                    : !CBService.formikCreateContainerBookings
                                          .dirty
                            }
                        />
                    </div>
                </div>
            </FormikProvider>
        </div>
    )
}

export default ContainerBookings
