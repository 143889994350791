import CardsDashboard from '@components/card-dashboard/card-dashboard.component'
import { FormikProvider } from 'formik'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import InfoCardGroup from '@components/InfoCardGroup/Info-card-group.component'
import { BarChartStacked, DoughnutChart } from '@components/rechart-component'
import { formatNumber } from 'common/common.service'
import FormMonthYearPicker from '@components/form-month-year-picker/form-month-year-picker.component'
import './style.css'
import useDashboard from './dashboard.service'
import MapWithTable from './components/map-with-table.component'
import InfoCard from './components/info-card.component'
import TableOpenPO from './components/table-open-po.component'

export default function WIP() {
    return <DashboardImprovement />
}

export function DashboardImprovement() {
    const {
        formikDashboard,
        dataItems,
        isLoading,
        donatData,
        barInvoiceData,
        news,
        barContainerData,
        transModeData,
        itemInvoiceData,
        itemInvoiceDataUnPaid,
        statusContainerMode,
        trackingShipmentLocatione,
        isLoadingNews,
        setPageData,
        setTabFilter,
        tabFilter,
    } = useDashboard()

    const { fclShipment, lclShipment } = statusContainerMode

    return (
        <div className="flex flex-col gap-3 overflow-auto xl:overflow-hidden">
            <CardsDashboard className="flex flex-initial flex-wrap w-full gap-2 pb-3">
                <div className="p-1 flex-grow w-[367px]">
                    <FormikProvider value={formikDashboard}>
                        <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-2">
                            <FormDropdown
                                isSearchable
                                label={'Transport Mode'}
                                name={`TransportModeCode`}
                                placeholder="Select Here"
                                parentDivClassName="!mb-0"
                                options={transModeData}
                                defaultValue={[
                                    { value: 'all', label: 'All Mode' },
                                ]}
                            />
                            <FormDropdown
                                isSearchable
                                label={'Shipment Date By'}
                                name={`ShipmentDateBy`}
                                placeholder="Select Here"
                                parentDivClassName="!mb-0"
                                options={[
                                    { value: 'eta', label: 'ETA' },
                                    { value: 'etd', label: 'ETD' },
                                    {
                                        value: 'newshipment',
                                        label: 'New Shipment',
                                    },
                                ]}
                            />
                            <FormMonthYearPicker
                                name="StartMonth"
                                label="START MONTH"
                                placeholder="MM/YYYY"
                            />
                            <FormMonthYearPicker
                                name="EndMonth"
                                label="END MONTH"
                                placeholder="MM/YYYY"
                            />
                        </div>
                    </FormikProvider>
                </div>
                <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-5 mobile:!grid-cols-none gap-3 flex-grow">
                    {dataItems.map((item, index) => (
                        <InfoCard
                            key={index}
                            icon={item.icon}
                            total={item.total}
                            title={item.title}
                            items={item.items}
                        />
                    ))}
                </div>
            </CardsDashboard>
            <div className="grid grid-cols-12 grid-rows-2 gap-3 xl:h-[calc(100vh-15.2rem)]">
                <CardsDashboard className="!p-0 row-span-2 col-span-12 xl:col-span-5 h-[710px] xl:h-full">
                    <MapWithTable
                        data={trackingShipmentLocatione.data}
                        meta={trackingShipmentLocatione.meta}
                        loading={isLoading.shipmentData}
                        tabFilter={tabFilter}
                        setPageData={setPageData}
                        setTabFilter={setTabFilter}
                    />
                </CardsDashboard>
                <CardsDashboard className="flex w-full col-span-12 row-span-2 xl:col-span-7 xl:row-span-1 xl:h-full md:h-[492px] sm:flex-col">
                    <div className="flex flex-col  md:w-1/2 sm:h-1/2">
                        <div className="pb-1 mb-1 font-bold text-title-card-dashboard">
                            Total Shipments by Container Modes
                        </div>
                        <div className="grid grid-cols-2 themes-bg-gray-v1 border-t themes-border-gray-v4 mb-2 text-size-XS">
                            <div className="ml-2 border-r  themes-border-gray-v4 text-size-XS">
                                <div className="">FCL Shipments</div>
                                <div className="flex border-t justify-around themes-border-gray-v4 themes-text-gray-v9">
                                    <div className="flex items-center">
                                        <div className="leading-4">
                                            <div className="font-bold text-size-XS">
                                                {formatNumber(fclShipment.sea)}
                                            </div>
                                            <div className="text-size-XS">
                                                SEA
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <div className="border-l h-[30px] themes-border-gray-v4 mx-2" />
                                        <div className="leading-4">
                                            <div className="font-bold text-size-XS">
                                                {formatNumber(fclShipment.road)}
                                            </div>
                                            <div className="text-size-XS">
                                                Road
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <div className="border-l h-[30px] themes-border-gray-v4 mx-2" />
                                        <div className="leading-4">
                                            <div className="font-bold text-size-XS">
                                                {formatNumber(fclShipment.rail)}
                                            </div>
                                            <div className="text-size-XS">
                                                Rail
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ml-1">
                                <div className="">LCL Shipments</div>
                                <div className="flex border-t justify-around themes-border-gray-v4 themes-text-gray-v9">
                                    <div className="flex items-center">
                                        <div className="leading-4">
                                            <div className="font-bold text-size-XS">
                                                {formatNumber(lclShipment.sea)}
                                            </div>
                                            <div className="text-desc-dashboard">
                                                SEA
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <div className="border-l themes-border-gray-v4 mx-2" />
                                        <div className="leading-4">
                                            <div className="font-bold text-size-XS">
                                                {formatNumber(lclShipment.road)}
                                            </div>
                                            <div className="text-desc-dashboard">
                                                Road
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <div className="border-l h-[30px] themes-border-gray-v4 mx-2" />
                                        <div className="leading-4">
                                            <div className="font-bold text-size-XS">
                                                {formatNumber(lclShipment.rail)}
                                            </div>
                                            <div className="text-desc-dashboard">
                                                Rail
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-grow">
                            <div className="h-full">
                                <BarChartStacked
                                    data={barContainerData}
                                    isLoading={isLoading.containerModeStatus}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="border-l pl-3 flex flex-col md:w-1/2 sm:border-0 sm:pl-0 sm:h-1/2">
                        <div className="border-b pb-2 mb-1 font-bold text-title-card-dashboard">
                            Latest Industry News
                        </div>
                        <div className="flex-grow min-h-0 overflow-hidden">
                            <div className="overflow-auto height-news-data sm:h-[35vh]">
                                <TableOpenPO
                                    data={news || []}
                                    isLoading={isLoadingNews}
                                />
                            </div>
                        </div>
                    </div>
                </CardsDashboard>
                <CardsDashboard className="flex w-full col-span-12 row-span-2 h-[550px] xl:col-span-7 xl:row-span-1 xl:h-full sm:flex-col">
                    <div className="flex flex-col md:w-1/2 sm:h-1/2">
                        <div className="pb-1 mb-1 font-bold text-title-card-dashboard">
                            Total Shipments by Invoice Paid
                        </div>
                        <InfoCardGroup
                            items={itemInvoiceData}
                            titleClass="!text-size-XS"
                            descClass="!text-[9px]"
                        />
                        <BarChartStacked data={barInvoiceData} />
                    </div>
                    <div className="border-l pl-3 !text-size-XS sm:border-0 sm:pl-0 md:w-1/2 sm:h-1/2">
                        <div className="pb-1 mb-1 font-bold text-title-card-dashboard">
                            Total Shipments Invoice Unpaid
                        </div>
                        <InfoCardGroup
                            items={itemInvoiceDataUnPaid}
                            titleClass="!text-size-XS"
                            descClass="!text-[9px]"
                        />
                        <div className="">
                            <DoughnutChart data={donatData} />
                        </div>
                    </div>
                </CardsDashboard>
            </div>
        </div>
    )
}
