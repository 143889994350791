import ProgressBarChart from './progress-bar-chart.component'
import { transitShipmentDelivery } from '../performances.dummy'

const TransitShipmentDelivery = () => {
    return (
        <div className="overflow-auto h-full w-full">
            {transitShipmentDelivery.map((data) => {
                let transportModeIcon: string
                switch (data.transportMode) {
                    case 'sea':
                        transportModeIcon = 'ri-ship-2-line'
                        break
                    default:
                        transportModeIcon = 'ri-ship-2-line'
                }
                const setLoadCountryCode = data.loadCountryCode
                    .slice(0, 2)
                    .toLowerCase()
                const setDischargeCountryCode = data.dischargeCountryCode
                    .slice(0, 2)
                    .toLowerCase()
                const loadCountryImg = `https://flagcdn.com/${setLoadCountryCode}.svg`
                const dischargeCountryImg = `https://flagcdn.com/${setDischargeCountryCode}.svg`

                return (
                    <>
                        <div className="flex p-3 gap-3 w-full text-size-XS">
                            <div className="min-w-max">
                                <div className="themes-bg-gray-v2 h-[30px] w-[30px] rounded flex items-center justify-center">
                                    <i
                                        className={`${transportModeIcon} text-size-S`}
                                    ></i>
                                </div>
                            </div>
                            <div className="border-r pr-3 min-w-max">
                                <div>Container Mode</div>
                                <div>{data.containerMode}</div>
                            </div>
                            <div className="border-r pr-3 min-w-max">
                                <div>Shipments Record</div>
                                <div>{data.totalShipments}</div>
                            </div>
                            <div className="border-r pr-3 flex items-center gap-1 min-w-max">
                                <img
                                    src={loadCountryImg}
                                    alt=""
                                    className="w-[34px] border rounded"
                                />
                                <div>
                                    <div>Load</div>
                                    <div>{data.loadCountryCode}</div>
                                </div>
                            </div>
                            <div className="border-r pr-3 flex items-center gap-1 min-w-max">
                                <div>
                                    <img
                                        src={dischargeCountryImg}
                                        alt=""
                                        className="w-[34px] border rounded"
                                    />
                                </div>
                                <div>
                                    <div>Discharge</div>
                                    <div>{data.dischargeCountryCode}</div>
                                </div>
                            </div>
                            <div className="flex items-center justify-end border-r pr-3 min-w-max">
                                <ProgressBarChart
                                    containerClassNames={`w-[200px] w-min-[200px] w-max-[200px] flex justify-end`}
                                    data={data}
                                />
                            </div>
                            <div className="min-w-max">
                                <div>Transit Days</div>
                                <div>
                                    {data.totalDays} -{' '}
                                    {data.totalDays + data.extraDay} Days
                                </div>
                            </div>
                        </div>
                        <div className="border-b"></div>
                    </>
                )
            })}
        </div>
    )
}

export default TransitShipmentDelivery
