import { IShipmentDeliveryData } from '../performances.interface'

const ProgressBarChart = ({
    containerClassNames,
    data,
}: {
    containerClassNames: string
    data: IShipmentDeliveryData
}) => {
    return (
        <div className={`flex gap-1 ${containerClassNames}`}>
            {data.goodsDeliveryStage.map((item) => {
                const total = data.totalDays
                const width = `${(item.value / total) * 100}%`
                return (
                    <div
                        className={`${item.bgColor} rounded h-[20px] flex justify-center themes-text-white`}
                        style={{
                            width,
                            backgroundColor: item.bgColor,
                        }}
                    >
                        {item.accessor === 'transitTime'
                            ? `${item.value} Days`
                            : ''}
                    </div>
                )
            })}
        </div>
    )
}

export default ProgressBarChart
