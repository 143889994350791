export const ifbEndpoints = {
    // dashboard
    news_scrapper: 'Scrapper/ifb',
    scfi_index: 'Scrapper/sse',
    shipmentStatus: 'UniversalShipment/GetShipmentStatus',
    shipmentMilestoneStatus: 'UniversalShipment/GetShipmentMilestones',
    bookingStatus: 'BookingShipment/GetCountStatus',
    containerModeStatus: 'UniversalShipment/GetContainerTransportStatus',

    //bookings
    bookings: 'BookingShipment',
    bookingsGetMetadata: 'BookingShipment/GetMetadata',
    bookingsGetContactMetadata: 'BookingShipment/GetContactMetadata',
    GetOriginDestination: 'BookingShipment/GetOriginDestination',
    bookingsCreate: 'BookingShipment/create',

    //volume
    barChartVolume: 'Analytics/Volume/Transport',
    donatChartVolume: 'Analytics/Volume',

    // performance
    performance_getStatus: 'Analytics/Performance/ShipmentStatus',
    performance_getByPorts: 'Analytics/Performance/PerformanceByPorts',
    performance_averageTransitTime: 'Analytics/Performance/ShipmentTransitTime',
    performance_shipmentsTransitTimeByPorts:
        'Analytics/Performance/ShipmentTransitTimeByPort',
    performance_averageTransitTimeByMode:
        'Analytics/Performance/ShipmentTransitRecordedByModes',
    performance_TotalCostByModes: 'Analytics/Performance/TotalCostByModes',
    performance_TotalMonthlyCost: 'Analytics/Performance/TotalMonthlyCost',

    //shipments
    shipment_getAll: 'UniversalShipment',

    // trackings
    tracking_getAll: 'Container',

    //manage user
    manage_user_getAll: 'Staff',
    manage_user_getCurrentUser: 'Staff/current-staff',
    manage_user_getModuleTemplates: 'Staff/module-templates',
}
