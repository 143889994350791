import { IDonatRechart } from '@components/rechart-component/rechart-interface'
import { IShipmentStatusCount } from 'client/ifb/repository/interface/performance.interface'
import { numberWithCommas } from 'common/common.service'
import { useRef } from 'react'

const ColorPieChart = ({
    items,
    content = 'performance',
}: {
    items: IDonatRechart<IShipmentStatusCount>[]
    content?: string
}) => {
    const totalValue = items.reduce((acc, itemData) => acc + itemData.value, 0)
    const ref = useRef<HTMLDivElement>(null)

    return (
        <div
            className="col-span-6 lg:col-span-7 grid grid-cols-2 gap-3 h-fit"
            ref={ref}
        >
            {items.map((itemData, index) => {
                const { value, name, fill, textColor } = itemData
                const percentage = ((value / totalValue) * 100).toFixed(2)

                return (
                    <div
                        key={index}
                        className="flex flex-col text-size-XS leading-none"
                    >
                        {name && (
                            <div className="thames-text-gray-v9 pt-1 whitespace-nowrap text-ellipsis overflow-hidden">
                                {name}
                            </div>
                        )}
                        <div className="font-bold themes-text-gray-v9">
                            {content === 'performance' ? '' : `PLN `}
                            {numberWithCommas(itemData.value.toString())}
                        </div>
                        <div
                            style={{ background: `${fill}` }}
                            className={`w-auto h-4 px-1 bg-red-100 rounded justify-center items-center gap-1 inline-flex  py-3`}
                        >
                            <div
                                style={{ color: `${textColor}` }}
                                className=" font-bold leading-[18px]"
                            >
                                {content === 'performance'
                                    ? itemData.additionalData
                                        ? itemData.additionalData.persentage
                                        : 0
                                    : percentage !== 'NaN'
                                      ? percentage
                                      : 0}
                                %
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default ColorPieChart
