var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorMessage, useField } from 'formik';
import Dropdown from '@components/dropdown/dropdown.component';
import { useEffect, useState } from 'react';
function FormDropdown(_a) {
    var _b = _a.options, options = _b === void 0 ? [] : _b, _c = _a.parentDivClassName, parentDivClassName = _c === void 0 ? '' : _c, _d = _a.showErrorMessage, showErrorMessage = _d === void 0 ? true : _d, additionalOnClick = _a.additionalOnClick, props = __rest(_a, ["options", "parentDivClassName", "showErrorMessage", "additionalOnClick"]);
    //formik
    var _e = useField(props.name), field = _e[0], meta = _e[1], helpers = _e[2];
    var _f = useState(), selectedItem = _f[0], setSelectedItem = _f[1];
    var rearrangeDropdown = function () {
        var sItem;
        if (!field.value) {
            setSelectedItem(sItem);
            return;
        }
        if (props.isMultiSelect) {
            var valArray_1 = field.value;
            sItem = options.filter(function (x) {
                return valArray_1.includes(x.value);
            });
        }
        else {
            sItem = options.find(function (x) {
                return x.value === field.value;
            });
        }
        setSelectedItem(sItem);
        if (sItem && additionalOnClick) {
            additionalOnClick(sItem);
        }
    };
    useEffect(function () {
        rearrangeDropdown();
    }, [field.value]);
    useEffect(function () {
        rearrangeDropdown();
    }, [options]);
    var borderColorClass = meta.error && meta.touched ? 'themes-border-red-v3' : '';
    var isError = !(!meta.error || (meta.error && !meta.touched));
    var errorClassMessage = isError ? '' : 'hidden';
    return (_jsxs("div", __assign({ className: "".concat(parentDivClassName, " mb-3") }, { children: [_jsx(Dropdown, __assign({ options: options, onClick: function (change) {
                    if (!change) {
                        helpers.setValue(undefined);
                        return;
                    }
                    if (!props.isMultiSelect) {
                        var _change = change;
                        helpers.setValue(_change.value);
                    }
                    else {
                        var _change = change;
                        var multiValue = _change.map(function (d) {
                            return d.value;
                        });
                        helpers.setValue(multiValue);
                    }
                    additionalOnClick && additionalOnClick(change);
                }, className: " ".concat(props.className), parentDivClassname: borderColorClass, value: selectedItem }, props)), _jsx("div", __assign({ className: "ml-small-x text-size-XS text-red-500 ".concat(!showErrorMessage ? 'hidden' : errorClassMessage) }, { children: _jsx(ErrorMessage, { name: props.name, component: "p" }) }))] })));
}
export default FormDropdown;
