var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import EstimationInfo from "@components/estimation-info/estimation-info";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export function TableExpandableRow(_a) {
    var _b, _c, _d, _e;
    var props = __rest(_a, []);
    var t = useTranslation().t;
    var navigate = useNavigate();
    var _f = useState(false), isExpanded = _f[0], setIsExpanded = _f[1];
    var _g = useState(false), isOpenDetail = _g[0], setIsOpenDetail = _g[1];
    var nameRoute = props.moduleTitle.toLocaleLowerCase();
    var IconRow = props.data.typeTransport === "SEA"
        ? " ri-ship-line"
        : props.data.typeTransport === "AIR"
            ? "ri-plane-line"
            : "ri-truck-line";
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "grid grid-cols-12 justify-between px-[30px] py-[15px] border-b items-center" }, { children: [_jsx("div", __assign({ className: "col-span-3 sm:col-span-12" }, { children: _jsxs("div", __assign({ className: "flex items-center" }, { children: [_jsx("i", { className: "".concat(IconRow, " text-[32px] themes-text-gray-v5") }), _jsxs("div", __assign({ className: "ml-5" }, { children: [_jsx("div", __assign({ className: "text-table-title font-bold" }, { children: props.data.idShipment })), _jsx("div", __assign({ className: "text-table-desc" }, { children: props.data.description }))] }))] })) })), _jsxs("div", __assign({ className: "col-span-9 sm:col-span-12 flex items-center", onClick: function () { setIsExpanded(!isExpanded); } }, { children: [_jsx("div", __assign({ className: "flex-grow" }, { children: _jsx(EstimationInfo, { initialCountryName: props.data.initialCountryName, initialCountryCode: props.data.initialCountryCode, destinationCountryName: props.data.destinationCountryName, destinationCountryCode: props.data.destinationCountryCode, ETD: props.data.ETD, ETA: props.data.ETA, iconMap: false, status: props.data.status }) })), props.moreOptions && (_jsxs("div", __assign({ className: "relative sm:hidden" }, { children: [_jsx("div", { className: "h-[40px] border-l  themes-border-gray-v3 mx-3" }), _jsx("div", { children: isOpenDetail && (_jsx("div", __assign({ className: "absolute z-10 mt-2 bg-white border rounded-sm shadow-lg right-0 w-max" }, { children: (_b = props === null || props === void 0 ? void 0 : props.moreOptions) === null || _b === void 0 ? void 0 : _b.map(function (itemMore, idx) {
                                                return (_jsx("div", __assign({ className: "py-2 px-5 hover:bg-gray-100 cursor-pointer", onClick: function () {
                                                        // navigate(
                                                        //     `/${nameRoute}-details/${itemMore.value}/2`
                                                        // );
                                                        (props === null || props === void 0 ? void 0 : props.onRowClick) &&
                                                            props.onRowClick(props.data, itemMore.value);
                                                    } }, { children: itemMore.label }), "item-more-".concat(idx)));
                                            }) }))) })] }))), _jsx("i", { className: "ri-arrow-down-s-line transform cursor-pointer ".concat(isExpanded ? "rotate-180" : "rotate-0", " transition-transform duration-300 ease-in-out sm:hidden") })] }))] })), _jsxs("div", __assign({ className: "overflow-hidden px-[30px] grid grid-cols-12 themes-bg-gray-v1   \n                                            ".concat(isExpanded ? "h-[380px] transition-height duration-300  py-[15px] border-b" : "h-0 transition-height duration-300") }, { children: [_jsx("div", __assign({ className: "leading-5 col-span-6" }, { children: props.summaryDetail &&
                            ((_c = props === null || props === void 0 ? void 0 : props.summaryDetail) === null || _c === void 0 ? void 0 : _c.map(function (item, idx) {
                                var xLabel = item.labelTranslation
                                    ? t(item.labelTranslation)
                                    : item.label;
                                return (_jsxs("div", __assign({ className: "text-table-desc grid grid-cols-1" }, { children: [_jsx("div", __assign({ className: "themes-text-gray-v5" }, { children: xLabel })), _jsx("div", { children: props.data.detail[item.accessor] ? props.data.detail[item.accessor] : '-' })] }), "item-detail-".concat(idx)));
                            })) })), _jsxs("div", __assign({ className: "flex col-span-6 mb-3" }, { children: [_jsx("div", { className: "border-l themes-border-gray-v5 h-full mr-3" }), _jsx("div", __assign({ className: "flex flex-col " }, { children: (_e = (_d = props === null || props === void 0 ? void 0 : props.data) === null || _d === void 0 ? void 0 : _d.track) === null || _e === void 0 ? void 0 : _e.map(function (data, idx) {
                                    var _a;
                                    return (_jsxs("div", __assign({ className: "flex items-start relative" }, { children: [_jsxs("div", __assign({ className: "mr-2 flex flex-col items-center" }, { children: [_jsx("div", { className: "rounded-full w-3 h-3 ".concat(idx === 0
                                                            ? "themes-bg-gray-v7"
                                                            : "themes-bg-brand-v1 border-[3px] t", " line-through z-10") }), idx !== ((_a = props.data.track) === null || _a === void 0 ? void 0 : _a.length) - 1 && (_jsx("div", { className: "absolute top-0 bottom-0 w-[2px] themes-bg-gray-v3" }))] })), _jsxs("div", __assign({ className: "text-table-desc mt-[-4px] pb-5" }, { children: [_jsx("div", __assign({ className: "font-bold" }, { children: data.label })), _jsx("div", { children: data.desc }), _jsx("div", { children: data.time })] }))] }), "data-track-".concat(idx)));
                                }) }))] }))] }))] }));
}
