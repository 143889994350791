import { useModal } from '@components//modal/modal.service'
import useOverlay from '@components/overlay/overlay.service'
import { ITabItem } from '@components/tab/tab.interface'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ConfigureColumnsModal from '@components/configure-columns-modal/configure-columns-modal.component'
import {
    IManageUser,
    IManageUserFilter,
    IManageUserStatusTotal,
} from './manage-users.interface'
import { ITableColumn } from '@components/table/table.interface'
import { ManageConfigureColumns } from './manage-users.static'
import { useSelector } from 'react-redux'
import {
    manageUserDataSelector,
    manageUserFilterSelector,
    manageUserMetaSelector,
    manageUserStatusTotalSelector,
    manageUserTabStatusFilterSelector,
    setFilter,
    setManageUserData,
    setPageNumber,
    setSelectedStatus,
} from './manage-users.slice'
import { useAppDispatch } from 'store'
import { getManageUserData } from 'client/ifb/repository/manage-user.repository'

const useManageUsers = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    // overlay
    const filterOverlayService = useOverlay()
    const newBookingOverlayService = useOverlay()

    // modal
    const generateReportModalService = useModal()
    const ConfigureColumnsModalService = useModal()

    // selector
    const manageUserData = useSelector(manageUserDataSelector)
    const totalStatus = useSelector(manageUserStatusTotalSelector)
    const tabFilter = useSelector(manageUserTabStatusFilterSelector)
    const filter = useSelector(manageUserFilterSelector)
    const manageUserDataMeta = useSelector(manageUserMetaSelector)

    // state
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [tabItems, setTabItems] = useState<ITabItem[]>()
    const [headers, setHeaders] = useState<ITableColumn<IManageUser>[]>([])

    // variable
    const { pageNumber, pageSize, status } = filter

    useEffect(() => {
        generateTabItems(totalStatus)
    }, [totalStatus])

    // get data shipments
    useEffect(() => {
        loadData()
    }, [pageNumber, pageSize, status, tabFilter, filter])

    // Get Data Function
    const loadData = async () => {
        const status = tabFilter.value

        let tmpFilter: IManageUserFilter = filter
        if (tabFilter?.childStatus) {
            const inProgressStat = tabFilter?.childStatus[0].value
            tmpFilter = { ...filter, status, inProgressStatus: inProgressStat }
        } else {
            tmpFilter = { ...filter, status }
        }
        try {
            setLoading(true)
            const actionResult = await getManageUserData(tmpFilter)
            dispatch(setManageUserData(actionResult))
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    // generate tab Items
    const generateTabItems = (status: IManageUserStatusTotal) => {
        const tabItems: ITabItem[] = [
            {
                label: 'All Users',
                totalData: status.all,
                key: 'status',
                value: 'all',
                className: 'brand',
            },
            {
                label: 'Active',
                totalData: status.active,
                key: 'status',
                value: 'active',
                className: 'green',
            },
            {
                label: 'Suspended',
                totalData: status.suspended,
                key: 'status',
                value: 'suspended',
                className: 'yellow',
            },
        ]
        setTabItems(tabItems)
    }

    const setPageData = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber))
    }

    const handleSearch = (values: string) => {
        if (values.length >= 3) {
            dispatch(setFilter({ ...filter, shipmentId: values }))
        } else if (values.length <= 0)
            dispatch(setFilter({ ...filter, shipmentId: '' }))
        loadData()
    }

    const setTabFilter = async (data: ITabItem) => {
        dispatch(setSelectedStatus(data))
        dispatch(setPageNumber(1))
    }

    const allModal = (
        <>
            <ConfigureColumnsModal<IManageUser>
                modalService={ConfigureColumnsModalService}
                columns={ManageConfigureColumns}
                setHeaders={setHeaders}
            />
        </>
    )

    return {
        filterOverlayService,
        newBookingOverlayService,
        generateReportModalService,
        tabItems,
        tabFilter,
        loading,
        errorMessage,
        manageUserDataMeta,
        setPageData,
        setLoading,
        setErrorMessage,
        navigate,
        handleSearch,
        setTabItems,
        setTabFilter,
        manageUserData,
        ConfigureColumnsModalService,
        allModal,
        headers,
    }
}

export default useManageUsers
