import { INewsData } from '../dashboard.interface'
import SpinnerTable from '@components/spinner-table/spinner-table.component'
import EmptyResult from '@components/empty-result/empty-result.component'

interface TableOpenPOProps {
    data: INewsData[]
    isLoading: boolean
}

const TableOpenPO: React.FC<TableOpenPOProps> = ({ data, isLoading }) => {
    const loadingMessage = `loading Data . . .`
    const notFoundMessage = `No data found`

    return (
        <>
            {isLoading === true ? (
                <div className="h-full">
                    <SpinnerTable className="w-full" message={loadingMessage} />
                </div>
            ) : data.length <= 0 ? (
                <div className="h-full">
                    <EmptyResult className="w-full" message={notFoundMessage} />
                </div>
            ) : (
                <div className="table-auto w-full h-full">
                    <div className="text-gray-700  h-full overflow-auto">
                        {data.map((item, index) => {
                            return (
                                <div
                                    key={index + item.id}
                                    className="w-full border-b border-gray-200 items-center pt-1 pb-1 grid grid-cols-12"
                                >
                                    <div className="line-clamp-3 col-span-11 text-blue-950 text-base font-normal font-['Basis Grotesque Arabic Pro']  whitespace-nowrap text-ellipsis block text-size-XS">
                                        {item.title}
                                    </div>
                                    <div className="col-span-1 pl-[10px] pr-2 border-l border-gray-300">
                                        <a
                                            href={item.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="ri-arrow-right-s-line" />
                                        </a>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        </>
    )
}

export default TableOpenPO
