import { IButton } from '@components/button/button.interface'
import {
    filterCOInitialValue,
    filterCOValidation,
    IFilterCO,
} from 'client/ifb/form-validation/control-tower.validation'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import {
    averageMonthlyPerformanceSelector,
    barChartOverviewSelector,
    customerChartOrganisationDataSelector,
    customerTableDataSelector,
    dataOverviewTable1Selector,
    dataOverviewTable2Selector,
    dataTradeLanesTableSelector,
    financePieChartSelector,
    financeTableDataSelector,
    historyDataSelector,
    joinChartOverviewSelector,
    pieChartTradeLanesSelector,
    setAverageMonthlyPerformance,
    setHistoryData,
} from './control-tower.slice'
import { useEffect, useState } from 'react'
import { ITabItem } from '@components/tab/tab.interface'
import {
    tabItemFilterCustomers,
    tabItemFilterFinance,
    tabItemFilterOverview,
    tabItemFilterTradeLanes,
} from './control-tower.static'
import { faker } from '@faker-js/faker'
import {
    IButtonContentItems,
    ILoadingFinance,
    ILoadingHistory,
    IUseControlTower,
} from './control-tower.interface'
import moment from 'moment'

const useControlTower = (): IUseControlTower => {
    const dispatch = useDispatch()

    // selector common
    const historyData = useSelector(historyDataSelector)

    // selector overview
    const dataOverviewTable1 = useSelector(dataOverviewTable1Selector)
    const dataOverviewTable2 = useSelector(dataOverviewTable2Selector)
    const joinChartOverview = useSelector(joinChartOverviewSelector)
    const barChartOverview = useSelector(barChartOverviewSelector)

    // selector trade lanes
    const pieChartTradeLanes = useSelector(pieChartTradeLanesSelector)
    const dataTradeLanesTable = useSelector(dataTradeLanesTableSelector)

    // selector trade lanes
    const customerChartOrganisationData = useSelector(
        customerChartOrganisationDataSelector,
    )
    const customerTableData = useSelector(customerTableDataSelector)

    // selector finance
    const financeTableData = useSelector(financeTableDataSelector)
    const financePieChart = useSelector(financePieChartSelector)
    const averageMonthlyPerformance = useSelector(
        averageMonthlyPerformanceSelector,
    )

    // state common
    const [buttonContent, setButtonContent] =
        useState<IButtonContentItems>('overview')
    const [loadingHistory, setLoadingHistory] = useState<ILoadingHistory>({
        development: false,
        splitRecord: false,
    })
    const [isInitialRender, setIsInitialRender] = useState(true)
    // state overview
    const [tabFilterOverview, setTabFilterOverview] = useState<ITabItem>(
        tabItemFilterOverview[0],
    )
    // state trade lanes
    const [tabFilterTradeLanes, setTabFilterTradeLanes] = useState<ITabItem>(
        tabItemFilterTradeLanes[0],
    )
    // state customers
    const [tabFilterCustomers, setTabFilterCustomers] = useState<ITabItem>(
        tabItemFilterCustomers[0],
    )
    // state finance
    const [tabFilterFinance, setTabFilterFinance] = useState<ITabItem>(
        tabItemFilterFinance[0],
    )
    const [loadingFinance, setLoadingFinance] = useState<ILoadingFinance>({
        averageMonthlyPerformance: true,
    })

    // formik
    const formikFilterCO = useFormik<IFilterCO>({
        validationSchema: filterCOValidation,
        initialValues: filterCOInitialValue,
        onSubmit: async () => {},
    })

    // fucntion & variable common
    const buttonContentCO: IButton[] = [
        {
            onClick: () => setButtonContent('overview'),
            label: 'OVERVIEW',
            icon: 'ri-pages-line',
            className: 'col-span-6',
        },
        {
            onClick: () => setButtonContent('tradeLanes'),
            label: 'trade lanes',
            icon: 'ri-share-line',
            useUpperCase: true,
            className: 'col-span-6',
        },

        {
            onClick: () => setButtonContent('customers'),
            label: 'customers',
            icon: 'ri-user-heart-line',
            useUpperCase: true,
            className: 'col-span-4',
        },
        {
            onClick: () => setButtonContent('sales'),
            label: 'sales',
            icon: 'ri-money-cny-box-line',
            useUpperCase: true,
            className: 'col-span-4',
        },

        {
            onClick: () => setButtonContent('finance'),
            label: 'finance',
            icon: 'ri-book-2-line',
            useUpperCase: true,
            className: 'col-span-4',
        },
    ]
    const buttonFilterCO1: IButton[] = [
        {
            onClick: () => {},
            label: 'SHPs',
        },
        {
            onClick: () => {},
            label: 'REV',
        },
        {
            onClick: () => {},
            label: 'GP',
        },
        {
            onClick: () => {},
            label: 'VOL',
        },
    ]
    const buttonFilterCO2: IButton[] = [
        {
            onClick: () => {},
            label: 'IMP',
        },
        {
            onClick: () => {},
            label: 'EXP',
        },
        {
            onClick: () => {},
            label: 'CROSS-TRADE',
        },
    ]
    const loadHistoryData = () => {
        setLoadingHistory({
            development: true,
            splitRecord: true,
        })

        const start = moment(formikFilterCO.values.startMonth, 'MM/YYYY')
        const end = moment(formikFilterCO.values.endMonth, 'MM/YYYY')

        const rangeMonth: string[] = []
        const line1 = []
        const line2 = []
        let index = 1

        while (start.isSameOrBefore(end, 'month')) {
            rangeMonth.push(start.format('MMM'))
            line1.push({
                x: index,
                secName: start.format('YYYY'),
                y: faker.number.int({ min: 0, max: 10 }),
            })
            line2.push({
                x: index,
                secName: start.format('YYYY'),
                y: faker.number.int({ min: 0, max: 10 }),
            })

            start.add(1, 'month')
            index++
        }

        const barChartSplitRecord = [
            {
                name: 'This Period',
                f1: faker.number.int({ min: 10, max: 30 }),
                f2: faker.number.int({ min: 10, max: 30 }),
                amt: 10,
            },
            {
                name: 'Last Period',
                f1: faker.number.int({ min: 10, max: 30 }),
                f2: faker.number.int({ min: 10, max: 30 }),
                amt: 10,
            },
        ]

        dispatch(
            setHistoryData({
                development: { line1, line2, tickItems: rangeMonth },
                splitRecord: barChartSplitRecord,
            }),
        )
        setTimeout(() => {
            setLoadingHistory({
                development: false,
                splitRecord: false,
            })
        }, 1000)
    }
    const wrapFetchDataContent = () => {
        if (formikFilterCO.errors.endMonth || formikFilterCO.errors.startMonth)
            return
        loadHistoryData()
        if (buttonContent === 'finance') loadAverageMonthlyPerformanceData()
    }

    // fucntion & variable finance
    const loadAverageMonthlyPerformanceData = () => {
        setLoadingFinance({
            averageMonthlyPerformance: true,
        })

        const start = moment(formikFilterCO.values.startMonth, 'MM/YYYY')
        const end = moment(formikFilterCO.values.endMonth, 'MM/YYYY')

        const rangeMonth: string[] = []
        const line1 = []
        const line2 = []
        const line3 = []
        let index = 1

        while (start.isSameOrBefore(end, 'month')) {
            rangeMonth.push(start.format('MMM'))
            line1.push({
                x: index,
                secName: start.format('YYYY'),
                y: faker.number.int({ min: 0, max: 10 }),
            })
            line2.push({
                x: index,
                secName: start.format('YYYY'),
                y: faker.number.int({ min: 0, max: 10 }),
            })
            line3.push({
                x: index,
                secName: start.format('YYYY'),
                y: faker.number.int({ min: 0, max: 10 }),
            })

            start.add(1, 'month')
            index++
        }

        dispatch(
            setAverageMonthlyPerformance({
                line1,
                line2,
                line3,
                tickItems: rangeMonth,
            }),
        )
        setTimeout(() => {
            setLoadingFinance({
                averageMonthlyPerformance: false,
            })
        }, 1000)
    }

    // useEffects
    useEffect(() => {
        formikFilterCO.submitForm()
    }, [])
    useEffect(() => {
        const handler = setTimeout(() => {
            setIsInitialRender(false)
            wrapFetchDataContent()
        }, 1000)
        return () => clearTimeout(handler)
    }, [formikFilterCO.values, formikFilterCO.errors])
    useEffect(() => {
        if (!isInitialRender) {
            wrapFetchDataContent()
        }
        wrapFetchDataContent()
    }, [buttonContent])

    return {
        // common
        formikFilterCO,
        buttonContentCO,
        buttonFilterCO1,
        buttonFilterCO2,
        buttonContent,
        historyData,
        loadHistoryData,
        loadingHistory,

        // overview
        dataOverviewTable1,
        dataOverviewTable2,
        tabFilterOverview,
        setTabFilterOverview,
        joinChartOverview,
        barChartOverview,

        // trade lanes
        pieChartTradeLanes,
        tabFilterTradeLanes,
        setTabFilterTradeLanes,
        dataTradeLanesTable,

        // customers
        tabFilterCustomers,
        setTabFilterCustomers,
        customerChartOrganisationData,
        customerTableData,

        // finance
        loadingFinance,
        financeTableData,
        tabFilterFinance,
        setTabFilterFinance,
        financePieChart,
        averageMonthlyPerformance,
    }
}

export default useControlTower
