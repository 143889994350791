import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMeta, IResponseData } from 'common/common.interface'
import { ITabItem } from '@components/tab/tab.interface'
import {
    IShipment,
    IShipmentFilter,
    IShipmentStatusTotal,
    IShipmentDetails,
} from 'client/ifb/repository/interface/shipment.interface'
import { RootState } from 'store'
import { intialValueShipmentDetails } from './shipment-details/shipment-details.static'
import { formatDate } from 'common/common.service'
import { empityMetaDummy } from './shipments.dummy'

interface IShipmentsSlice {
    data: IShipment[]
    meta: IMeta
    responseMessage: string
    filter: IShipmentFilter
    statusSelected: ITabItem
    statusTotal: IShipmentStatusTotal

    // segment details
    detailsData: IShipmentDetails
}

export const initialState: IShipmentsSlice = {
    data: [],
    meta: {
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total_page: 0,
        total_Items: 0,
        from: 0,
        to: 0,
        index_end: 0,
        index_start: 0,
    },
    responseMessage: '',
    filter: {
        status: '',
        inProgressStatus: '',
        pageNumber: 1,
        pageSize: 50,
    },
    statusTotal: {
        all: 0,
        arrived: 0,
        inTransit: 0,
        delayed: 0,
        noStatus: 0,
    },
    statusSelected: {
        value: 'all',
        key: 'status',
        label: 'All Shipment',
        totalData: 0,
    },

    // segment details
    detailsData: intialValueShipmentDetails,
}

const shipmentsSlice = createSlice({
    name: 'shipmentsIFB',
    initialState,
    reducers: {
        setShipmentData(
            state,
            action: PayloadAction<IResponseData<IShipment[]>>,
        ) {
            const { data, isSuccess, message, meta, links } = action.payload

            let setFormatData: IShipment[] = []
            let setMeta: IMeta
            // change this logic when the status summary is clear
            if (state.statusSelected.value === 'all') {
                // set total status
                setFormatData = data.map((item) => ({
                    ...item,
                    datesETDShipment: item?.datesETDShipment
                        ? formatDate(item?.datesETDShipment)
                        : '',
                    datesETAShipment: item?.datesETAShipment
                        ? formatDate(item?.datesETAShipment)
                        : '',
                }))
                setMeta = meta
            } else {
                setFormatData = []
                setMeta = empityMetaDummy
            }

            const d = {
                data: setFormatData,
                isSuccess,
                message,
                meta: setMeta,
                links,
                statusTotal: {
                    ...state.statusTotal,
                    all: meta.total_Items || 0,
                },
            }

            return {
                ...state,
                ...d,
            }
        },
        setSelectedStatus(state, action: PayloadAction<ITabItem>) {
            const statusSelected = action.payload

            return {
                ...state,
                statusSelected,
            }
        },
        setFilter(state, action: PayloadAction<IShipmentFilter>) {
            const filter = action.payload
            return {
                ...state,
                filter,
            }
        },
        setPageNumber(state, action: PayloadAction<number>) {
            const pageNumber = action.payload
            const filter = { ...state.filter, pageNumber }
            return {
                ...state,
                filter,
            }
        },

        // segment details
        setShipmentDetailsData(
            state,
            action: PayloadAction<IResponseData<IShipmentDetails>>,
        ) {
            const detailsData = action.payload.data
            detailsData.destination.etaShipment = detailsData.destination
                .etaShipment
                ? formatDate(detailsData.destination.etaShipment)
                : ''
            detailsData.origin.etdShipment = detailsData.origin.etdShipment
                ? formatDate(detailsData.origin.etdShipment)
                : ''

            state.detailsData = action.payload.data
        },
    },
})

// these all the variables used for selector
export const shipmentsDataSelector = (state: RootState) =>
    state.shipmentIFB.data || {}
export const filterSelector = (state: RootState) =>
    state.shipmentIFB.filter || {}
export const shipmentMetaSelector = (state: RootState) =>
    state.shipmentIFB.meta || {}
export const shipmentsFilterSelector = (state: RootState) =>
    state.shipmentIFB.filter || {}
export const shipmentsStatusTotalSelector = (state: RootState) =>
    state.shipmentIFB.statusTotal || {}
export const tabStatusFilterSelector = (state: RootState) =>
    state.shipmentIFB.statusSelected || {}

// segment details
export const shipmentDetailsDataSelector = (state: RootState) =>
    state.shipmentIFB.detailsData || {}

// all actions
export const {
    setShipmentData,
    setSelectedStatus,
    setPageNumber,
    setFilter,
    setShipmentDetailsData,
} = shipmentsSlice.actions

// Reducer
export default shipmentsSlice.reducer
