/* eslint-disable no-unused-vars */
import { ITabItem } from '@components/tab/tab.interface'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { tabDetailHeaders } from './shipment-details.static'
import Details from './contents/details'
import PackLines from './contents/packLines'
// import Orders from './contents/orders'
// import RelatedInvoices from './contents/related-invoices'
// import Milestones from 'client/ifb/pages/bookings/booking-details/contents/milestones.components'
// import Events from 'client/ifb/pages/bookings/booking-details/contents/events.components'
// import Notes from 'client/ifb/pages/bookings/booking-details/contents/notes.component'
// import Messages from 'client/ifb/pages/bookings/booking-details/contents/messages.component'
// import EDocs from 'client/ifb/pages/bookings/booking-details/contents/e-docs.component'
// import Documents from 'client/ifb/pages/bookings/booking-details/contents/documents.component'
import { useAppDispatch } from 'store'
import { useSelector } from 'react-redux'
import {
    setShipmentDetailsData,
    shipmentDetailsDataSelector,
} from '../shipments.slice'
import { Toast } from '@components/toast/toast.component'
import { getShipmentDetails } from 'client/ifb/repository/shipment.repository'
import RelatedParties from './contents/related-parties'
import Tracking from './contents/tracking'
import { ITrackingSumary } from '../../trackings/trackings.interface'
import { formatDate, formatDateTime } from 'common/common.service'
import moment from 'moment'

const useShipmentDetails = () => {
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    // selectors
    const shipmentDetailsData = useSelector(shipmentDetailsDataSelector)

    // states
    const [tabFilter, setTabFilter] = useState<ITabItem>(tabDetailHeaders[0])
    const [loadingDetailData, setLoadingDetailData] = useState(true)

    // get data detail
    const loadDetailData = async () => {
        if (!id || id === '') {
            return
        }

        try {
            setLoadingDetailData(true)
            const actionResult = await getShipmentDetails(id)
            dispatch(setShipmentDetailsData(actionResult))
            setLoadingDetailData(false)
        } catch (e: any) {
            console.log(e)
            setLoadingDetailData(false)
            const errorMessage = e.message
            Toast({
                header: 'Failed Get Detail Shipments',
                message: errorMessage,
                type: 'error',
            })
            setTimeout(() => {
                navigate('/shipments')
            }, 100)
        }
    }

    const setDataTracking: ITrackingSumary[] =
        shipmentDetailsData?.shipmentTracking?.map((d) => ({
            idShipment: d?.containerNo,
            initialCountryCode: d?.portOfLoadingCode,
            initialCountryName: d?.portOfLoadingDesc,
            destinationCountryCode: d?.portOfDischargeCode,
            destinationCountryName: d?.portOfDischargeDesc,
            ETD: d?.containerETD
                ? moment(d.containerETD).format('DD MMM YY')
                : '',
            ETA: d?.containerETA
                ? moment(d.containerETA).format('DD MMM YY')
                : '',
            detail: {
                containerType: d?.containerType,
                containerMode: d?.containerMode,
                deliveryMode: d?.deliveryMode,
                relatedShipments: d?.relatedShipmentNumbers,
                consoleNo: d?.consolNo,
                sealNo: d?.sealNo,
                weight: d?.weight,
                vessel: d?.vesselName,
                voyage: d?.voyageNo,
                emptyReturnedOn: d?.emptyReturnedOn
                    ? formatDate(d.emptyReturnedOn)
                    : '',
            },
            track:
                d?.trackingHistoryMilestone?.map((t) => ({
                    label: t?.eventCode || '',
                    desc: t?.description || '',
                    time:
                        t?.estimatedDate !== null
                            ? formatDateTime(t.estimatedDate)
                            : '',
                })) || [],
            status: { color: 'green', text: 'IN TRANSIT' },
        }))

    // functions
    const getContent = () => {
        const contents: Record<string, JSX.Element> = {
            details: (
                <Details
                    data={shipmentDetailsData}
                    laoding={loadingDetailData}
                    shipmentId={id}
                />
            ),
            packLines: <PackLines data={shipmentDetailsData.packLines} />,
            // orders: <Orders />,
            // relatedInvoices: <RelatedInvoices />,
            // documents: <Documents />,
            // eDocs: <EDocs />,
            // messages: <Messages />,
            // notes: <Notes />,
            // milestones: <Milestones />,
            // events: <Events />,
            tracking: <Tracking data={setDataTracking} />,
            relatedParties: (
                <RelatedParties
                    consignorPickupData={
                        shipmentDetailsData?.consignorPickupAddress
                    }
                    consigneePickupData={
                        shipmentDetailsData?.consigneePickupAddress
                    }
                    consignorDocumentaryAddress={
                        shipmentDetailsData?.consignorDocumentaryAddress
                    }
                    consigneeDocumentaryAddress={
                        shipmentDetailsData?.consigneeDocumentaryAddress
                    }
                />
            ),
        }
        return contents[tabFilter.value]
    }

    // Use Effect ----------------------------------------------
    useEffect(() => {
        if (!id) return
        loadDetailData()
    }, [id])

    return {
        navigate,
        getContent,
        setTabFilter,
        shipmentDetailsData,
        tabFilter,
    }
}

export default useShipmentDetails
