import SimpleTable from '@components/simple-table/simple-table.component'
import { shipmentPackLineHeaders } from '../../shipments.static'
// import { metaDummy } from '../../shipments.dummy'
import { IShipmentPackLine } from 'client/ifb/repository/interface/shipment.interface'

const PackLines = ({ data }: { data: IShipmentPackLine[] }) => {
    return (
        <SimpleTable<IShipmentPackLine>
            headers={shipmentPackLineHeaders}
            data={data}
            // comment untuk sementara
            // usePagination
            // nextHandling={(page) => {
            //     console.log('hit Next API -> ' + page)
            // }}
            // previousHandling={(page) => {
            //     console.log('hit Prev API -> ' + page)
            // }}
            // meta={metaDummy}
        />
    )
}

export default PackLines
