import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    fetchBookingStatus,
    fetchContainerModeStatus,
    fetchNewsData,
    fetchScfiData,
    fetchShipmenteStatus,
    fetchShipmenteStatus2,
    fetchShipmentsData,
    fetchtDropdownDashboard,
} from 'client/ifb/repository/dashboard.repository'
import {
    barContainerSelector,
    barInvoiceSelector,
    dropdownTransModeSelector,
    filterShipmentDataSelector,
    itemDonatSelector,
    itemInvoiceSelector,
    itemInvoiceUnPaidSelector,
    itemSelector,
    newsSelector,
    setBookingStatus,
    setContainermodeStatus,
    setDashboardDropdown,
    setNews,
    setPageNumber,
    setSelectedStatus,
    setShipmentStatus,
    setShipmentStatus2,
    setTrackingShipmentLocation,
    statusContainerModeSelector,
    tabStatusFilterSelector,
    trackingShipmentLocationeSelector,
} from './dashboard.slice'
import { useFormik } from 'formik'
import {
    IDashboardFilterValidation,
    useDashboardFilterValidation,
} from 'client/ifb/form-validation/dashboard.validation'
import { IInfoCardProps } from './dashboard.interface'
import { metaDataDropdown } from '../bookings/bookings.interface'
import { ITabItem } from '@components/tab/tab.interface'

const useDashboard = () => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState({
        shipmentStatus: true,
        shipmentStatus2: true,
        bookingStatus: true,
        containerModeStatus: true,
        shipmentData: true,
    })
    const [isLoadingNews, setIsLoadingNews] = useState(false)
    const dataItem = useSelector(itemSelector)
    const filterShipmentData = useSelector(filterShipmentDataSelector)
    const donatData = useSelector(itemDonatSelector)
    const barInvoiceData = useSelector(barInvoiceSelector)
    const barContainerData = useSelector(barContainerSelector)
    const itemInvoiceData = useSelector(itemInvoiceSelector)
    const itemInvoiceDataUnPaid = useSelector(itemInvoiceUnPaidSelector)
    const statusContainerMode = useSelector(statusContainerModeSelector)
    const news = useSelector(newsSelector)
    const transModeData = useSelector(dropdownTransModeSelector)
    const trackingShipmentLocatione = useSelector(
        trackingShipmentLocationeSelector,
    )
    const tabFilter = useSelector(tabStatusFilterSelector)
    const [dataItems, setDataItems] = useState<IInfoCardProps[]>([])
    const { pageNumber, pageSize, status, search } = filterShipmentData

    const { dashboardFilterValidation, dashboardFilterInitialValue } =
        useDashboardFilterValidation()

    const formikDashboard = useFormik<IDashboardFilterValidation>({
        validationSchema: dashboardFilterValidation,
        initialValues: dashboardFilterInitialValue,
        onSubmit: async () => {},
    })

    const fetchData = async () => {
        try {
            const shipmentStatusPromise = fetchShipmenteStatus(
                formikDashboard.values,
            )
                .then((shipmentStatus) => {
                    dispatch(setShipmentStatus(shipmentStatus.data))
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        shipmentStatus: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching Shipment Status data:', error)
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        shipmentStatus: false,
                    }))
                })

            const shipmentStatusPromise2 = fetchShipmenteStatus2(
                formikDashboard.values,
            )
                .then((shipmentStatus2) => {
                    if (shipmentStatus2.isSuccess) {
                        dispatch(setShipmentStatus2(shipmentStatus2.data))
                    }
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        shipmentStatus2: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching Shipment Status data:', error)
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        shipmentStatus: false,
                    }))
                })

            const bookingStatusPromise = fetchBookingStatus(
                formikDashboard.values,
            )
                .then((bookingStatus) => {
                    dispatch(setBookingStatus(bookingStatus.data))
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        bookingStatus: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching Shipment Status data:', error)
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        bookingStatus: false,
                    }))
                })

            const containerModeStatusPromise = fetchContainerModeStatus(
                formikDashboard.values,
            )
                .then((containerModeStatus) => {
                    dispatch(setContainermodeStatus(containerModeStatus.data))
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        containerModeStatus: false,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching Shipment Status data:', error)
                    setIsLoading((prevLoading) => ({
                        ...prevLoading,
                        containerModeStatus: false,
                    }))
                })
            await Promise.allSettled([
                shipmentStatusPromise,
                shipmentStatusPromise2,
                bookingStatusPromise,
                containerModeStatusPromise,
            ])
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    const fetchDataOnlyOnce = async () => {
        try {
            const [dataNews, dataDropdown] = await Promise.all([
                fetchNewsData(),
                fetchtDropdownDashboard(),
            ])
            dispatch(setNews(dataNews))
            dispatch(setDashboardDropdown(dataDropdown as metaDataDropdown))
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setIsLoadingNews(false)
        }
    }

    // Get Data Function
    const loadDataShipment = async () => {
        let tmpFilter = filterShipmentData
        if (tabFilter.value) {
            const inProgressStat = tabFilter.value
            tmpFilter = {
                ...filterShipmentData,
                status,
                inProgressStatus: inProgressStat,
            }
        } else {
            tmpFilter = { ...filterShipmentData, status }
        }
        const filterData = {
            ...tmpFilter,
            ...formikDashboard.values,
        }
        try {
            setIsLoading((prevLoading) => ({
                ...prevLoading,
                shipmentData: true,
            }))
            const actionResult = await fetchShipmentsData(filterData)
            dispatch(setTrackingShipmentLocation(actionResult))
            setIsLoading((prevLoading) => ({
                ...prevLoading,
                shipmentData: false,
            }))
        } catch (e) {
            setIsLoading((prevLoading) => ({
                ...prevLoading,
                shipmentData: false,
            }))
            console.error('Error fetching data:', e)
        }
    }

    const setPageData = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber))
    }

    const setTabFilter = async (data: ITabItem) => {
        dispatch(setSelectedStatus(data))
        dispatch(setPageNumber(1))
    }

    useEffect(() => {
        setIsLoadingNews(true)
        fetchDataOnlyOnce()
    }, [])

    useEffect(() => {
        loadDataShipment()
    }, [
        pageNumber,
        pageSize,
        status,
        search,
        filterShipmentData,
        formikDashboard.values,
    ])

    useEffect(() => {
        const handler = setTimeout(() => {
            setIsLoading({
                shipmentStatus: true,
                shipmentStatus2: true,
                bookingStatus: true,
                containerModeStatus: true,
                shipmentData: true,
            })
            fetchData()
        }, 1000)
        return () => clearTimeout(handler)
    }, [formikDashboard.values])

    useEffect(() => {
        if (dataItem) {
            const formatingData = [
                {
                    icon: 'ri-home-3-line',
                    total: dataItem.quote.total,
                    title: 'Quotes',
                    items: [
                        { label: 'Approved', value: dataItem.quote.approved },
                        { label: 'Pending', value: dataItem.quote.pending },
                        { label: 'Delayed', value: dataItem.quote.delayed },
                        {
                            label: 'New Quotes',
                            value: dataItem.quote.newQuotes,
                        },
                    ],
                },
                {
                    icon: 'ri-git-repository-line',
                    total: dataItem.bookings.TotalBooking,
                    title: 'Bookings',
                    items: [
                        { label: 'Booked', value: dataItem.bookings.Booked },
                        {
                            label: 'Confirmed',
                            value: dataItem.bookings.Confirmed,
                        },
                        {
                            label: 'Cancelled',
                            value: dataItem.bookings.Cancelled,
                        },
                        {
                            label: 'New Bookings',
                            value: dataItem.bookings.NewBookings,
                        },
                    ],
                },
                {
                    icon: 'ri-ship-line',
                    total: dataItem.shipment2.totalStatus,
                    title: 'Shipments',
                    items: [
                        {
                            label: 'Delayed',
                            value: dataItem.shipment2.delayed,
                        },
                        {
                            label: 'Completed',
                            value: dataItem.shipment2.completed,
                        },
                        {
                            label: 'CompletedLate',
                            value: dataItem.shipment2.completedLate,
                        },
                    ],
                },
                {
                    icon: 'ri-box-3-line',
                    total: dataItem.warehouse.total,
                    title: 'Warehouse',
                    items: [
                        {
                            label: 'In stock',
                            value: dataItem.warehouse.inStock,
                        },
                        {
                            label: 'Low stock',
                            value: dataItem.warehouse.lowStock,
                        },
                        {
                            label: 'Out of Stock',
                            value: dataItem.warehouse.outOfStock,
                        },
                        {
                            label: 'New Orders',
                            value: dataItem.warehouse.newOrders,
                        },
                    ],
                },
                {
                    icon: 'ri-leaf-line',
                    total: dataItem.co2Emissions.total,
                    title: 'CO2 Emissions',
                    items: [
                        { label: 'SEA', value: dataItem.co2Emissions.sea },
                        { label: 'AIR', value: dataItem.co2Emissions.air },
                        { label: 'ROAD', value: dataItem.co2Emissions.road },
                        { label: 'RAIL', value: dataItem.co2Emissions.rail },
                    ],
                },
            ]
            setDataItems(formatingData)
        }
    }, [dataItem])

    return {
        news,
        isLoadingNews,
        transModeData,
        barContainerData,
        barInvoiceData,
        donatData,
        fetchNewsData,
        fetchScfiData,
        isLoading,
        formikDashboard,
        itemInvoiceData,
        itemInvoiceDataUnPaid,
        dataItems,
        statusContainerMode,
        trackingShipmentLocatione,
        setPageData,
        tabFilter,
        setTabFilter,
    }
}

export default useDashboard
