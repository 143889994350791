import { faker } from '@faker-js/faker'
import {
    IShipmentDeliveryData,
    IShipmentServiceTypeData,
} from './performances.interface'

export const dataTransitTime = {
    barColor1: '#D9DDE1',
    barColor2: '#8D99A5',
    item: [
        {
            name: 'AUMEL',
            uv: faker.number.int({ min: 10, max: 100 }),
            pv: faker.number.int({ min: 10, max: 100 }),
            amt: 200,
        },
        {
            name: 'IDBTM',
            uv: faker.number.int({ min: 10, max: 100 }),
            pv: faker.number.int({ min: 10, max: 100 }),
            amt: 200,
        },
        {
            name: 'SGSIN',
            uv: faker.number.int({ min: 10, max: 100 }),
            pv: faker.number.int({ min: 10, max: 100 }),
            amt: 200,
        },
        {
            name: 'TWTPE',
            uv: faker.number.int({ min: 10, max: 100 }),
            pv: faker.number.int({ min: 10, max: 100 }),
            amt: 200,
        },
        {
            name: 'THBKK',
            uv: faker.number.int({ min: 10, max: 100 }),
            pv: faker.number.int({ min: 10, max: 100 }),
            amt: 200,
        },
        {
            name: 'JPKYT',
            uv: faker.number.int({ min: 10, max: 100 }),
            pv: faker.number.int({ min: 10, max: 100 }),
            amt: 200,
        },
        {
            name: 'JPOSA',
            uv: faker.number.int({ min: 10, max: 100 }),
            pv: faker.number.int({ min: 10, max: 100 }),
            amt: 200,
        },
        {
            name: 'JPFJS',
            uv: faker.number.int({ min: 10, max: 100 }),
            pv: faker.number.int({ min: 10, max: 100 }),
            amt: 200,
        },
        {
            name: 'USDMK',
            uv: faker.number.int({ min: 10, max: 100 }),
            pv: faker.number.int({ min: 10, max: 100 }),
            amt: 200,
        },
        {
            name: 'MYJHB',
            uv: faker.number.int({ min: 10, max: 100 }),
            pv: faker.number.int({ min: 10, max: 100 }),
            amt: 200,
        },
        {
            name: 'AUSYD',
            uv: faker.number.int({ min: 10, max: 100 }),
            pv: faker.number.int({ min: 10, max: 100 }),
            amt: 200,
        },
        {
            name: 'CNNBO',
            uv: faker.number.int({ min: 10, max: 100 }),
            pv: faker.number.int({ min: 10, max: 100 }),
            amt: 200,
        },
    ],
}

export const shipmentDelivery: IShipmentDeliveryData[] = [
    {
        transportMode: 'sea',
        containerMode: 'FCL',
        totalShipments: 158,
        loadCountryCode: 'SGSIN',
        dischargeCountryCode: 'AUMEL',
        totalDays: 58,
        extraDay: 2,
        goodsDeliveryStage: [
            {
                accessor: 'loading',
                value: 20,
                bgColor: '#E3CD9A',
            },
            {
                accessor: 'transitTime',
                value: 20,
                bgColor: '#5280C6',
            },
            {
                accessor: 'discharge',
                value: 20,
                bgColor: '#C0C7CD',
            },
        ],
    },
    {
        transportMode: 'sea',
        containerMode: 'FCL',
        totalShipments: 120,
        loadCountryCode: 'USLAX',
        dischargeCountryCode: 'JPNTY',
        totalDays: 45,
        extraDay: 3,
        goodsDeliveryStage: [
            {
                accessor: 'loading',
                value: 10,
                bgColor: '#E3CD9A',
            },
            {
                accessor: 'transitTime',
                value: 15,
                bgColor: '#5280C6',
            },
            {
                accessor: 'discharge',
                value: 10,
                bgColor: '#C0C7CD',
            },
        ],
    },
    {
        transportMode: 'sea',
        containerMode: 'FCL',
        totalShipments: 200,
        loadCountryCode: 'DEHAM',
        dischargeCountryCode: 'FRPAR',
        totalDays: 30,
        extraDay: 1,
        goodsDeliveryStage: [
            {
                accessor: 'loading',
                value: 10,
                bgColor: '#E3CD9A',
            },
            {
                accessor: 'transitTime',
                value: 5,
                bgColor: '#5280C6',
            },
            {
                accessor: 'discharge',
                value: 10,
                bgColor: '#C0C7CD',
            },
        ],
    },
    {
        transportMode: 'sea',
        containerMode: 'FCL',
        totalShipments: 180,
        loadCountryCode: 'CNSHA',
        dischargeCountryCode: 'RUULI',
        totalDays: 40,
        extraDay: 2,
        goodsDeliveryStage: [
            {
                accessor: 'loading',
                value: 12,
                bgColor: '#E3CD9A',
            },
            {
                accessor: 'transitTime',
                value: 8,
                bgColor: '#5280C6',
            },
            {
                accessor: 'discharge',
                value: 15,
                bgColor: '#C0C7CD',
            },
        ],
    },
    {
        transportMode: 'sea',
        containerMode: 'FCL',
        totalShipments: 140,
        loadCountryCode: 'GBLIV',
        dischargeCountryCode: 'NGLOS',
        totalDays: 50,
        extraDay: 1,
        goodsDeliveryStage: [
            {
                accessor: 'loading',
                value: 18,
                bgColor: '#E3CD9A',
            },
            {
                accessor: 'transitTime',
                value: 7,
                bgColor: '#5280C6',
            },
            {
                accessor: 'discharge',
                value: 20,
                bgColor: '#C0C7CD',
            },
        ],
    },
]

export const transitShipmentDelivery: IShipmentDeliveryData[] = [
    {
        transportMode: 'sea',
        containerMode: 'FCL',
        totalShipments: 158,
        loadCountryCode: 'SGSIN',
        dischargeCountryCode: 'AUMEL',
        totalDays: 58,
        extraDay: 2,
        goodsDeliveryStage: [
            {
                accessor: 'cartagePickup',
                value: 50,
                bgColor: 'themes-bg-gray-v5',
            },
        ],
    },
    {
        transportMode: 'sea',
        containerMode: 'FCL',
        totalShipments: 120,
        loadCountryCode: 'USLAX',
        dischargeCountryCode: 'JPNTY',
        totalDays: 45,
        extraDay: 3,
        goodsDeliveryStage: [
            {
                accessor: 'cartagePickup',
                value: 45,
                bgColor: 'themes-bg-gray-v5',
            },
        ],
    },
    {
        transportMode: 'sea',
        containerMode: 'FCL',
        totalShipments: 200,
        loadCountryCode: 'DEHAM',
        dischargeCountryCode: 'FRPAR',
        totalDays: 30,
        extraDay: 1,
        goodsDeliveryStage: [
            {
                accessor: 'cartagePickup',
                value: 20,
                bgColor: 'themes-bg-gray-v5',
            },
        ],
    },
    {
        transportMode: 'sea',
        containerMode: 'FCL',
        totalShipments: 180,
        loadCountryCode: 'CNSHA',
        dischargeCountryCode: 'RUULI',
        totalDays: 40,
        extraDay: 2,
        goodsDeliveryStage: [
            {
                accessor: 'cartagePickup',
                value: 22,
                bgColor: 'themes-bg-gray-v5',
            },
        ],
    },
    {
        transportMode: 'sea',
        containerMode: 'FCL',
        totalShipments: 140,
        loadCountryCode: 'GBLIV',
        dischargeCountryCode: 'NGLOS',
        totalDays: 50,
        extraDay: 1,
        goodsDeliveryStage: [
            {
                accessor: 'cartagePickup',
                value: 38,
                bgColor: 'themes-bg-gray-v5',
            },
        ],
    },
]

export const shipmentServiceTypeDummy: IShipmentServiceTypeData[] = [
    {
        transportMode: 'sea',
        loadCountryCode: 'SGSIN',
        loadCountryName: 'Singapore',
        dischargeCountryCode: 'AUMEL',
        dischargeCountryName: 'Melbourne',
        shipmentRecord: 158,
        actualTransitTime: 20,
        extraTransitTime: 25,
        serviceType: 'doorToDoor',
    },
    {
        transportMode: 'sea',
        loadCountryCode: 'SGSIN',
        loadCountryName: 'Singapore',
        dischargeCountryCode: 'AUMEL',
        dischargeCountryName: 'Melbourne',
        shipmentRecord: 158,
        actualTransitTime: 20,
        extraTransitTime: 25,
        serviceType: 'doorToDoor',
    },
    {
        transportMode: 'sea',
        loadCountryCode: 'SGSIN',
        loadCountryName: 'Singapore',
        dischargeCountryCode: 'AUMEL',
        dischargeCountryName: 'Melbourne',
        shipmentRecord: 158,
        actualTransitTime: 20,
        extraTransitTime: 25,
        serviceType: 'doorToDoor',
    },
    {
        transportMode: 'sea',
        loadCountryCode: 'SGSIN',
        loadCountryName: 'Singapore',
        dischargeCountryCode: 'AUMEL',
        dischargeCountryName: 'Melbourne',
        shipmentRecord: 158,
        actualTransitTime: 20,
        extraTransitTime: 25,
        serviceType: 'doorToDoor',
    },
    {
        transportMode: 'sea',
        loadCountryCode: 'SGSIN',
        loadCountryName: 'Singapore',
        dischargeCountryCode: 'AUMEL',
        dischargeCountryName: 'Melbourne',
        shipmentRecord: 158,
        actualTransitTime: 20,
        extraTransitTime: 25,
        serviceType: 'doorToDoor',
    },
]
