import SimpleTable from '@components/simple-table/simple-table.component'
import { metaDummy } from '../booking-details.dummy'
import { packLineHeadersDetails } from '../booking-details.static'
import { IBookingsPackLinesTable } from '../booking-details.interface'
import { IBookingsDetailsResponse } from '../../bookings.interface'

const PackLines = ({ data }: { data: IBookingsDetailsResponse }) => {
    return (
        <SimpleTable<IBookingsPackLinesTable>
            headers={packLineHeadersDetails}
            data={data.detailsPacklines}
            usePagination
            nextHandling={(page) => {
                console.log('hit Next API -> ' + page)
            }}
            previousHandling={(page) => {
                console.log('hit Prev API -> ' + page)
            }}
            meta={metaDummy}
        />
    )
}

export default PackLines
